<bz-layout>

  <ng-container ngProjectAs="app-header">
    <header class="header">
      <app-header class="desktop"></app-header>
    </header>
  </ng-container>

  <div class="main-content" [ngStyle]="{height: primaryHeightContent}">
    <router-outlet></router-outlet>
  </div>

  <bz-footer class="bz-footer"></bz-footer>

</bz-layout>

<ng-container *ngIf="visibleLcationChange | async">
  <div class="info-change-location-popup">
    <div class="closed-btn" (click)="closeLocationPopup()">
      <span class="icon"></span>
    </div>
    <p class="title-info-change-location-popup">{{'searchPharmsInCity' | transloco}}:</p>


    <div class="wrapper-content">
      <div (click)="selectLocation(item)" *ngFor="let item of (variantsLcationForPopupChange | async)"
        class="wrapper-item">
        <span class="city-var">{{ currentLang == 'ru' ? item.nameRu : item.nameUa }}</span>
      </div>
    </div>

    <div class="wrapper-item-other">
      <p>{{'other' | transloco}}:</p>
      <bz-select-city-popup></bz-select-city-popup>
    </div>

  </div>
</ng-container>
