import { createSelector } from '@ngrx/store';
import * as fromRouter from '@ngrx/router-store';
import { AppState } from '@appStates';
import { RouterStateUrl } from '../router/custom-serializer';
import { NavigationState } from '../state/routs-app.state';

export const getRouterState = (state: AppState) => state.router;
export const getPrevRouterState = (state: AppState) => state.prevRout;

export const getCurrentRouteState = createSelector(
    getRouterState,
  (state: fromRouter.RouterReducerState) => state.state
);

export const getPrevRouteState = createSelector(
    getPrevRouterState,
  (prev: NavigationState): RouterStateUrl => prev.rout
);
