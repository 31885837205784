import {
  AfterViewInit, Component, ElementRef, EventEmitter, HostBinding, Inject, Input,
  OnDestroy, OnInit, Output, PLATFORM_ID, ViewChild
} from '@angular/core';
import { LocalizationService } from '@core/services';
import { Subject } from 'rxjs';
import { debounceTime, take, takeUntil } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { ClearFoundActionSuccess, ResetPageCountLoadDataAction } from '../../../store/actions';
import { select, Store } from '@ngrx/store';
import { AppState } from '@appStates';
import { getPrevRouteState } from 'src/app/store/selectors/navigate.selectors';

@Component({
  selector: 'bz-catalog-popup',
  templateUrl: '../../../../catalog.html',
  styleUrls: ['./bz-catalog-popup.component.sass'],
})
export class BzCatalogPopupComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() isSwitch: Subject<never> = new Subject<never>();
  @ViewChild('menuRight') menuRight: ElementRef;
  public isRus: boolean;
  public bufferZ = 1;
  public bufferElement: HTMLDivElement;

  public menu: Subject<[HTMLDivElement, HTMLDivElement, string]> =
    new Subject<[HTMLDivElement, HTMLDivElement, string]>();

  @HostBinding('class.home-page') public setWidth = false;
  @HostBinding('class.other-page') public setStaticWidth = false;

  @Output() public onClose: EventEmitter<void> = new EventEmitter();

  private isHomePage: boolean;

  private componentDestroyer = new Subject();

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: Object,
    private readonly localizationService: LocalizationService,
    private readonly store: Store<AppState>
  ) {
    this.isRus = this.localizationService.getLang() === 'ru';

    if (window.location.href !== window.location.origin + '/ua'
      && window.location.href !== window.location.origin + '/') {
      this.isHomePage = false;
      this.setStaticWidth = true;
    } else {
      this.isHomePage = true;
      this.setWidth = true;
    }
  }

  ngOnInit(): void {
    this.hoverVisibleHelper();
  }

  public block(l: HTMLDivElement, m: HTMLDivElement): void {
    l.style.zIndex = (++this.bufferZ).toString();
    m.style.background = '#f8f8f8';
  }

  public hoverVisibleHelper(): void {
    this.menu.asObservable()
      .pipe(
        debounceTime(100),
        takeUntil(this.componentDestroyer)
      )
      .subscribe(([element, menu, type]: [HTMLDivElement, HTMLDivElement, string]) => {
        if (type === 'mouseenter') {
          element.style.zIndex = (++this.bufferZ).toString();
          if (this.bufferElement) {
            this.bufferElement.style.background = '';
          } else {
            this.menuRight.nativeElement.childNodes[0].style.background = '';
          }
          menu.style.background = '#f8f8f8';
          this.bufferElement = menu;
        }
      });
  }

  public clearSearchState(): void {
    this.store.pipe(
      select(getPrevRouteState),
      take(1)
    ).subscribe(data => {
      if (window.location.pathname.includes('/category/') && data.url === '/') {
        return  // if same url navigation - just return
      } else {
        this.store.dispatch(ResetPageCountLoadDataAction());
        this.store.dispatch(ClearFoundActionSuccess());
      }
    });

    this.onClose.next(null);
  }

  public ngAfterViewInit(): void {
    this.widthBlockRendering();
  }

  public widthBlockRendering(): void {
    if (isPlatformBrowser(this.platformId)) {

      if (this.menuRight.nativeElement && this.menuRight.nativeElement.childNodes[0]) {
        this.menuRight.nativeElement.childNodes[0].style.background = '#f8f8f8';
      }

      if (this.isHomePage) {
        // если попап на главной, то он резиновый (responsive)
        const homePagePopup: Element = document.getElementsByClassName('home-page')[0];

        (homePagePopup as HTMLElement).style.width = 'calc(100% + 145px)';
        if (window.matchMedia('(max-width: 1190px)').matches) {
          (homePagePopup as HTMLElement).style.left = '-130px';
        }
      } else {
        // если попап не на главной, то он статичен и с медиазапросами (adaptive)
        const otherPagesPopup: Element = document.getElementsByClassName('other-page')[0];
        (otherPagesPopup as HTMLElement).style.width = '1120px';
        (otherPagesPopup as HTMLElement).style.height = '600px';

        if (window.matchMedia('(max-width: 1400px)').matches) {
          (otherPagesPopup as HTMLElement).style.left = '-250px';
        }
        if (window.matchMedia('(max-width: 1300px)').matches) {
          (otherPagesPopup as HTMLElement).style.left = '-300px';
        }
        if (window.matchMedia('(max-width: 1235px)').matches) {
          (otherPagesPopup as HTMLElement).style.left = '-325px';
        }
        if (window.matchMedia('(max-width: 1165px)').matches) {
          (otherPagesPopup as HTMLElement).style.width = '95vw';
        }
        if (window.matchMedia('(max-width: 1100px)').matches) {
          (otherPagesPopup as HTMLElement).style.width = '98vw';
        }
      }

    }
  }

  public trackById(_, el: any): number {
    return el.id;
  }

  ngOnDestroy(): void {
    this.componentDestroyer.next(null);
    this.componentDestroyer.complete();
  }

}
