import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { combineLatest, forkJoin, Observable, of, throwError } from 'rxjs';
import { BzProduct, ISearchResultResponse, ListOptions, Options } from '@interfaces';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';
import { selectFoundsLength, selectPageCount, selectTarget } from '../../../store/selectors';
import { ActiveFiltersSuccess, LoadFiltersPageSuccess, SetCurrentFiltersAction, SetPageLoadingAction } from '../../../store/actions';
import { LocalStorageService } from '@core/services';
import { RestUrlService, TypeUrl } from './settings/rest-url.service';
import { Store } from '@ngrx/store';
import { AppState } from '@appStates';
import { isPlatformBrowser } from '@angular/common';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class SearchGoodsApiService {

  private readonly _url: string;

  constructor(
    private readonly http: HttpClient,
    private readonly localStorageService: LocalStorageService,
    private readonly store: Store<AppState>,
    private readonly urlRest: RestUrlService,
    private readonly toastr: ToastrService,
    @Inject(PLATFORM_ID) private platformId: {}
  ) {
    this._url = 'https:' + this.urlRest.getUrl(TypeUrl.rest);
  }

  public getOptionsOnType(search: string): Observable<Options[]> {
    const params: HttpParams = new HttpParams().set('pattern', search);
    return this.http.get<Options[]>(this._url + '/api/v2/search-options', { params }).pipe(
      catchError((error) => {
        this.toastr.error(error.message ?? '', 'Сталася помилка!', {
          timeOut: 2000
        });
        return throwError(error);
      })
    )
  }

  public getFoundsTargetSearch(search: ListOptions, loading: boolean, anyParams?: any): Observable<ISearchResultResponse> {
    return combineLatest([
      this.store.select(selectFoundsLength).pipe(take(1)),
      this.store.select(selectPageCount).pipe(take(1)),
    ]).pipe(
      switchMap(([length, pages]) => {
        if (length > 0 && !loading) {
          return of(null);
        }

        const [pStart, pEnd] = pages;

        if (search.tradeNameEn === 'false' || search.tradeNameEn === undefined) {
          this.saveTargetToHistoty('specific', search);
        }

        let params: HttpParams = new HttpParams()
          .set('sectionId', search.id ? search.id.toString() : '6')
          .set('info', search.info ? search.info : 'null')
          .set('tradeNameEn', 'false')
          .set('pStart', (pStart || 1).toString())
          .set('pEnd', (pEnd || 1).toString());

        params = search.name ? params.set('pattern', decodeURIComponent(encodeURIComponent(search.name))) : params;
        params = !search.id && !search.info ? params.set('pattern', decodeURIComponent(search.url)) : params;

        if (anyParams) {
          for (const key in anyParams) {
            params = params.append(key, anyParams[key]);
          }
        }

        let uri: string = encodeURI(search.url);

        if (uri !== 'by-town' && search.id === 1 && search.info === 'trade' && search.tradeNameEn) {
          uri = '_';
          params = params.set('tradeNameEn', 'true');
        }

        if (uri === 'by-town') {
          params = params.set('tradeNameEn', 'true');
        }

        if (search.town) {
          params = params.set('townEn', search.town);
        }

        this.getFoundsTargetSearchFilters(search, anyParams, search.town);
        this.store.dispatch(SetPageLoadingAction({ loading: true }));

        return this.http.get<ISearchResultResponse>(`${this._url}/api/search-proposed/block-drug/${uri}`, { params }).pipe(
          switchMap((response: ISearchResultResponse) => {
            if (isPlatformBrowser(this.platformId)) {
              return forkJoin([of(response), this.localStorageService.getProductsIdSet(), this.localStorageService.getFavoriteIdSet()]);
            } else {
              return forkJoin([of(response), of(new Set<number>()), of(new Set<number>())])
            }
          }),
          map(([searchResult, favoriteSet, listSet]) => {
            const markedProducts: BzProduct[] = [];

            searchResult.goods.forEach((product: BzProduct) => {
              markedProducts.push({ ...product, isFavorite: favoriteSet.has(product.id), inList: listSet.has(product.id) });
            });

            return { ...searchResult, goods: markedProducts } as ISearchResultResponse;
          }),
          catchError((error) => {
            this.toastr.error(error.message ?? '', 'Сталася помилка!', {
              timeOut: 2000
            });
            this.store.dispatch(SetPageLoadingAction({ loading: false }));
            return throwError(error);
          }),
        );
      })
    );
  }

  private getFoundsTargetSearchFilters(search: ListOptions, filterParams?: any, town?: string): void {
    let params: HttpParams = new HttpParams()
      .set('sectionId', search.id ? search.id.toString() : '6')
      .set('tradeNameEn', 'false')
      .set('info', search.info ? search.info : 'null');

    params = search.name ? params.set('pattern', decodeURIComponent(encodeURIComponent(search.name))) : params;
    params = !search.id && !search.info ? params.set('pattern', decodeURIComponent(search.url)) : params;

    if (filterParams) {
      for (const key in filterParams) {
        params = params.append(key, filterParams[key]);
      }
    }

    let type = search.url === 'analogs' ? 'analogs' : search.url === 'search' ? 'search' : search.url;

    if (search.id === 2 && search.info === '3' && !search.name && window.location.href.includes('category')) {
      type = search.url;
    }

    if (search.id === 1 && search.info === 'trade' && search.tradeNameEn) {
      type = '_';
      params = params.set('tradeNameEn', 'true');
    }

    if (town) {
      params = params.set('townEn', search.town);
    }

    this.http.get<any>(`${this._url}/api/search-proposed/filters/${type}`, { params }).subscribe(filters => {
      this.store.dispatch(SetCurrentFiltersAction({ current: filters.checked }));
      this.store.dispatch(LoadFiltersPageSuccess({ filters: filters.all }));
      this.store.dispatch(ActiveFiltersSuccess({ count: filters.count }));
    });
  }

  private saveTargetToHistoty(
    source: 'arbitrary' | 'specific',
    option?: ListOptions
  ): void {
    this.store.select(selectTarget).pipe(
      filter((v) => !!v),
      take(1)
    ).subscribe((target: string) => {
      if (option) {
        this.localStorageService.toHS(source, target, option);
      } else {
        this.localStorageService.toHS(source, target);
      }
    });
  }

}
