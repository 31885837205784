import { BzProduct, IAdviceDay, IAllAdviceList, ISingleAdviceDay, ITopReserveSingleGroup, SliderItem } from '@interfaces';
import { createSelector } from '@ngrx/store';
import { selectFourProducts, selectViewedFourProducts } from './index';
import { AppState } from '@appStates';
import { StartPageState } from '../state/start.page.state';

const selectStartPageState = (state: AppState): StartPageState => state.startPage;

export const listSelectBlocks = createSelector(
    selectStartPageState,
    (state: StartPageState): { id: number, category: string }[] => {
        return state.slides.map(item => {
            return { id: item.id, category: item.name }
        })
    }
);

export const selectActiveBlock = createSelector(
    selectStartPageState,
    selectFourProducts,
    selectViewedFourProducts,
    (spstate: StartPageState, products: BzProduct[], viewed: BzProduct[], props: { id: number }): SliderItem => {
        let result: SliderItem = { ...spstate.slides[props.id] }
        return result;
    }
);

export const selectAllBlock = createSelector(
    selectStartPageState,
    (state: StartPageState): SliderItem[] => state.slides);


export const currentAdviceTipId = createSelector(
    selectStartPageState,
    (state: StartPageState): string => state.adviceDayId
);

export const currentMostOftenGroup = createSelector(
    selectStartPageState,
    (state: StartPageState): string => {
        if (state.mostOftenGroup) {
            return state.mostOftenGroup
        }
    }
);

export const selectAdviceDayList = createSelector(
    selectStartPageState,
    (state: StartPageState): IAdviceDay[] => state.adviceDayList
);

export const selectTopBookingList = createSelector(
    selectStartPageState,
    (state: StartPageState): BzProduct[] => Object.values(state.mostOftenList.entities)
);

export const selectCurrentAdviceDay = createSelector(
    selectStartPageState,
    (state: StartPageState): ISingleAdviceDay => state.singleAdviceDay
);

export const selectGroupOftenKeys = createSelector(
    selectStartPageState,
    (state: StartPageState): string[] => state.singleOftenKeys
);

export const selectOneTopReservGroup = createSelector(
    selectStartPageState,
    (state: StartPageState): ITopReserveSingleGroup => {
        if (state.currentOftenCategory) {
            return state.currentOftenCategory
        }
    }
);

export const selectAllAdviceDayTips = createSelector(
    selectStartPageState,
    (state: StartPageState): IAllAdviceList => state.allAdviceDayTips
);

export const selectSingleAdviceTipState = createSelector(
    selectStartPageState,
    (state: StartPageState): boolean => state.singleAdviceState
);

export const selectCurrentAdvicesPage = createSelector(
    selectStartPageState,
    (state: StartPageState): number => state.currentAdvicesPage
);

export const listActiveBlockControlPanelSlide = createSelector(
    selectStartPageState,
    selectFourProducts,
    selectViewedFourProducts,
    (state: StartPageState, products: BzProduct[], viewed: BzProduct[], props: { id: number }) => {
        let result = { ...state.slides[props.id] };
        let res = [];
        res = result.slides.map(item => {
            return { id: item.id, active: false }
        })
        return res;
    }

);

