import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ICatalogProducts, ICatalogTextWithEditor, ITableCatalogProductsResponse } from '@interfaces';
import { EMPTY, forkJoin, Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { LocalStorageService } from '../storage.services/local-storage.service';
import { RestUrlService, TypeUrl } from './settings/rest-url.service';

@Injectable({
  providedIn: 'root'
})
export class CatalogProductsService {

  private readonly _url: string;

  constructor(
    private readonly http: HttpClient,
    private readonly urlRest: RestUrlService,
    private readonly localStorageService: LocalStorageService,
    @Inject(PLATFORM_ID) private readonly platformId: Object
  ) {
    this._url = 'https:' + this.urlRest.getUrl(TypeUrl.rest);
  }

  getCatalogProducts(url: string): Observable<ICatalogProducts[]> {
    return this.http.get<ICatalogProducts[]>(this._url + `/api/catalog-goods/${url}`).pipe(
      mergeMap((response) => {
        if (isPlatformBrowser(this.platformId)) {
          return forkJoin([of(response), this.localStorageService.getProductsIdSet(), this.localStorageService.getFavoriteIdSet()]);
        } else {
          return forkJoin([of(response), of(new Set()), of(new Set())]);
        }
      }),
      map(([response, list, favorite]) => {
        response.forEach((group) => {
          group.products.forEach((product) => {
            list.has(product.id) ? product.inList = true : product.inList = false;
            favorite.has(product.id) ? product.isFavorite = true : product.isFavorite = false;
          });
        });
        return response;
      }),
      catchError(() => EMPTY)
    );
  }

  getCatalogProductsTable(url: string): Observable<ITableCatalogProductsResponse> {
    return this.http.get<ITableCatalogProductsResponse>(this._url + `/api/catalog-table/${url}`).pipe(
      catchError(() => EMPTY)
    );
  }

  getCatalogProductsTextWithEditor(url: string): Observable<ICatalogTextWithEditor> {
    return this.http.get<ICatalogTextWithEditor>(this._url + `/api/catalog-text/${url}`).pipe(
      catchError(() => EMPTY)
    );
  }
}
