import { Injectable } from '@angular/core';
import { LocalStorageApiService } from './local-storage-api.service';
import { KeysLS } from '@enums';
import { MemoryStorageApiService } from "./memory-storage-api.service";

@Injectable({
  providedIn: 'root'
})
export class LocationStorageService extends LocalStorageApiService {
  public DEFAULT_VALUE = ['Київ', '1240', 'kv'];
  public KEY = [];

  constructor(
    private readonly memory: MemoryStorageApiService
  ) {
    super();
  }

  public get location(): string {
    if (this.isSupported()) {
      return this.getData(KeysLS.location) ?? null;
    } else {
      return this.memory.get(KeysLS.location) ?? null;
    }
  }

  public set location(value: string) {
    if (this.isSupported()) {
      this.setData(KeysLS.location, value);
    } else {
      this.memory.set(KeysLS.location, value);
    }
  }

  public get cityName(): string {
    if (this.isSupported()) {
      return this.getData(KeysLS.cityName) ?? this.DEFAULT_VALUE[0];
    } else {
      return this.memory.get(KeysLS.cityName) ?? this.DEFAULT_VALUE[0];
    }
  }

  public set cityName(value: string) {
    if (this.isSupported()) {
      this.setData(KeysLS.cityName, value);
    } else {
      this.memory.set(KeysLS.cityName, value);
    }
  }

  public get cityId(): string {
    if (this.isSupported()) {
      return this.getData(KeysLS.cityID) ?? this.DEFAULT_VALUE[1];
    } else {
      return this.memory.get(KeysLS.cityID);
    }
  }

  public set cityId(value: string) {
    if (this.isSupported()) {
      this.setData(KeysLS.cityID, value);
    } else {
      this.memory.set(KeysLS.cityID, value);
    }
  }

  public get city(): string {
    if (this.isSupported()) {
      return this.getData(KeysLS.city);
    } else {
      return this.memory.get(KeysLS.city);
    }
  }

  public set city(value: string) {
    if (this.isSupported()) {
      this.setData(KeysLS.city, value);
    } else {
      this.memory.set(KeysLS.city, value);
    }
  }

  public get label(): string {
    if (this.isSupported()) {
      return this.getData(KeysLS.label) ?? this.DEFAULT_VALUE[2];
    } else {
      return this.memory.get(KeysLS.label);
    }
  }

  public set label(value: string) {
    if (this.isSupported()) {
      this.setData(KeysLS.label, value);
    } else {
      this.memory.set(KeysLS.label, value);
    }
  }

  public get address(): string {
    if (this.isSupported()) {
      return this.getData(KeysLS.address) ?? null;
    } else {
      return this.memory.get(KeysLS.address);
    }
  }

  public set address(value: string) {
    if (this.isSupported()) {
      this.setData(KeysLS.address, value);
    } else {
      this.memory.set(KeysLS.address, value);
    }
  }

  public get addressHistory(): string {
    if (this.isSupported()) {
      return this.getData(KeysLS.addressHistory) ?? null;
    } else {
      return this.memory.get(KeysLS.addressHistory) ?? null;
    }
  }

  public set addressHistory(value: string) {
    if (this.isSupported()) {
      this.setData(KeysLS.addressHistory, value);
    } else {
      this.memory.set(KeysLS.addressHistory, value);
    }
  }

  public get townEn(): string {
    if (this.isSupported()) {
      return this.getData(KeysLS.nameEn);
    } else {
      return this.memory.get(KeysLS.nameEn);
    }
  }

  public set townEn(value: string) {
    if (this.isSupported()) {
      this.setData(KeysLS.nameEn, value);
    } else {
      this.memory.set(KeysLS.nameEn, value);
    }
  }

  public get userTown(): string {
    if (this.isSupported()) {
      return this.getData(KeysLS.userTown);
    } else {
      return this.memory.get(KeysLS.userTown);
    }
  }

  public set userTown(value: string) {
    if (this.isSupported()) {
      this.setData(KeysLS.userTown, value);
    } else {
      this.memory.set(KeysLS.userTown, value);
    }
  }
}
