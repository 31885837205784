import { AppState } from '@appStates';
import { Languages, ListLazyComponents } from '@enums';
import { ICity, SideMenuItem } from '@interfaces';
import { createSelector } from '@ngrx/store';
import { GlobalAppState } from '@states';


const selectAppGlobalState = (state: AppState): GlobalAppState => state.globalApp;

export const selectListoptionsDesktop = createSelector(
    selectAppGlobalState,
    (state: GlobalAppState): any[] => state.workingPanelMenu
        .filter(item => (item.device === 'all' || item.device === 'desktop'))
        .filter(item => item.switcher)
);

export const selectListoptionsMobile = createSelector(
    selectAppGlobalState,
    (state: GlobalAppState): any[] => state.workingPanelMenu
        .filter(item => (item.device === 'all' || item.device === 'mobile'))
        .filter(item => item.switcher)
);

export const selectPreviousRoutForMobile = createSelector(
    selectAppGlobalState,
    (state: GlobalAppState): string => state.sideMenu.previousRout
);

export const selectCurrentLang = createSelector(
    selectAppGlobalState,
    (state: GlobalAppState): Languages | string => state.lang
);

export const selectStateCurrentBlockMobilePage = createSelector(
    selectAppGlobalState,
    (state: GlobalAppState): ListLazyComponents => state.sideMenu.currentBlock
);

export const selectStateListSideMenu = createSelector(
    selectAppGlobalState,
    (state: GlobalAppState): SideMenuItem[] => state.sideMenu.list
);

export const selectVisibleBoolean = createSelector(
    selectAppGlobalState,
    (state: GlobalAppState): boolean => state.visible
);

export const selectCurrentCoord = createSelector(
    selectAppGlobalState,
    (state: GlobalAppState): any[] => state.coord
);

export const selectCurrentCityId = createSelector(
    selectAppGlobalState,
    (state: GlobalAppState): number => state.cityId
);

export const selectCurrentCity = createSelector(
    selectAppGlobalState,
    (state: GlobalAppState): string => state.cityName
);

export const selectCurrentLabel = createSelector(
    selectAppGlobalState,
    (state: GlobalAppState): string => state.label
);

export const selectVisibleLocationChangePopup = createSelector(
    selectAppGlobalState,
    (state: GlobalAppState): boolean => state.popupChangeLocation
);

export const selectVariantsChangeLocationsForPopup = createSelector(
    selectAppGlobalState,
    (state: GlobalAppState): ICity[] => state.variantsLocationInPopup
);

export const selectUserTown = createSelector(
    selectAppGlobalState,
    (state: GlobalAppState): { townId: number; latitude: number; longitude: number } => state.userTown
);

export const selectTownEnName = createSelector(
    selectAppGlobalState,
    (state: GlobalAppState): string => state.townEn
);

export const selectIsGeolocationDisabled = createSelector(
    selectAppGlobalState,
    (state: GlobalAppState): boolean => state.isGeoLocationDisabled
);
