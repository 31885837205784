import { Action, createReducer, on } from '@ngrx/store';
import { initialLocationPageState, LocationPageState } from '@states';

import * as LocationActions from '@actions';

const reducer = createReducer(
  initialLocationPageState,
  on(
    LocationActions.SetCitiesAction, (state, action) => ({
      ...state,
      cities: action.cities
    })
  ),
  on(
    LocationActions.SetSearchCitiesHintsAction, (state, action) => ({
      ...state,
      searchCitiesHints: action.cities
    })
  ),
  on(
    LocationActions.SetFullAddressesAction, (state, action) => ({
      ...state,
      fullAdresses: action.fullAddresses
    })
  ),
  on(
    LocationActions.SetAddressAction, (state, action) => ({
      ...state,
      address: action.address
    })
  )
);

export function locationReducer(state: LocationPageState | undefined, action: Action): LocationPageState {
  return reducer(state, action);
}
