import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BzAptekaComponent } from './bz-apteka.component';
import { ButtonModule } from '../../action-elements/bz-button/button.module';
import { TranslocoModule } from '@ngneat/transloco';
import { BzProductModule } from '../bz-product/bz-product.module';
import { SharedModule } from '@shared';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';



@NgModule({
  declarations: [BzAptekaComponent],
  imports: [
    CommonModule,
    ButtonModule,
    BzProductModule,
    TranslocoModule,
    SharedModule,
    ShareButtonsModule,
    ShareIconsModule,
  ],
  exports: [BzAptekaComponent]
})
export class BzAptekaModule { }
