import { Injectable } from '@angular/core';
import { LocalStorageApiService } from './local-storage-api.service';
import { KeysLS } from '@enums';
import { MemoryStorageApiService } from './memory-storage-api.service';

@Injectable({
  providedIn: 'root'
})
export class FavoriteLocalStorageService extends LocalStorageApiService {
  public DEFAULT_VALUE = '';
  public KEY = KeysLS.favorite;

  constructor(
    private readonly memory: MemoryStorageApiService
  ) {
    super();
  }

  public get favorite(): string {
    if (this.isSupported()) {
      return this.getData(this.KEY);
    } else {
      return this.memory.get(this.KEY);
    }
  }

  public set favorite(value: string) {
    if (this.isSupported()) {
      this.setData(this.KEY, value);
    } else {
      this.memory.set(this.KEY, value);
    }
  }
}
