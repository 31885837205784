import { createAction, props } from '@ngrx/store';
import { BzProduct } from '@interfaces';

export enum FavoriteActions {
  LoadFavoriteProducts = '[Favorite] LoadFavoriteProducts',
  UnmarkFavoriteProduct = '[Favorite] UnmarkFavoriteProduct',
  MarkFavoriteProduct = '[Favorite] MarkFavoriteAction',
  InListToggleProduct = '[Favorite] InListToggleProduct',
  UnmarkInListProductFromList = '[Favorite] UnmarkInListIconFromListPage',
  UnmarkInListProductsFromList = '[Favorite] UnmarkInListIconsFromListPage',
  IncrementPageState = '[Favorite] IncrementPageState'
}

export const LoadFavoriteProductsAction = createAction(
  FavoriteActions.LoadFavoriteProducts,
  props<{ products: BzProduct[] }>()
);

export const UnmarkFavoriteAction = createAction(
  FavoriteActions.UnmarkFavoriteProduct,
  props<{ id: number }>()
);

export const MarkFavoriteAction = createAction(
  FavoriteActions.MarkFavoriteProduct,
  props<{ product: BzProduct }>()
);

export const InListToggleAction = createAction(
  FavoriteActions.InListToggleProduct,
  props<{ id: number }>()
);

export const UnmarkInListProductFromListAction = createAction(
  FavoriteActions.UnmarkInListProductFromList,
  props<{ id: number }>()
);

export const UnmarkInListProductsFromListAction = createAction(
  FavoriteActions.UnmarkInListProductsFromList
);

export const IncrementPageStateAction = createAction(
  FavoriteActions.IncrementPageState
);