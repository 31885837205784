import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { map, mergeMap } from 'rxjs/operators';
import { BzProduct, ITopReserveSingleGroup } from '@interfaces';
import { isPlatformBrowser } from '@angular/common';
import { forkJoin, of } from 'rxjs';
import { SetMostOftenProductsAction, SetOftenKeysAction, SetOneOftenProductsGroupAction } from '../../../store/actions';
import { RestUrlService, TypeUrl } from './settings/rest-url.service';
import { HttpClient } from '@angular/common/http';
import { LocalStorageService, SeoService } from '@core/services';
import { Store } from '@ngrx/store';
import { AppState } from '@appStates';

@Injectable({
  providedIn: 'root'
})
export class TopBookingApiService {
  private readonly _url: string;

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: Object,
    private readonly http: HttpClient,
    private readonly localStorageService: LocalStorageService,
    private readonly store: Store<AppState>,
    private readonly urlRest: RestUrlService,
    private readonly seoService: SeoService
  ) {
    this._url = 'https:' + this.urlRest.getUrl(TypeUrl.rest);
  }

  public getTopBookingProducts(): void {
    this.http.get(this._url + '/api/main/top-booking')
      .pipe(
        mergeMap((response: Map<string, BzProduct>) => {
          if (isPlatformBrowser(this.platformId)) {
            return forkJoin([
              of(new Map(Object.entries(response))),
              this.localStorageService.getProductsIdSet(),
              this.localStorageService.getFavoriteIdSet()
            ]);
          } else {
            return forkJoin([
              of(new Map(Object.entries(response))),
              of(new Set<number>()),
              of(new Set<number>())
            ]);
          }
        }),
        map(([response, listIds, favoriteIds]) => {
          response.forEach((item: BzProduct, key: string) => {
            listIds.has(item.id) ? item.inList = true : item.inList = false;
            favoriteIds.has(item.id) ? item.isFavorite = true : item.isFavorite = false;
            item.key = key;
          });
          return Array.from(response.values());
        })
      )
      .subscribe((products: BzProduct[]) => {
        this.store.dispatch(SetMostOftenProductsAction({ data: products }));
      });
  }


  public getGroupOfTopReserving(id: string): void {
    this.http.get(this._url + `/api/top-booking/${id}`).pipe(
      mergeMap((response: { allKeys: string[], topShop: ITopReserveSingleGroup }) => {
        if (isPlatformBrowser(this.platformId)) {
          return forkJoin([
            of(response),
            this.localStorageService.getProductsIdSet(),
            this.localStorageService.getFavoriteIdSet()
          ]);
        } else {
          return forkJoin([
            of(response),
            of(new Set<number>()),
            of(new Set<number>())
          ]);
        }
      }),
      map(([response, listIds, favoriteIds]) => {
        const currentGroup = response.topShop;
        currentGroup.products.forEach((item: BzProduct) => {
          listIds.has(item.id) ? item.inList = true : item.inList = false;
          favoriteIds.has(item.id) ? item.isFavorite = true : item.isFavorite = false;
        });
        return response;
      })
    ).subscribe((data: { allKeys: string[], topShop: ITopReserveSingleGroup }) => {
      this.store.dispatch(SetOneOftenProductsGroupAction({ group: data.topShop }));
      this.store.dispatch(SetOftenKeysAction({ keys: data.allKeys }));
      this.seoService.setStaticTitleWithOutTranslate(data.topShop.seoTitle);
      this.seoService.setStaticDescriptionWithOutTranslate(data.topShop.seoDescription);
    });
  }

}
