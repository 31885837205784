import { AppState } from '@appStates';
import { BzProduct } from '@interfaces';
import { EntityState } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { adapterViewedProducts } from '@states';

const start: number = 0;
const limitInHomePage: number = 5;

const selectViewedState = (state: AppState): EntityState<BzProduct> => state.viewed.products;

const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
} = adapterViewedProducts.getSelectors();

export const selectAllViewed = () => createSelector(selectViewedState, selectAll);
export const selectViewedLength = () => createSelector(selectViewedState, selectTotal);
export const selectViewedDictionary = () => createSelector(selectViewedState, selectEntities);
export const selectViewedIds = () => createSelector(selectViewedState, selectIds);

export const selectViewedListProducts = createSelector(
    selectAllViewed(),
    (products: BzProduct[]): BzProduct[] => products
);

export const selectViewedFourProducts = createSelector(
    selectAllViewed(),
    (products: BzProduct[]): BzProduct[] => products.slice(start, limitInHomePage)
);