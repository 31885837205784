import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputSearchWrapperFieldComponent } from './input-search-wrapper-field.component';
import { TranslocoModule } from '@ngneat/transloco';
import { SharedModule } from '@shared';



@NgModule({
  declarations: [InputSearchWrapperFieldComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    SharedModule
  ],
  exports: [InputSearchWrapperFieldComponent]
})
export class InputSearchWrapperFieldModule { }
