import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';

const initialContactList: EntityState<Contact> = {
  ids: [1, 2, 3, 4, 5],
  entities: {
        1: {
          id: 1,
          icon: 'mail.svg',
          name: 'contact.email',
          description: ['info@zdorovi.ua']
        },
        2: {
            id: 2,
            icon: 'messenger.svg',
            name: 'contact.messenger',
            description: ['@zdorovi_ua']
        },
        3: {
            id: 3,
            icon: 'support.svg',
            name: 'contact.support',
            description: ['+38 066 820 57 61']
        },
        4: {
            id: 4,
            icon: 'connect.svg',
            name: 'contact.connect',
            description: ['connect@zdorovi.ua']
        },
        // 5: {
        //     id: 5,
        //     icon: 'phone.svg',
        //     name: 'contact.phones',
        //     description: ['+38 050 234 51 70', '+38 067 720 97 59']
        // }
  }
};

export interface Contact {
  id?: number;
  icon?: string;
  name?: string;
  description?: string[];
}

export interface ContactsState extends EntityState<Contact>{}

export const adapterContacts: EntityAdapter<Contact> = createEntityAdapter<Contact>();

export const initialContactState: ContactsState = adapterContacts.getInitialState(initialContactList);


