import { BtnTypeContent, Languages, ListLazyComponents, TypeDevice, TypeStatusIconMobileMenu } from '@enums';
import { ICity, SideMenuItem, WorkingPanelMenu } from '@interfaces';
import { PrimaryColors } from 'src/app/shared/enums/colors.app.enum';

export interface GlobalAppState {
    lang: Languages | string;
    sideMenu: SideMenuSettings;
    workingPanelMenu: WorkingPanelMenu[];
    visible: boolean;
    city: any;
    cityId: number;
    cityName: string;
    coord: any[];
    label: string;
    popupChangeLocation: boolean;
    variantsLocationInPopup: ICity[];
    userTown: { townId: number, latitude: number, longitude: number };
    townEn: string;
    isGeoLocationDisabled: boolean;
}

export const initialGlobalAppState: GlobalAppState = {
    lang: null,
    sideMenu: {
        list: [
            {
                id: 2,
                icon: 'sm-order.svg',
                name: 'order',
                rout: '',
                type: TypeDevice.all,
                bage: true
            },
            {
                id: 3,
                icon: 'bz-archive.svg',
                name: 'history',
                rout: '',
                type: TypeDevice.all
            },
            {
                id: 9,
                icon: 'bookmark.svg',
                name: 'catalog',
                rout: '',
                type: TypeDevice.all
            },
            {
                id: 3,
                icon: 'sm-heart.svg',
                name: 'heart',
                rout: '',
                type: TypeDevice.all
            },
            {
                id: 5,
                icon: 'sm-finder.svg',
                name: 'finder',
                rout: '',
                type: TypeDevice.all
            },
            {
                id: 6,
                icon: 'location.svg',
                name: 'location',
                rout: '',
                type: TypeDevice.mobile,
                bage: true
            },
             {
                id: 8,
                icon: 'sm-translation.svg',
                name: 'translation',
                rout: '',
                type: TypeDevice.mobile
            },
        ],
        statusIcon: TypeStatusIconMobileMenu.default,
        previousRout: '',
        currentBlock: null
    },
    workingPanelMenu: [
        {
            id: 1,
            device: 'mobile',
            description: 'home',
            component: ListLazyComponents.toHomePageMobile,
            switcher: true,
            buttonSetting: {
                content: BtnTypeContent.all,
                size: { w: 45, h: 45 },
                icon: 'home.svg',
                text: 'home',
                textColor: PrimaryColors.text,
                radius: true
            }
        },
        {
            id: 2,
            device: 'mobile',
            description: 'search',
            component: ListLazyComponents.search,
            switcher: true,
            buttonSetting: {
                content: BtnTypeContent.all,
                size: { w: 45, h: 45 },
                icon: 'search.svg',
                text: 'search',
                textColor: PrimaryColors.text,
                radius: true
            }
        },
        {
            id: 3,
            device: 'all',
            description: 'list',
            component: ListLazyComponents.list,
            switcher: true,
            buttonSetting: {
                content: BtnTypeContent.all,
                size: { w: 45, h: 45 },
                icon: 'list.svg',
                text: 'list',
                textColor: PrimaryColors.text,
                radius: true
            }
        },
        {
            id: 4,
            device: 'all',
            description: 'cart',
            component: ListLazyComponents.cart,
            switcher: true,
            buttonSetting: {
                content: BtnTypeContent.all,
                size: { w: 45, h: 45 },
                icon: 'cart.svg',
                text: 'cart',
                textColor: PrimaryColors.text,
                radius: true
            }
        },
        {
            id: 5,
            device: 'desktop',
            description: 'location',
            component: ListLazyComponents.location,
            switcher: true,
            buttonSetting: {
                content: BtnTypeContent.all,
                size: { w: 45, h: 45 },
                icon: 'location.svg',
                text: 'location',
                textColor: PrimaryColors.text,
                radius: true
            }
        },
        {
            id: 6,
            device: 'mobile',
            description: 'menu',
            component: ListLazyComponents.menu,
            switcher: true,
            buttonSetting: {
                content: BtnTypeContent.all,
                size: { w: 45, h: 45 },
                icon: 'menu.svg',
                text: 'menu',
                textColor: PrimaryColors.text,
                radius: true
            }
        },
        {
            id: 7,
            device: 'desktop',
            description: 'user',
            component: ListLazyComponents.private,
            switcher: true,
            buttonSetting: {
                content: BtnTypeContent.all,
                size: { w: 45, h: 45 },
                icon: 'sm-user.svg',
                text: 'user',
                textColor: PrimaryColors.text,
                radius: true
            }
        },
        {
            id: 8,
            device: 'desktop',
            description: 'translate',
            component: null,
            switcher: true,
            buttonSetting: {
                content: BtnTypeContent.all,
                size: { w: 45, h: 45 },
                icon: 'info.svg',
                text: 'help',
                textColor: PrimaryColors.text,
                radius: true
            }
        },
    ],
    visible: false,
    city: null,
    cityId: null,
    cityName: null,
    coord: [],
    label: null,
    popupChangeLocation: false,
    variantsLocationInPopup: [],
    userTown: null,
    townEn: '',
    isGeoLocationDisabled: false,
};

interface SideMenuSettings {
    list: SideMenuItem[];
    statusIcon: TypeStatusIconMobileMenu;
    previousRout: string;
    currentBlock: ListLazyComponents;
}

export interface Locale {
    coord: Set<string>;
    label: string;
    description?: string;
    regionName?: string;
    idx?: Set<string>;
    center?: string;
    idRu?: number;
    idUa?: number;
}
