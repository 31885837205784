import { HttpClient } from '@angular/common/http';
import { Translation, TranslocoLoader, TranslocoModule, provideTransloco } from '@ngneat/transloco';
import { Injectable,  NgModule } from '@angular/core';
import { environment } from '@env';
import { Observable } from 'rxjs';

const { version: appVersion } = require('package.json');

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private http: HttpClient) {}

  public getTranslation(lang: string): Observable<Translation> {
    return this.http.get<Translation>(`/assets/i18n/${lang}.json?v=${appVersion}`);
  }
}

@NgModule({
  exports: [ TranslocoModule ],
  providers: [
    provideTransloco({
      config: {
        availableLangs: ['ua', 'ru'],
        defaultLang: 'ua',
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: environment.production,
      },
      loader: TranslocoHttpLoader
    }),
  ]
})
export class TranslocoBrowserRootModule {}
