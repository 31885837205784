import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, mergeMap } from 'rxjs/operators';
import { BzProduct } from '@interfaces';
import { forkJoin, of } from 'rxjs';
import { AddViewedBlocksInSliderAction, LoadViewedProductsAction } from '../../../store/actions';
import { LocalStorageService } from '@core/services';
import { Store } from '@ngrx/store';
import { AppState } from '@appStates';
import { RestUrlService, TypeUrl } from './settings/rest-url.service';

@Injectable({
  providedIn: 'root'
})
export class ViewedGoodsApiService {
  private readonly _url: string;

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: Object,
    private readonly http: HttpClient,
    private readonly localStorageService: LocalStorageService,
    private readonly store: Store<AppState>,
    private readonly urlRest: RestUrlService
  ) {
    this._url = 'https:' + this.urlRest.getUrl(TypeUrl.rest);
  }

  public getProductsFromViewed(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.localStorageService.getIdsArrayFromViewed()
        .subscribe((ids: string[]) => {
          const params: HttpParams = new HttpParams()
            .set('ids', ids.join());
          this.http.get(this._url + '/api/user-goods', { params })
            .pipe(
              mergeMap((response: BzProduct[]) => forkJoin([of(response),
              this.localStorageService.getProductsIdSet(),
              this.localStorageService.getFavoriteIdSet()])),
              map(([response, listIds, favoriteIds]) => {
                response.forEach((product: BzProduct) => {
                  listIds.has(product.id) ? product.inList = true : product.inList = false;
                  favoriteIds.has(product.id) ? product.isFavorite = true : product.isFavorite = false;
                });
                return response;
              }),
            ).subscribe((products: BzProduct[]) => {
              this.store.dispatch(LoadViewedProductsAction({ products }));
              this.store.dispatch(AddViewedBlocksInSliderAction({ vieweds: products }));
            });
        });
    }
  }

}
