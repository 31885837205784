import { Routes } from '@angular/router';
import { CatalogPageGuard } from '../core/guards/catalog-page.guard';
import { CategoryGuard } from '../core/guards/category.guard';
import { CiteMapGuard } from '../core/guards/cite-map.guard';
import { PharmaciesGuard } from '../core/guards/pharmacies.guard';
import { PharmaciesListGuard } from '../core/guards/pharmacies-list.guard';
import { PreparatGuard } from '../core/guards/preparat-page.guard';
import { infoDragResolver } from '../core/guards/info-drag.resolver';

export const routes: Routes = [
  {
    path: 'category/:url',
    loadChildren: () => import('../pages/search/search.module').then(m => m.SearchModule),
    resolve: [CategoryGuard]
  },
  {
    path: 'category/:url/:page',
    loadChildren: () => import('../pages/search/search.module').then(m => m.SearchModule),
    resolve: [CategoryGuard]
  },
  {
    path: 'preparat/:url/:town',
    loadChildren: () => import('../pages/search/search.module').then(m => m.SearchModule),
    resolve: [PreparatGuard]
  },
  {
    path: 'preparat/:url/:town/:page',
    loadChildren: () => import('../pages/search/search.module').then(m => m.SearchModule),
    resolve: [PreparatGuard]
  },
  {
    path: 'list/:url',
    loadChildren: () => import('../pages/search/search.module').then(m => m.SearchModule),
    resolve: [CategoryGuard]
  },
  {
    path: 'list/:url/:page',
    loadChildren: () => import('../pages/search/search.module').then(m => m.SearchModule),
    resolve: [CategoryGuard]
  },
  {
    path: 'search/:url',
    loadChildren: () => import('../pages/search/search.module').then(m => m.SearchModule),
    resolve: [CategoryGuard]
  },
  {
    path: 'search/:url/:page',
    loadChildren: () => import('../pages/search/search.module').then(m => m.SearchModule),
    resolve: [CategoryGuard]
  },
  {
    path: 'catalog/:url',
    loadChildren: () => import('../pages/catalog/catalog.module').then(m => m.CatalogModule),
    resolve: [CatalogPageGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'catalog',
    loadChildren: () => import('../pages/catalog/catalog.module').then(m => m.CatalogModule),
    resolve: [CatalogPageGuard]
  },
  {
    path: 'info-drug/:url/:id',
    loadChildren: () => import('../pages/info-drug/info-drug.module').then(m => m.InfoDrugModule),
    resolve: { infoDrag: infoDragResolver }
  },
  {
    path: 'pharmacies',
    loadChildren: () => import('../pages/pharmacies/pharmacies.module').then(m => m.PharmaciesModule),
    resolve: [PharmaciesListGuard]
  },
  {
    path: 'pharmacies/:url/:town',
    loadChildren: () => import('../pages/pharmacies/pharmacies.module').then(m => m.PharmaciesModule),
    resolve: [PharmaciesGuard]
  },
  {
    path: 'mobile',
    loadChildren: () => import('../pages/mobile/mobile.module').then(m => m.MobileModule)
  },
  {
    path: 'alphabetical',
    loadChildren: () => import('../pages/alphabetical/alphabetical.module').then(m => m.AlphabeticalModule)
  },
  {
    path: 'contacts',
    loadChildren: () => import('../pages/contacts/contacts.module').then(m => m.ContactsModule)
  },
  {
    path: 'delivery',
    loadChildren: () => import('../pages/delivery/delivery.module').then(m => m.DeliveryModule)
  },
  {
    path: 'grant',
    loadChildren: () => import('../pages/grant/grant.module').then(m => m.GrantModule)
  },
  {
    path: 'confidentiality',
    loadChildren: () => import('../pages/confidentiality/confidentiality.module').then(m => m.ConfidentialityModule)
  },
  {
    path: 'agreement',
    loadChildren: () => import('../pages/agreement/agreement.module').then(m => m.AgreementModule)
  },
  {
    path: 'about-company',
    loadChildren: () => import('../pages/about-company/about-company.module').then(m => m.AboutCompanyModule)
  },
  {
    path: 'team',
    loadChildren: () => import('../pages/team/team.module').then(m => m.TeamModule)
  },
  {
    path: 'connect',
    loadChildren: () => import('../pages/connect-pharmacy/connect-pharmacy.module').then(m => m.ConnectPharmacyModule)
  },
  {
    path: 'certificates',
    loadChildren: () => import('../pages/certificates/certificates.module').then(m => m.CertificatesModule)
  },
  {
    path: 'references',
    loadChildren: () => import('../pages/references/references.module').then(m => m.ReferencesModule)
  },
  {
    path: 'reserving',
    loadChildren: () => import('../pages/reserving/reserving.module').then(m => m.ReservingModule)
  },
  {
    path: 'irresponsible',
    loadChildren: () => import('../pages/irresponsible/irresponsible.module').then(m => m.IrresponsibleModule)
  },
  {
    path: 'licenses',
    loadChildren: () => import('../pages/licenses/licenses.module').then(m => m.LicensesModule)
  },
  {
    path: 'often',
    loadChildren: () => import('../pages/frequently-ordered/frequently-ordered.module').then(m => m.FrequentlyOrderedModule)
  },
  {
    path: 'tips',
    loadChildren: () => import('../pages/tip-of-the-day/tip-of-the-day.module').then(m => m.TipOfTheDayModule)
  },
  {
    path: 'tips/:name',
    loadChildren: () => import('../pages/tip-of-the-day/tip-of-the-day.module').then(m => m.TipOfTheDayModule)
  },
  {
    path: 'sitemap',
    loadChildren: () => import('../pages/site-map/site-map.module').then(m => m.SiteMapModule),
    resolve: [CiteMapGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'app-links',
    loadChildren: () => import('../pages/app-links/app-links.module').then(m => m.AppLinksModule)
  },
  {
    path: '404',
    loadChildren: () => import('../pages/not-found-page/not-found-page.module').then(m => m.NotFoundPageModule),
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: '404'
  }
];
