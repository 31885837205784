import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { AppState } from '@appStates';
import { select, Store } from '@ngrx/store';
import {
  SetAddressAction,
  SetPopupVisibleChangeLocationAction,
  SetSearchCitiesHintsAction,
  SetVariantsLocationInPopupAction
} from '../../../store/actions';
import { ICity } from '@interfaces';
import { selectSearchedCitiesHints } from '../../../store/selectors';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { LocalizationService, LocationService } from '@core/services';
import { LocationStorageService } from 'src/app/core/services/storage.services/location-storage-service.service';
import { GeoLocationService } from 'src/app/core/services/positioning.services/geo-location.service';

@Component({
  selector: 'bz-select-city-popup',
  templateUrl: './bz-select-city-popup.component.html',
  styleUrls: ['./bz-select-city-popup.component.sass']
})
export class BzSelectRegionComponent implements OnInit, OnDestroy {

  public searchCitiesHints: Observable<ICity[]> = this.store.pipe(select(selectSearchedCitiesHints));
  public cityControl: UntypedFormControl = new UntypedFormControl('');
  public currentLang: string;

  private destroyer$: Subject<void> = new Subject();

  constructor(
    private readonly store: Store<AppState>,
    private readonly localizationService: LocalizationService,
    private readonly locationService: LocationService,
    private readonly locationStorageService: LocationStorageService,
    private readonly geoLocationService: GeoLocationService,
  ) { }

  ngOnInit(): void {
    this.currentLang = this.localizationService.getLang();

    this.cityControl.valueChanges.pipe(
      debounceTime(350),
      distinctUntilChanged(),
      takeUntil(this.destroyer$)
    ).subscribe((value: string) => {
      if (value.length > 2) {
        this.locationService.searchTownByPattern(value);
      } else {
        this.store.dispatch(SetSearchCitiesHintsAction({ cities: [] }));
      }
    });

  }

  onChangeCity({ city, fromHint }: { city: ICity, fromHint?: boolean }): void {
    this.locationStorageService.address = '';
    this.geoLocationService.setTownEn(city.nameEn);
    this.geoLocationService.onChangeCity(city, fromHint);
    this.store.dispatch(SetPopupVisibleChangeLocationAction({ visible: false }));
    this.store.dispatch(SetAddressAction({ address: null }));
    this.resetStates();
  }

  private resetStates(): void {
    this.store.dispatch(SetVariantsLocationInPopupAction({ variants: [] }));
    this.store.dispatch(SetSearchCitiesHintsAction({ cities: [] }));
  }

  ngOnDestroy(): void {
    this.resetStates();
    this.destroyer$.next(null);
    this.destroyer$.complete();
  }

}
