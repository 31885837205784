import {
  Component,
  ElementRef,
  NgModule,
  OnInit,
  OnDestroy,
  Input,
  ViewChild,
  AfterViewInit,
  ViewContainerRef,
  ChangeDetectorRef,
  ChangeDetectionStrategy, Inject, PLATFORM_ID
} from '@angular/core';
import { debounceTime, filter, map, switchMap, takeUntil, take } from 'rxjs/operators';
import {
  ClearFoundActionSuccess,
  ClearOptionsSuccess,
  ResetFilterBufferAction,
  ResetPageCountLoadDataAction,
  SetTargetSuccess
} from '@actions';
import { UntypedFormControl, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';
import { AppState } from '@appStates';
import { LocalizationService, LocalStorageService } from '@core/services';
import { HistorySearchList, ListOptions, Options } from '@interfaces';
import { InputSearchWrapperFieldModule } from '../..';
import { TranslocoModule } from '@ngneat/transloco';
import { select, Store } from '@ngrx/store';
import { selectListoptions } from '@selectors';
import { SharedModule } from '@shared';
import { of, Subject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { CatalogPopupModule } from '../../blocks-ui/bz-catalog-popup/bz-catalog-popup.module';
import { RestUrlService, TypeUrl } from 'src/app/core/services/api.services/settings/rest-url.service';


@Component({
  selector: 'app-input-search',
  templateUrl: './input-search.component.html',
  styleUrls: ['./input-search.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputSearchComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('search') public search: ElementRef;
  @ViewChild('popupCatalog', { read: ViewContainerRef }) public popupCatalog: ViewContainerRef;

  @Input() headerLocation: boolean = false;

  public popupVisible: boolean = true;
  public reloadPopupVisible: boolean = true;
  public desktop_visible_droplist = false;
  public searchTargetStringSub$: Observable<void>;
  public listOptions: Observable<Options[]>;
  public inputSearch: UntypedFormControl = new UntypedFormControl();
  public hs: HistorySearchList[] = [];
  public isFocusSearch = false;
  public errorMessage: string = '';
  public imagesPath: string = '';

  private componentDestroyer = new Subject<void>();

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private readonly locale: LocalizationService,
    private readonly router: Router,
    private readonly store: Store<AppState>,
    private readonly localStorageService: LocalStorageService,
    private readonly cdr: ChangeDetectorRef,
    private readonly url: RestUrlService
  ) {
    this.imagesPath = this.url.getUrl(TypeUrl.img) + '/160-120/';
  }

  ngOnInit(): void {
    this.subSearchOnType();
    this.getListOptions();
    this.resetOptions();
  }

  ngAfterViewInit(): void {
    if (window.innerWidth <= 960) {
      this.clickInput();
      this.search.nativeElement.focus();
    }
    this.cdr.detectChanges();

    /* Trick for Safari */
    if(isPlatformBrowser(this.platformId)) {
      setTimeout(() => window.scrollTo(0, 0), 200);
    }
  }

  public clickInput(event?: any): void {
    this.isFocusSearch = true;
    this.clearPopupCatalog();
    this.desktop_visible_droplist = true;
    this.localStorageService.getHS()
      .pipe(takeUntil(this.componentDestroyer))
      .subscribe((data: HistorySearchList[]) => {
        this.getListHistorySearch(event, data);
      });
  }

  public keypressEnter(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.requestCustomSearch();
      this.closePopUp();
      this.search.nativeElement.blur();
    }
  }

  public outsidePopupClick(e): void {
    if (!new Set(['btn-catalog-text', 'btn-catalog-popup']).has(e.target.className)) {
      this.clearPopupCatalog();
    }
  }

  public clearPopupCatalog(): void {
    this.popupVisible = true;
  }

  public switchCatalogPopup(): void {
    this.reloadPopupVisible = false;
    this.popupVisible = !this.popupVisible;
    setTimeout(() => {
      this.reloadPopupVisible = true;
      this.cdr.detectChanges();
    }, 50);
  }

  // TODO: Move this to i18n
  public requestCustomSearch(hs?: string): void {
    if (!InputSearchComponent.isTrueLength(this.inputSearch.value)) {
      const errorMessage = this.locale.getLang() === 'ua' ?
        'Для пошуку необхідно ввести хоча б 3 символи' :
        'Для поиска необходимо ввести хотя бы 3 символа';
      this.addErrorMessage(errorMessage);
      this.clearErrorMessageWithTimeout();
      return;
    }

    /* Сбрасываем "страницы" загрузки товаров для страницы результатов поиска на значение дэфолтное значение [1,1] */
    this.store.dispatch(ResetPageCountLoadDataAction());
    /* Очищаем стейт загруженных результатов поиска */
    this.store.dispatch(ClearFoundActionSuccess());

    const target: string = hs ? hs : this.inputSearch.value ? this.inputSearch.value.replace('/', '') : '';
    this.reset();

    this.router.navigate(this.locale.getReturnParamsLang(['search', '_']), {
      queryParams: {
        sectionId: 6,
        info: 'null',
        pattern: target,
        pStart: 1,
        pEnd: 1
      }
    });

    if (this.isSearchPageResult()) {
      this.store.dispatch(SetTargetSuccess({
        target: target.toString(), life: '', option: {
          id: 6,
          name: target,
          url: target,
          info: 'null'
        },
        p: false
      }));

      this.store.dispatch(ResetFilterBufferAction({ status: true }));
    }
  }

  public closePopUp(event?: Event): void {
    if ((event?.target as Element)?.className.includes('input-search')) {
      return;
    }
    this.hs = [];
    this.desktop_visible_droplist = false;
    this.isFocusSearch = false;
    this.resetOptions();
  }

  public changeOption(item: ListOptions, id?: number, name?: string): void {
    this.closePopUp();
    this.store.dispatch(ResetPageCountLoadDataAction());
    this.store.dispatch(ClearFoundActionSuccess());
    this.reset();

    if (item.info === "1" || item.info === "2") {
      this.router.navigateByUrl(this.locale.getRouterLinkApp('catalog') + '/' + item.url);
      return;
    }
    if (item.info === "3") {
      this.router.navigateByUrl(this.locale.getRouterLinkApp('category') + '/' + item.url);
      return;
    }

    this.router.navigate(this.locale.getReturnParamsLang(['search', item.url]), {
      queryParams: {
        sectionId: id,
        info: item.info,
        pattern: name === "ath" ? item.url : item.name,
        pStart: 1,
        pEnd: 1
      }
    });
    if (this.isSearchPageResult()) {
      this.store.dispatch(SetTargetSuccess({
        target: item.name, life: '', option: {
          id: +id,
          name: item.name,
          url: item.url,
          info: item.info
        },
        p: false
      }));

      this.store.dispatch(ResetFilterBufferAction({ status: true }));
    }
  }

  public isSearchPageResult(): boolean {
    return window.location.pathname.includes('/search/');
  }

  public historyChangeOption(item: HistorySearchList): void {
    this.store.dispatch(ClearFoundActionSuccess());
    if (!item.option) {
      this.requestCustomSearch(item.value);
    } else {
      this.changeOption(item.option, item.option.id);
    }
  }

  public clearField(): void {
    this.isFocusSearch = true;
    this.reset();
    this.desktop_visible_droplist = false;
    this.resetOptions();
  }

  public focusIn(): void {
    this.isFocusSearch = true;
  }

  public focusOut(): void {
    this.listOptions.pipe(take(1)).subscribe((options) => {
      setTimeout(() => {
        this.isFocusSearch = (!(this.hs?.length === 0 && options.length === 0));
      }, 0);
    });
  }

  private reset(): void {
    this.inputSearch.reset();
  }

  private resetOptions(): void {
    this.store.dispatch(ClearOptionsSuccess());
  }

  private clearErrorMessage(): void {
    this.errorMessage = '';
  }

  private clearErrorMessageWithTimeout(): void {
    setTimeout(_ => this.clearErrorMessage(), 4000);
  }

  private addErrorMessage(text: string): void {
    this.errorMessage = text;
  }

  private getListOptions(): void {
    this.listOptions = this.store.pipe(select(selectListoptions));
  }

  private getListHistorySearch(target: string, data: HistorySearchList[]): void {
    this.hs = !target ? data : data.filter(item => item.value.includes(target));
  }

  private subSearchOnType(): void {
    this.searchTargetStringSub$ = this.inputSearch.valueChanges.pipe(
      map((value: string | null) => {
        this.clickInput(value);
        return value ? value.replace('/', '') : null;
      }),
      filter((item: string | null) => !!item && item.length > 2),
      debounceTime(500),
      switchMap(target => of(this.store.dispatch(SetTargetSuccess({ target, life: 'option' }))))
    );
  }

  private static isTrueLength(l: string): boolean {
    return l.length >= 3;
  }

  ngOnDestroy(): void {
    this.componentDestroyer.next(null);
    this.componentDestroyer.complete();
  }

}

@NgModule({
  declarations: [InputSearchComponent],
  imports: [
    CommonModule,
    SharedModule,
    CatalogPopupModule,
    TranslocoModule,
    InputSearchWrapperFieldModule,
    ReactiveFormsModule
  ],
  exports: [InputSearchComponent]
})
export class InputSearchModule { }
