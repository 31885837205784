import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BtnLangComponent } from './btn-lang.component';
import { TranslocoModule } from '@ngneat/transloco';
import {RouterModule} from '@angular/router';
import {SharedModule} from '@shared';



@NgModule({
  declarations: [BtnLangComponent],
    imports: [
        CommonModule,
        TranslocoModule,
        RouterModule,
        SharedModule
    ],
  exports: [BtnLangComponent]
})
export class BtnLangModule { }
