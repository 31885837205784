import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '@appStates';
import { StartForNewDataPageService } from '../services/helpers/help-service-for-search-page/start-for-new-data-page.service';
import { AddPageCountLoadDataAction, SetFaqForListPageAction, SetSeoAnalogsForListPageAction, SetTableForListPageAction } from '../../store/actions';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PreparatGuard  {
  constructor(
    private readonly helperDataService: StartForNewDataPageService,
    private readonly store: Store<AppState>
  ) { }

  resolve(
    route: ActivatedRouteSnapshot
  ): Observable<{ isPreparatPage: boolean, town: string }> {
    const town: string = route.params.town;
    const page: string = route.params.page ? route.params.page.replace('page', '') : '1';

    this.store.dispatch(SetFaqForListPageAction({ faq: null }));
    this.store.dispatch(SetTableForListPageAction({ table: null }));
    this.store.dispatch(SetSeoAnalogsForListPageAction({ analogs: null }));
    this.store.dispatch(AddPageCountLoadDataAction({ pages: [+page, +page] }));

    const params = {
        ...route.queryParams,
        sectionId: '1',
        info: 'trade',
        pattern: route.params.url,
        tradeNameEn: 'true',
        pStart: page,
        pEnd: page,
    };

    this.helperDataService.startDispatchAction(true, 'by-town', params, null, town);

    return of({ isPreparatPage: true, town });
  }
}
