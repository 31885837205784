import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReturnBtnArrowComponent } from './return-btn-arrow.component';

@NgModule({
  declarations: [ReturnBtnArrowComponent],
  imports: [
    CommonModule
  ],
  exports: [ReturnBtnArrowComponent]
})
export class ReturnBtnArrowModule { }
