import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT, PlatformLocation } from '@angular/common';
import { SeoFacebookCard, SeoTwitterCard } from '@interfaces';
import { TranslocoService } from '@ngneat/transloco';
import { take } from 'rxjs/operators';
import { environment } from '@env';
import { Pages } from '@enums';
import { LocalizationService } from '../localization.service';


@Injectable({
  providedIn: 'root'
})
export class SeoService {
  private renderer: Renderer2;
  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly rendererFactory: RendererFactory2,
    private readonly locale: LocalizationService,
    private readonly platform: PlatformLocation,
    private readonly meta: Meta,
    private readonly title: Title,
    private readonly translocoService: TranslocoService
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  public setStaticTitle(value: string, params?: any, lang?: string): void {
    const language: string = lang ? lang : this.locale.getLang();
    const par = params ? params : {};
    this.translocoService.selectTranslate(value, par, language)
      .pipe(take(1))
      .subscribe((translate: string) => this.setStaticTitleWithOutTranslate(translate));
  }

  public setStaticTitleWithOutTranslate(value: string): void {
    this.title.setTitle(value);
    this.meta.addTag({ name: 'twitter:title', content: value });
    this.meta.updateTag({ name: 'twitter:title', content: value });
  }

  public addDescriptionPage(names: string[]): void {
    const lang = this.locale.getLang();
    this.translocoService.selectTranslate('descriptionForProductPage', {}, lang)
      .pipe(take(1))
      .subscribe((translate: string) => {
        this.meta.addTag( { name: 'description', content: names[0] + translate + names[1] });
      });
  }

  public setStaticDescription(value: string, params?: any, lang?: string): void {
    const language = lang ? lang : this.locale.getLang();
    const par = params ? params : {};
    this.translocoService.selectTranslate(value, par, language)
      .pipe(take(1))
      .subscribe((translate: string) => {
        this.setStaticDescriptionWithOutTranslate(translate);
      });
  }

  public setStaticDescriptionWithOutTranslate(value: string): void {
    this.meta.addTag({ name: 'description', content: value });
    this.meta.updateTag({ name: 'description', content: value });
    this.meta.addTag({ name: 'twitter:description', content: value });
    this.meta.updateTag({ name: 'twitter:description', content: value });
  }

  public addTwitterCard(data: SeoTwitterCard): void {
    this.meta.addTags([
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:title', content: data.title },
      { name: 'twitter:description', content: data.description },
      { name: 'twitter:image', content: data.image },
      { name: 'twitter:image:alt', content: data.alt }
    ]);
  }

  public addFacebookCard(data: SeoFacebookCard): void {
    this.meta.addTag({ name: 'og:title', content: data.title });
    this.meta.addTag({ name: 'og:description', content: data.description });
    this.addOgImage(data.image);
  }

  public addOgTypeWebsite(): void {
    this.meta.addTag({ name: 'og:type', content: 'website' });
    this.meta.updateTag({ name: 'og:type', content: 'website' });
  }

  public addOgUrl(): void {
    this.meta.addTag({ name: 'og:url', content: this.platform.pathname });
    this.meta.updateTag({ name: 'og:url', content: this.platform.pathname });
  }

  public addOgImage(url: string): void {
    this.meta.addTag({ name: 'og:image', content: url });
    this.meta.updateTag({ name: 'og:image', content: url });
  }

  public setTwitterSummary(logoPath: string): void {
    this.meta.addTags([
      { name: 'twitter:card', content: 'summary' },
      { name: 'twitter:image', content: logoPath },
      { name: 'twitter:image:alt', content: 'Zdorovi.ua Logo' }
    ]);
  }

  // block index and follow
  public addBlockedForIndexing(): void {
    this.meta.addTag({ name: 'robots', content: 'noindex, nofollow' });
    this.meta.updateTag({ name: 'robots', content: 'noindex, nofollow' });
  }

  // block index, but follow
  public addBlockedOnlyForIndexing(): void {
    this.meta.addTag({ name: 'robots', content: 'noindex, follow' });
  }

  public removeBlockedForIndexing(): void {
    this.meta.addTag({ name: 'robots' });
    this.meta.updateTag({ name: 'robots' });
  }

  public addCustomBlockedForIndexing(content: string): void {
    this.removeBlockedForIndexing();
    this.meta.addTag({ name: 'robots', content });
    this.meta.updateTag({ name: 'robots', content });
  }

  // end block for robots google bot
  public resetAll(): void {
    this.meta.removeTag('name=\'og:title\'');
    this.meta.removeTag('name=\'og:type\'');
    this.meta.removeTag('name=\'og:description\'');
    this.meta.removeTag('name=\'og:image\'');
    this.meta.removeTag('name=\'og:url\'');
    this.meta.removeTag('name=\'twitter:card\'');
    this.meta.removeTag('name=\'twitter:title\'');
    this.meta.removeTag('name=\'twitter:description\'');
    this.meta.removeTag('name=\'twitter:image\'');
    this.meta.removeTag('name=\'twitter:image:alt\'');
    this.meta.removeTag('name=\'description\'');
  }

  public updateLinkHreflang(url?: string): void {
    const origin: string = environment.baseUrl;
    const search: string = this.platform.search;

    const pathname: string[] = this.platform.pathname.split('/').reverse();
    pathname.pop();
    pathname.reverse();
    if (pathname[0] === 'ua') {
      pathname.reverse().pop();
      pathname.reverse();
    }

    let lua: string = origin + '/ua/' + pathname.join('/') + search;
    let lru: string = origin + '/' + pathname.join('/') + search;

    // если на главной странице удалить лишний слеш в конце
    if (!Boolean(pathname.join('/')) && !Boolean(search)) {
      lua = lua.substring(0, lua.length - 1);
      lru = lru.substring(0, lru.length - 1);
    }

    const arr: any[] = Array.from(this.document.head.children);

    const linkRU: any = arr.find((e: any) => e.rel === 'alternate' && e.hreflang === 'ru');
    const linkUA: any = arr.find((e: any) => e.rel === 'alternate' && e.hreflang === 'uk');

    this.renderer.setAttribute(linkRU, 'href', lru);
    this.renderer.setAttribute(linkUA, 'href', lua);
  }

  public updateLinkCanonical(url: string): void {
    const canonical: string = url;

    const canonicalLinks: Element = document.querySelector('link[rel=\'canonical\']');
    const head: HTMLHeadElement = this.document.head;

    if (head === null) {
      throw new Error('<head> not found within DOCUMENT.');
    }

    if (!!canonicalLinks) {
      this.renderer.removeChild(head, canonicalLinks);
    }

    const createdLink: HTMLLinkElement = this.document.createElement('link');

    this.document.head.appendChild(createdLink);
    createdLink.setAttribute('rel', 'canonical');
    createdLink.setAttribute('href', canonical);
  }

  public deleteCanonical(): void {
    const canonicalLinks: Element = document.querySelector('link[rel=\'canonical\']');
    const head: HTMLHeadElement = this.document.head;

    if (head === null) {
      throw new Error('<head> not found within DOCUMENT.');
    }

    if (!!canonicalLinks) {
      this.renderer.removeChild(head, canonicalLinks);
    }
  }

  public getTegRobots(page: Pages): void {
    const exeptionForIndex: Set<Pages> =
      new Set<Pages>([
        Pages.start,
        Pages.services,
        Pages.category,
        Pages.sitemap,
        Pages.often,
        Pages.info,
        Pages.tips,
        Pages.global,
        Pages.pharmacies,
      ]);

    if (!environment.index) {
      this.addBlockedForIndexing();
      return;
    }

    if (exeptionForIndex.has(page)) {
      this.addCustomBlockedForIndexing('index,follow');
    } else {
      this.addBlockedForIndexing();
    }
  }

}
