import { Injectable } from '@angular/core';
import Hotjar from '@hotjar/browser';

import { environment } from '@env';

@Injectable()
export class HotjarService {

  constructor() {
    this.init();
  }

  init(): void {
    Hotjar.init(
      Number(environment.hotjar.trackingCode),
      Number(environment.hotjar.version)
    )
  }
}
