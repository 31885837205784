import { AppState } from '@appStates';
import { ActionReducerMap } from '@ngrx/store';
import { globalAppReducer } from './global-app.reducer';
import { pharmcyReducer } from './pharmacy.reducer';
import { searchReducer } from './search.reducer';
import { startPageReducer } from './start-opage.reducer';
import { productReducer } from './info-drug.reducer'
import { favoriteReducer } from './favorite.reducer';
import { viewedReducer } from './viewed.reducer';
import { orderReducer } from './order.reducer';
import { routerReducer } from '@ngrx/router-store';
import { navigationReducer } from './navigation.reducer';
import { privatePageReducer } from './private-page.reducer';
import {contactsReducer} from './contacts.reducer';
import { catalogReducer } from './catalog.reducer';
import { locationReducer } from './location.reducer';

export const appReducer: ActionReducerMap<AppState, any> = {
    globalApp: globalAppReducer,
    startPage: startPageReducer,
    search: searchReducer,
    infoForDrug: productReducer,
    pharmacies: pharmcyReducer,
    user: null,
    favorite: favoriteReducer,
    viewed: viewedReducer,
    order: orderReducer,
    router: routerReducer,
    prevRout: navigationReducer,
    privatePage: privatePageReducer,
    contacts: contactsReducer,
    catalog: catalogReducer,
    location: locationReducer
};

