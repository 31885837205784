<div class="search-city-wrapper" [class.extra-padding-top]="addExtraPadding">
    <img class="search-icon" src="../../../../assets/search.svg" [class.top-position-without-padding]="!addExtraPadding">

    <input class="input-search-city ellipsis" [formControl]="cityControl" type="text" [placeholder]="'type-region' | transloco">

    <ul id="hints-list" class="hints-list scrollbar" *ngIf="searchCitiesHints.length > 0">
        <li [id]="'city-' + hint.id" *ngFor="let hint of searchCitiesHints" (click)="selectCityHint(hint)">
            {{ currentLang == 'ru' ? hint.hintRu : hint.hintUa }}
        </li>
    </ul>
</div>