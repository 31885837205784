import { SetCitiesAction, SetFullAddressesAction, SetSearchCitiesHintsAction } from '../../../store/actions';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppState } from '@appStates';
import { ICity, LocationServiceType } from '@interfaces';
import { Store } from '@ngrx/store';
import { EMPTY, Observable } from 'rxjs';
import { RestUrlService, TypeUrl } from '../api.services/settings/rest-url.service';
import { catchError } from 'rxjs/operators';
import { IFullAddressResponse } from 'src/app/shared/interfaces/location/full-adress-response.model';

@Injectable({
  providedIn: 'root'
})
export class LocationService implements LocationServiceType {

  private readonly _url: string;

  constructor(
    private readonly http: HttpClient,
    private readonly urlRest: RestUrlService,
    private readonly store: Store<AppState>
  ) {
    this._url = 'https:' + this.urlRest.getUrl(TypeUrl.location);
  }


  public getCities(): void {
    this.http.get(this._url + '/address_hints/city').pipe(
      catchError(() => EMPTY)
    ).subscribe((response: ICity[]) => {
      this.store.dispatch(SetCitiesAction({ cities: response }));
    });
  }

  public searchTownByPattern(pattern: string): void {
    const params: HttpParams = new HttpParams()
      .set('pattern', pattern);
    this.http.get(this._url + '/address_hints/town', { params: params }).pipe(
      catchError(() => EMPTY)
    ).subscribe((response: ICity[]) => {
      this.store.dispatch(SetSearchCitiesHintsAction({ cities: response }));
    });
  }

  public searchFullAdress(pattern: string, townId: number): void {
    const params: HttpParams = new HttpParams()
      .set('pattern', pattern)
      .set('town_id', townId.toString());
    this.http.get<IFullAddressResponse>(this._url + '/address_hints/full_address', { params: params }).pipe(
      catchError(() => EMPTY)
    ).subscribe((fullAddresses: IFullAddressResponse) => {
      this.store.dispatch(SetFullAddressesAction({ fullAddresses }));
    });
  }

  public searchAdressByCoordinates<IBuilding>(latitude: number, longitude: number): Observable<IBuilding> {
    const params: HttpParams = new HttpParams()
      .set('latitude', latitude.toString())
      .set('longitude', longitude.toString());
    return this.http.get<IBuilding>(this._url + '/address_hints/coordinates', { params: params }).pipe(
      catchError(() => EMPTY)
    );
  }

  public getTownByName(townName: string): Observable<ICity> {
    const params: HttpParams = new HttpParams()
      .set('townEn', townName);
    return this.http.get<ICity>(this._url + '/address_hints/town-info', { params: params }).pipe(
      catchError(() => EMPTY)
    );
  }


}
