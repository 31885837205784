import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StaticFooterComponent } from './static-footer.component';
import { SharedModule } from '@shared';
import { TranslocoModule } from '@ngneat/transloco';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [StaticFooterComponent],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    TranslocoModule
  ],
  exports: [StaticFooterComponent]
})
export class StaticFooterModule { }
