export enum colorApp {
  $primary = '#fefefe',
  $secondary = '#f5f8fd',
  $white = '#fefefe',
  $background = '#e4e4e4',
  $primary_font = '#292929bf',
  $secondary_font = '#0A0A0A',
  $third_font = '#5b5b5b',
  $primary_warn = '#fea724',
  $secondary_warn = '#fea724',
  $primary_success = '#449bd1',
  $secondary_sucsess = '#449bd1',
  $primary_error = '#ef5086',
  $secondary_error = '#ef5086'
}

export enum PrimaryColors {
  ce1 = '#449bd1',
  ce2 = '#ef5086',
  ce3 = '#fea724',
  icon = 'rgba(54, 54, 54, .9)',
  text = '#292929bf'
}
