import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { TypeDevice } from '@enums';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ScreenDetectedService {

  constructor(@Inject(PLATFORM_ID) private platformId: {}) {
  }
  public resizeObservable$: Observable<Event>;
  private resizeSubscriptione$: Subscription;
  private mobileBorder = 960;
  private currentTypeScreen: TypeDevice;

  public observerChangeSizeScreen(): void {
    this.onDestroyObserver();
    this.currentTypeScreen = this.geTypeDevice();
    this.resizeObservable$ = fromEvent(window, 'resize');

    this.resizeSubscriptione$ = this.resizeObservable$
      .pipe(
        filter(() => this.geTypeDevice() !== this.currentTypeScreen),
      )
      .subscribe(() => {
        this.currentTypeScreen = this.geTypeDevice();
        window.location.reload();
      });
  }

  public geTypeDevice(): TypeDevice {
    if (isPlatformBrowser(this.platformId)) {
      return window.innerWidth > this.mobileBorder ? TypeDevice.desktop : TypeDevice.mobile;
    } else {
      return TypeDevice.mobile;
    }

  }

  public isDesktop(): boolean {
    return this.geTypeDevice() === TypeDevice.desktop;
  }

  public isMobile(): boolean {
    return this.geTypeDevice() === TypeDevice.mobile;
  }

  public onDestroyObserver(): void {
    if (this.resizeSubscriptione$) { this.resizeSubscriptione$.unsubscribe(); }
  }
}
