import { NgModule } from '@angular/core';
import { NoPreloading, RouterModule, Routes } from '@angular/router';
import { routes } from './routs';

const homeRoutes: Routes = [
  {
    path: '',
    loadChildren: () => import('../pages/start/start.module').then(m => m.StartModule)
  },
  {
    path: 'ua',
    loadChildren: () => import('./lang-ua/lang-ua.module').then(m => m.LangUaModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot([...homeRoutes, ...routes],
      {
        preloadingStrategy: NoPreloading,
        onSameUrlNavigation: 'reload',
        initialNavigation: 'enabledBlocking',
        scrollPositionRestoration: 'top'
      }
    )],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }
