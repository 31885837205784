import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared';
import { TranslocoModule } from '@ngneat/transloco';
import { BzPharmacyInfoComponent } from './bz-pharmacy-info.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [BzPharmacyInfoComponent],
  imports: [
    CommonModule,
    SharedModule,
    TranslocoModule,
    RouterModule
  ], 
  exports: [BzPharmacyInfoComponent]
})
export class BzPharmacyInfoModule { }
