export enum KeysLS {
    hs = 'hs',
    id = 'id',
    lang = 'lang',
    address = 'address',
    label = 'label',
    location = 'location',
    cityID = 'cityId',
    historyOrdersId = 'historyOrdersId',
    ordersHistoryIdsWithDate = 'ordersHistoryIdsWithDate',
    viewed = 'viewed',
    favorite = 'favorite',
    history = 'history',
    cart = 'cart',
    list = 'list',
    orderWithError = 'orderWithError',
    addressHistory = 'addressHistory',
    phones = 'phones',
    fsr = 'fsr',
    cityName = 'cityName',
    city = 'city',
    scrollPosition = 'scrollPosition',
    url = 'url',
    nameEn = 'nameEn',
    userTown = 'userTown'
}
