import {
  GetFoundSuccess, GetOptionsSuccess,
  SetFaqForListPageAction, SetH1ForSearchListPageAction,
  SetPageLoadingAction,
  SetPreparatSeoDataAction,
  SetSeoAnalogsForListPageAction,
  SetSeoTextWithEditorForThirdLvlAction,
  SetTableForListPageAction, SetTargetSuccess
} from '@actions';
import { Injectable } from '@angular/core';
import { BzProduct, ICatalogTextWithEditor, IPreparatPageResponse, ISearchResultResponse, ITableCatalogProductsResponse, ITradeGoodsResponse, Options } from '@interfaces';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { forkJoin, from, of } from 'rxjs';
import { exhaustMap, map, switchMap, take } from 'rxjs/operators';
import { AppState } from '@appStates';
import { select, Store } from '@ngrx/store';
import { selectShowMore } from '@selectors';
import { SearchGoodsApiService } from '../../core/services/api.services/search-goods-api.service';
import { CatalogProductsService } from 'src/app/core/services/api.services/catalog-products.service';
import { TradeNameGoodsService } from 'src/app/core/services/api.services/trade-name-goods.service';

@Injectable()
export class SearchEffects {

  constructor(
    private actions$: Actions,
    private searchGoodsApiService: SearchGoodsApiService,
    private store: Store<AppState>,
    private catalogProductsService: CatalogProductsService,
    private tradeNameGoodsService: TradeNameGoodsService
  ) { }

  getOptionsForTarget$ = createEffect(() => this.actions$
    .pipe(
      ofType(SetTargetSuccess),
      exhaustMap(
        (action) => {
          switch (action.life) {
            case 'option':
              return this.searchGoodsApiService.getOptionsOnType(action.target).pipe(
                map((options: Options[]) => GetOptionsSuccess({ options })),
              );
            case '':
              return this.searchGoodsApiService.getFoundsTargetSearch(
                action.option,
                action.p,
                action.anyParams
              ).pipe(
                switchMap((found: ISearchResultResponse) => forkJoin(
                  [
                    of(found),
                    this.store.pipe(select(selectShowMore), take(1)),
                    found?.h1 ? this.catalogProductsService.getCatalogProductsTextWithEditor(action.option.url) : of(null),
                    found?.tradeNameTitle && !action.option.town ? this.tradeNameGoodsService.getFaqForTradeNamePage(action.option.name) : of(null),
                    found?.tradeNameTitle && !action.option.town ? this.tradeNameGoodsService.getPricesTableForTradeNamePage(action.option.name) : of(null),
                    found?.tradeNameTitle && !action.option.town ? this.tradeNameGoodsService.getAnalogsForTradeNamePage(action.option.name) : of(null),
                    action.option.town ? this.tradeNameGoodsService.getSeoDataForPreparatPage(action.option.name, action.option.town) : of(null)
                  ]
                )),
                switchMap((
                  [
                    found,
                    showMore,
                    textWithEditor,
                    faq,
                    table,
                    analogs,
                    preparatSeoData
                  ]: [
                      ISearchResultResponse,
                      boolean,
                      ICatalogTextWithEditor | null,
                      ITradeGoodsResponse | null,
                      ITableCatalogProductsResponse | null,
                      BzProduct[] | null,
                      IPreparatPageResponse | null
                    ]) => {
                  return from([
                    SetH1ForSearchListPageAction({ title: found?.tradeNameTitle ?? '' }),
                    GetFoundSuccess({ found, showMore }),
                    SetSeoTextWithEditorForThirdLvlAction({ textWithEditor }),
                    SetFaqForListPageAction({ faq }),
                    SetTableForListPageAction({ table }),
                    SetSeoAnalogsForListPageAction({ analogs }),
                    SetPreparatSeoDataAction({ seoData: preparatSeoData }),
                    SetPageLoadingAction({ loading: false })
                  ]);
                }),
              );
          }
        },
      )
    )
  );

}
