import { Injectable } from '@angular/core';
import { AppState } from '@appStates';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ROUTER_REQUEST, ROUTER_NAVIGATED } from '@ngrx/router-store';
import { mergeMap, map, withLatestFrom } from 'rxjs/operators';
import { getCurrentRouteState } from '../selectors/get-current-route-state.selectors';
import { of } from 'rxjs';
import { AddNavigationsPrevRout } from '../actions/navigation.actions';
import { RouterStateUrl } from '../router/custom-serializer';

@Injectable()
export class NavigateEffects {

  constructor(private actions$: Actions, private store: Store<AppState>) {}

  getNavigateApp$ = createEffect(
    () => this.actions$.pipe(
      ofType(ROUTER_REQUEST),
      withLatestFrom(this.store.select(getCurrentRouteState)),
      mergeMap(([action, state]) => {
        return of([action, state]).pipe(
          map((res: any) => {
            const rout: RouterStateUrl = res[0].payload.routerState;
            const state = res[0]?.payload.event;
            return AddNavigationsPrevRout({rout, state});
          })
        )
      })
    )
  )

}
