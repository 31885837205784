import { CommonModule, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, Input, NgModule, OnInit, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationRoutingService } from '@application-routing';
import { AppState } from '@appStates';
import { LocalizationService, LocalStorageService, ScreenDetectedService } from '@core/services';
import { BtnTypeColor, Languages } from '@enums';
import { WorkingPanelMenu } from '@interfaces';
import { TranslocoModule } from '@ngneat/transloco';
import { select, Store } from '@ngrx/store';
import { selectCurrentLang } from '../../../store/selectors';
import { Observable } from 'rxjs';
import { PrimaryColors } from 'src/app/shared/enums/colors.app.enum';
import { PrivatePageExceptionReturnBtn, SetCurrentCityAction } from '../../../store/actions';
import { ButtonModule } from '../../action-elements/bz-button/button.module';
import { BtnLangModule } from 'src/app/modules-ui/action-elements/bz-btn-lang/btn-lang.module';
import { ScrollPositionLocalStorageService } from '../../../core/services/storage.services/scroll-position-local-storage.service';
import { LocationStorageService } from 'src/app/core/services/storage.services/location-storage-service.service';
import { Actions, ofType } from '@ngrx/effects';
import { CookiesServiceService } from 'src/app/core/services/storage.services/cookies-service.service';

@Component({
  selector: 'app-working-panel',
  templateUrl: './working-panel.component.html',
  styleUrls: ['./working-panel.component.sass']
})
export class WorkingPanelComponent implements OnInit {
  @Input() public width = '100vw';
  @Input() public menu: Observable<WorkingPanelMenu[]>;

  public colors: typeof BtnTypeColor = BtnTypeColor;
  public colorsApp: typeof PrimaryColors = PrimaryColors;
  public lang: Observable<string> = this.store.pipe(select(selectCurrentLang));
  public listCount: Observable<number>;
  public listSum: Observable<number>;
  public city: string;

  public cartCount: number;
  public cartSum: number;
  public show: boolean;

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: Object,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly routingService: ApplicationRoutingService,
    private readonly screenDetectedService: ScreenDetectedService,
    private readonly spls: ScrollPositionLocalStorageService,
    private readonly router: Router,
    private readonly store: Store<AppState>,
    private readonly locale: LocalizationService,
    private readonly localStorageService: LocalStorageService,
    private readonly location: LocationStorageService,
    private readonly actions: Actions,
    private readonly cook: CookiesServiceService
  ) {
    this.setInitCityName();
  }

  ngOnInit(): void {
    this.cityChangeListener();
    this.onlyBrowserRun();
  }

  public clickElMenu(event: any): void {
    this.store.dispatch(PrivatePageExceptionReturnBtn({ exception: null }));
    if (!this.screenDetectedService.isDesktop()) {
      setTimeout(() => this.spls.scrollPosition = '0', 50);
    }
    if (event.description !== 'translate') {
      this.routingService.navigate(event.component, this.router.routerState.snapshot.url);
    }
  }

  public translate(): void {
    this.animationBtnLang();
    let language: string = this.locale.getLang();
    language = language === Languages.ru ? Languages.ua : Languages.ru;
    this.locale.setLang(language, true);
  }

  public animationBtnLang(): void {
    this.show = true;
    setTimeout(() => this.show = false, 200);
  }

  private onlyBrowserRun(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.listCount = this.localStorageService.listCountSubject.asObservable();
      this.localStorageService.getListLength();
      this.listSum = this.localStorageService.listSumSubject.asObservable();
      this.localStorageService.sumProductsList();

      this.localStorageService.cartCountSubject.asObservable().subscribe(data => {
        this.cartCount = data;
        this.changeDetectorRef.detectChanges();
      });

      this.localStorageService.getCartLength();

      this.localStorageService.cartSumSubject.asObservable().subscribe(data => {
        this.cartSum = data;
        this.changeDetectorRef.detectChanges();
      });

      this.localStorageService.sumCartProducts();
    }
  }

  private setInitCityName(): void {
    if (isPlatformServer(this.platformId)) {
      this.city = this.cook.getItem('cityName')
        ? decodeURIComponent(this.cook.getItem('cityName'))
        : this.locale.getLang() === 'ua' ? 'Київ' : 'Киев';
    } else {
      this.city = this.location.cityName ? this.location.cityName
        : this.cook.getItem('cityName') ? decodeURIComponent(this.cook.getItem('cityName'))
          : this.locale.getLang() === 'ua' ? 'Київ' : 'Киев';
    }
  }

  private cityChangeListener(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.actions.pipe(
        ofType(SetCurrentCityAction),
      ).subscribe(() => {
        this.city = this.location.cityName ? this.location.cityName
          : this.cook.getItem('cityName') ? decodeURIComponent(this.cook.getItem('cityName'))
            : this.locale.getLang() === 'ua' ? 'Київ' : 'Киев';
      });
    }
  }

}

@NgModule({
  declarations: [WorkingPanelComponent],
  exports: [
    WorkingPanelComponent
  ],
  imports: [
    CommonModule,
    ButtonModule,
    BtnLangModule,
    TranslocoModule
  ]
})
export class WorkingPanelModule { }
