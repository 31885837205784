import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BtnTypeColor, BtnTypeContent } from '@enums';

@Component({
  selector: 'bz-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.sass']
})
export class ButtonComponent {
  public pushTest: boolean = false;
  public listContent = BtnTypeContent;

  @Input() type: BtnTypeColor = BtnTypeColor.none;
  @Input() k: number = null;
  @Input() text: string;
  @Input() textColor: string = '#66666a';
  @Input() borderColor: string = null;
  @Input() icon: string;
  @Input() defaultIconColor: string = '#757575';
  @Input() content: BtnTypeContent = BtnTypeContent.text;
  @Input() size: { w: number, h: number } = { w: null, h: null };
  @Input() radius: any = false;
  @Input() hover: 'invert' = null;
  @Input() fontSize: string;
  @Input() setTextColorForInnerSpan: boolean = false;

  @Input() disabled = false;
  @Input() hoverTextDisabled: string;

  @Input() isCounter: boolean;
  @Input() counterValue: string;

  @Output() public appClick: EventEmitter<MouseEvent> = new EventEmitter();

  public clickBtn(e: MouseEvent): void {
    if (this.disabled) { return; }
    this.pushTest = true;
    setTimeout(_ => this.pushTest = false, 300);
    this.appClick.emit(e);
  }

}
