import { Injectable } from '@angular/core';
import { StartForNewDataPage } from './start-for-new-data-page';
import { SetTargetSuccess } from '../../../../store/actions';
import { AppState } from '@appStates';
import { Store } from '@ngrx/store';
import { SearchPageParamsName } from './concat-params';
import { ConcatParamsService } from './concat-params.service';

@Injectable({
  providedIn: 'root'
})
export class StartForNewDataPageService implements StartForNewDataPage {

  constructor(
    private readonly store: Store<AppState>,
    private readonly helperParamsService: ConcatParamsService
  ) { }

  public startDispatchAction(
    additionalLoading: boolean = false,
    url: string,
    params: SearchPageParamsName,
    anyParams?: SearchPageParamsName,
    town?: string,
  ): void {
    if (url && Object.keys(params ?? {}).length > 0) {
      this.store.dispatch(SetTargetSuccess({
        target: params.pattern,
        life: '',
        option: {
          id: +params.sectionId,
          name: params.pattern,
          url: url,
          info: params.info,
          tradeNameEn: params.tradeNameEn,
          town,
        },
        p: additionalLoading,
        anyParams: this.helperParamsService.concat(anyParams, params)
      }));
    }
  }
}
