<button
  class="btn-app"
  [attr.aria-label]="text"
  (click)="clickBtn($event)"
  [ngSwitch]="content"
  [style]="'--hoverBack:' + borderColor + ';' + '--hoverColor:' + type"
  [ngStyle]="{
        'filter': !disabled ? 'none' : 'none',
        'border': borderColor ? '1px solid ' + borderColor : 'none',
        'padding': content === listContent.text ? '4px 8px' : content === listContent.all ? '10px 8px 10px 8px' : '0',
        'width': k ? (size.w ? size.w * k + 'px' : 'auto') : (size.w ? size.w + 'px' : 'auto'),
        'height': k ? (size.h ? size.h * k + 'px' : 'auto') : (size.h ? size.h + 'px' : 'auto'),
        'color': textColor,
        'border-radius': radius.length === 1 ? (radius ? '50%' : 0) : (radius[0] + radius[1] + radius[2]  + radius[3]),
        'background': !disabled ? type : '#e4e4e4'
        }">

  <span class="text-in-btn"
    *ngSwitchCase="listContent.text"
    [textContent]="text"
    [ngStyle]="{
      'font-size' : fontSize,
      'font-family': 'Arial, Helvetica, sans-serif',
      'color': setTextColorForInnerSpan ? textColor : ''
    }">
  </span>

  <span *ngIf="isCounter" class="count">{{counterValue}}</span>

  <img *ngSwitchCase="listContent.icon" [ngStyle]="{
            'width': size.w ? (size.w * .55) + 'px' : 'auto',
            'height': size.h ? (size.h * .55) + 'px' : 'auto'
        }" [ngClass]="{'push-img-btn': pushTest}" [src]="'../../../assets/' + icon" [alt]="'btn' | transloco"
    [alt]="'btn' | transloco">

  <div class="all-btn" *ngSwitchCase="listContent.all">

    <div class='all-btn-icon icon' [ngClass]="{'push-img-btn': pushTest}" [ngStyle]="
            {
            'background-color': defaultIconColor,
            'width': size.w ? (size.w * .55) + 'px' : 'auto',
            'height': size.h ? (size.h * .55) + 'px' : 'auto',
            'filter': disabled ? 'unset' : '',
            'mask': 'url(' + icon + ') no-repeat center',
            '-webkit-mask': 'url(../../../assets/' + icon + ') no-repeat center'}">
    </div>
    <span class='all-btn-text' [textContent]="text"></span>
  </div>


  <span *ngIf="disabled" class="hoverTextDisabled">
    <span class="inner-text">
      {{hoverTextDisabled}}
    </span>

  </span>

</button>
