import { NgModule} from '@angular/core';
import { CommonModule} from '@angular/common';
import { TranslocoModule} from '@ngneat/transloco';
import {SharedModule} from '@shared';
import {BzCatalogPopupComponent} from './bz-catalog-popup.component';
import {RouterModule} from '@angular/router';

@NgModule({
  declarations: [BzCatalogPopupComponent],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    TranslocoModule
  ],
  exports: [BzCatalogPopupComponent]
})
export class CatalogPopupModule { }
