import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { LocalizationService, ScreenDetectedService } from '@core/services';
import { Crumb } from '@interfaces';
import { TranslocoService } from '@ngneat/transloco';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { JsonLDServiceService } from 'src/app/core/services/seo.services/json-ldservice.service';

@Component({
  selector: 'bz-double-links-crumbs',
  templateUrl: './double-links-crumbs.component.html',
  styleUrls: ['./double-links-crumbs.component.sass']
})
export class DoubleLinksCrumbsComponent implements OnInit, OnDestroy {

  @Input() public secondLinkText: string;
  @Input() public secondLinkTextForScript: string;
  @Input() public setPaddingMobile: boolean = false;

  private firstLinkText: string;
  private homeLink: string;

  private destroy$: Subject<void> = new Subject();

  constructor(
    public readonly screenDetectedService: ScreenDetectedService,
    private readonly localizationService: LocalizationService,
    private readonly translocoService: TranslocoService,
    private readonly JsonLDService: JsonLDServiceService,
  ) { }

  ngOnInit(): void {
    this.generateLinks(this.localizationService.getLang());

    this.translocoService.selectTranslate(this.secondLinkTextForScript).pipe(
      takeUntil(this.destroy$)
    ).subscribe((secondLinkText: string) => {
      this.JsonLDService.add(
        JsonLDServiceService.Breadcrambs(
          JsonLDServiceService.mutationCrambs(
            this.generateCrumbs(secondLinkText)
          )
        ),
        'structured-data-crumbs'
      );
    });
  }

  public getHomePageLink(): string {
    return this.localizationService.getLang() === 'ru' ? '/' : '/ua';
  }

  private generateCrumbs(secondLinkText: string): Crumb[] {
    const crumbs: Crumb[] = [
      { name: this.firstLinkText, url: this.homeLink },
      { name: secondLinkText, url: null }
    ];
    return crumbs;
  }

  private generateLinks(lang: string): void {
    this.firstLinkText = lang === 'ru' ? 'Поиск лекарств' : 'Пошук ліків';
    this.homeLink = lang === 'ru' ? 'https://zdorovi.ua' : 'https://zdorovi.ua/ua';
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
    this.JsonLDService.remove();
  }

}
