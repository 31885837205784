import { AppState } from '@appStates';
import { createSelector } from '@ngrx/store';
import { PharmaciesPageState, QuantityForOneProduct } from '../state/pharmacies.state';
import { BzApteka, BzProduct, IBanner, IPharmacyFaq, IPharmacyTable, PharmacyMarker } from '@interfaces';
import { EntityState } from '@ngrx/entity';

const selectPharmacyState = (state: AppState): PharmaciesPageState => state.pharmacies;

const selectPharmacyStateMarkers = (state: AppState): EntityState<PharmacyMarker> => state.pharmacies.listMarkers;

export const selectIsTargetFromProductCardGetOtherCityPharmacies = createSelector(
  selectPharmacyState,
  (state: PharmaciesPageState): boolean => state.isTargetFromProductCardGetOtherCityPharmacies
);

export const selectIsGetOtherPharmacies = createSelector(
  selectPharmacyState,
  (state: PharmaciesPageState): boolean => state.isGetOtherCityPharmacies
);

export const selectListPharmacies = (type: 'top' | 'primary' | 'other') => createSelector(
    selectPharmacyState,
    (state: PharmaciesPageState): Map<number, BzApteka> => {
        switch (type) {
          case 'primary': return new Map([...state.listPharmacies].filter(([_, pharmacy]) => !pharmacy.toTop && !pharmacy.isOtherCity));
          case 'other': return new Map([...state.listPharmacies].filter(([_, pharmacy]) => pharmacy.isOtherCity));
          case 'top': return new Map([...state.listPharmacies].filter(([_, pharmacy]) => pharmacy.toTop));
        }
    }
);

export const selectTitleProductForOneProduct = createSelector(
    selectPharmacyState,
    (state: PharmaciesPageState): string => {
        if (state.listPharmacies) {
            const apteka: BzApteka = Array.from(state.listPharmacies.values())[0];
            if (apteka) {
                const products: BzProduct[] = apteka.products;
                if (products.length > 0) { return products[0].name; }
            } else {
                return;
            }
        }
    }
)

export const selectIsLoadingListPharmacies = createSelector(
    selectPharmacyState,
    (state: PharmaciesPageState): boolean => state.isLoadingListPharmacies
);

export const selectListMarkers = () => createSelector(
    selectPharmacyStateMarkers,
    (state: EntityState<PharmacyMarker>): PharmacyMarker[] => {
        return [...Object.values(state.entities)];
    }
);

export const selectCenterMap = createSelector(
    selectPharmacyState,
    (state: PharmaciesPageState): number[] => state.centerMap
);

export const selectTotalPharmaciesLength = createSelector(
    selectPharmacyState,
    (state: PharmaciesPageState): boolean => state.totalPharmaciesNumber
);

export const selectTotalPharmaciesOtherLength = createSelector(
    selectPharmacyState,
    (state: PharmaciesPageState): boolean => state.totalOtherPharmacies
);

export const selectZoomMap = createSelector(
    selectPharmacyState,
    (state: PharmaciesPageState): number => state.zoomMap
);

export const selectCountForOneProduct = createSelector(
    selectPharmacyState,
    (state: PharmaciesPageState): QuantityForOneProduct => state.quantity
);

export const selectPageCountPharmciesList = createSelector(
    selectPharmacyState,
    (state: PharmaciesPageState): number[] => state.page
);

export const selectOtherPageCountPharmciesList = createSelector(
    selectPharmacyState,
    (state: PharmaciesPageState): number[] => state.opage
);


export const selectOnePharmaForPopupActiveMarker = createSelector(
    selectPharmacyState,
    (state: PharmaciesPageState): BzApteka => state.activePopupPharmy
);

export const selectSwitchMobilePageMap = createSelector(
    selectPharmacyState,
    (state: PharmaciesPageState): boolean => state.isActiveMobilePageMap
);

export const selectActiveMobilesFiltersLength = createSelector(
    selectPharmacyState,
    (state: PharmaciesPageState): number => Object.values(state.activeMobileFilters.entities).length
);

export const selectCoordFromMarkers = (id: number) => createSelector(
    selectPharmacyStateMarkers,
    (state: EntityState<PharmacyMarker>): number[] => Object.values(state.entities)
        .filter((el: PharmacyMarker) => el.id === id)
        .map((i: PharmacyMarker) => [i.latitude, i.longitude])[0]
);

export const selectSeoFaqs = createSelector(
    selectPharmacyState,
    (state: PharmaciesPageState): IPharmacyFaq => state.pharmacyFaq
);

export const selectSeoPriceTabe = createSelector(
    selectPharmacyState,
    (state: PharmaciesPageState): IPharmacyTable => state.seoPrice
);

export const selectSeoPreparatText = createSelector(
    selectPharmacyState,
    (state: PharmaciesPageState): string => state.seoPreparat
);

export const selectPharmBanner = createSelector(
    selectPharmacyState,
    (state: PharmaciesPageState): IBanner => state.banner
);