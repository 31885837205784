import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { environment } from '@env';
import { map } from 'rxjs/operators';
import { Catalog, Crumb, ICatalogProducts, ICatalogTextWithEditor, ITableCatalogProductsResponse } from '@interfaces';
import { Pages } from '@enums';
import { LocalizationService, SeoService } from '@core/services';
import { JsonLDServiceService } from '../services/seo.services/json-ldservice.service';
import { CatalogApiService } from "../services/api.services/catalog-api.service";
import { CatalogProductsService } from '../services/api.services/catalog-products.service';

@Injectable({
  providedIn: 'root'
})
export class CatalogPageGuard {

  constructor(
    private readonly jld: JsonLDServiceService,
    private readonly catalogApiService: CatalogApiService,
    private readonly seo: SeoService,
    private readonly locale: LocalizationService,
    private readonly catalogProductsService: CatalogProductsService
  ) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    const lang = this.locale.getLang();
    const url: string | undefined = route.params.url;

    return forkJoin([
      this.catalogApiService.getCatalog<Catalog>(route.params.url),
      url ? this.catalogProductsService.getCatalogProducts(url) : of([]),
      url ? this.catalogProductsService.getCatalogProductsTable(url) : of(null),
      url ? this.catalogProductsService.getCatalogProductsTextWithEditor(url) : of(null)
    ]).pipe(
      map((
        [catalog, groups, table, textWithEditor]:
          [Catalog, ICatalogProducts[], ITableCatalogProductsResponse | null, ICatalogTextWithEditor | null]
      ) => {
        this.seo.getTegRobots(Pages.category);
        this.seo.updateLinkHreflang();
        if (catalog.id) {
          this.seo.setStaticTitleWithOutTranslate(catalog.seo_title);
          this.seo.setStaticDescriptionWithOutTranslate(catalog.seo_description);
        } else {
          this.seo.setStaticTitle('catalog.title');
          this.seo.setStaticDescription('catalog.description');
        }

        let crumbs: Crumb[] = [];

        const firstCrumb: Crumb = {
          name: lang === 'ua' ? 'Пошук ліків' : 'Поиск лекарств',
          url: lang === 'ua' ? `${environment.baseUrl}/ua` : environment.baseUrl,
        };

        const lastCrumb: Crumb = {
          name: catalog.h1,
        };

        if (catalog.crumbs) {
          const linkLang: string = lang === 'ua' ? '/ua/' : '/';
          crumbs = [
            firstCrumb,
            ...catalog.crumbs.filter((crumb) => crumb.name !== 'Каталог').map((crumb: Crumb) => {
              const linkInfo: string = crumb.info === '1' ? 'catalog/' : '';
              if (crumb.url) {
                return {
                  ...crumb,
                  url: environment.baseUrl + linkLang + linkInfo + String(crumb.url).toLowerCase(),
                }
              }
              return crumb;
            }),
            lastCrumb
          ];

          this.jld.add(
            JsonLDServiceService.Breadcrambs(
              JsonLDServiceService.mutationCrambs(crumbs)
            ),
            'structured-data-crumbs'
          );
        }

        return {
          value: catalog,
          h1: { seoH1: catalog.h1, originH1: catalog.h1 },
          groups,
          table,
          textWithEditor,
        };
      })
    );
  }
}
