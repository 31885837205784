import {
  ClearFoundActionSuccess,
  SetCurrentCityAction,
  SetLangAppSuccess
} from '../../store/actions';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { AppState } from '@appStates';
import { Languages } from '@enums';
import { TranslocoService } from '@ngneat/transloco';
import { select, Store } from '@ngrx/store';
import { Browsers } from 'src/app/shared/enums/browsers.enum';
import { DetectedBrowserService } from './detected-browser.service';
import { isPlatformServer, PlatformLocation } from '@angular/common';
import { CookiesServiceService } from './storage.services/cookies-service.service';
import { take } from 'rxjs/operators';
import { getPrevRouteState } from 'src/app/store/selectors/navigate.selectors';
import { RouterStateUrl } from 'src/app/store/router/custom-serializer';
import { LocationService } from './positioning.services/location.service';
import { IBuilding } from '@interfaces';
import { LangStorageService } from './storage.services/lang-storage.service';
import { LocationStorageService } from './storage.services/location-storage-service.service';

@Injectable({
  providedIn: 'root'
})
export class LocalizationService {

  constructor(
    private readonly platform: PlatformLocation,
    private readonly cook: CookiesServiceService,
    private readonly translocoService: TranslocoService,
    private readonly locationService: LocationService,
    private readonly langStorageService: LangStorageService,
    private readonly locationStorageService: LocationStorageService,
    private readonly store: Store<AppState>,
    private readonly router: Router,
    @Inject(PLATFORM_ID) private readonly platformId: Object
  ) { }

  public setLang(lang: Languages | string, reload: boolean = false): void {
    this.translocoService.setActiveLang(lang);
    this.langStorageService.lang = lang;
    this.cook.setItem('lang', lang);
    this.store.dispatch(SetLangAppSuccess({ lang }));
    this.locationService.getCities();
    if (reload) {
      this.redirectLang(this.getLang());
      if (DetectedBrowserService.whoHere(Browsers.safari)) {
        this.router.navigate(['/']);
      }
    }

    // перевод текущего города
    const localCoords: string = this.locationStorageService.location || this.cook.getItem('location');
    let result: string[];
    if (localCoords != null || undefined) {
      result = localCoords.split(',');
    } else {
      result = ['50.4500336', '30.5241361'];
    }
    this.locationService.searchAdressByCoordinates(+result[0], +result[1]).subscribe((data: IBuilding) => {
      this.locationStorageService.townEn = data.town.nameEn;
      this.cook.setItem('townEn', data.town.nameEn);
      this.cook.setItem('cityName', lang === 'ua' ? encodeURIComponent(data.town.nameUa) : encodeURIComponent(data.town.nameRu));
      this.locationStorageService.cityName = lang === 'ru' ? data.town.nameRu : data.town.nameUa;
      this.store.dispatch(SetCurrentCityAction({ city: lang === 'ru' ? data.town.nameRu : data.town.nameUa }));
    });

  }

  public getLang(): string {
    if (isPlatformServer(this.platformId)) {
      return this.cook.getItem('lang') ? this.cook.getItem('lang') :
        this.platform.pathname.includes('/ua') ? 'ua' : 'ru';
    } else {
      return this.langStorageService.lang ? this.langStorageService.lang :
        this.cook.getItem('lang') ? this.cook.getItem('lang') :
          this.platform.pathname.includes('/ua') ? 'ua' : 'ru';
    }
  }

  public default(): void {
    const local = this.platform.pathname.includes('/ua') ? 'ua' : 'ru';

    let lang: string;

    if (isPlatformServer(this.platformId)) {
      lang = this.cook.getItem('lang') ? this.cook.getItem('lang') :
        this.platform.pathname.includes('/ua') ? 'ua' : 'ru';
    } else {
      lang = this.langStorageService.lang ? this.langStorageService.lang :
        this.cook.getItem('lang') ? this.cook.getItem('lang') :
          this.platform.pathname.includes('/ua') ? 'ua' : 'ru';
    }


    if (lang !== local) { this.redirectLang(lang); }

    this.setLang(lang);
    this.store.dispatch(SetLangAppSuccess({ lang }));
  }

  public redirectLang(lang: string): void {
    if (window.location.href.includes('mobile')) {
      let prev: RouterStateUrl;
      this.store.pipe(
        take(1),
        select(getPrevRouteState)
      ).subscribe(prevUrl => prev = prevUrl);

      if (lang === 'ua') {
        if (prev.url == '/') {
          this.router.navigateByUrl('ua');
        } else {
          this.router.navigateByUrl('ua' + prev.url);
        }
      } else {
        this.router.navigateByUrl(prev.url.replace('/ua', ''));
      }

      this.store.dispatch(ClearFoundActionSuccess());

      return;
    }

    if (lang === 'ru') {
      this.router.navigateByUrl(this.getRequestParams(this.platform.pathname));
    } else {
      if (this.getRequestParams(this.platform.pathname) !== '/') {
        this.router.navigateByUrl(this.getRequestParams(this.platform.pathname));
      } else {
        this.router.navigateByUrl(lang);
      }
    }
    this.reRenderSearchOrCategoryPage();
  }

  public reRenderSearchOrCategoryPage(): void {
    if (window.location.pathname.includes('category')) {
      this.store.dispatch(ClearFoundActionSuccess());
    } else if (window.location.pathname.includes('search')) {
      this.store.dispatch(ClearFoundActionSuccess());
    }
  }

  public getReturnParamsLang(urls: string[]): string[] {
    if (this.getLang() === 'ru') {
      return urls;
    } else {
      return ['ua', ...urls];
    }
  }

  public getRouterLinkApp(url: string): string {
    if (this.getLang() === 'ru') {
      return '/' + url;
    } else {
      return url !== '' ? '/ua/' + url : '/ua';
    }
  }


  public getRequestParams(url: string): string {
    if (url.includes('/ua')) {
      return url.replace('/ua', '') + this.platform.search;
    } else {
      if (url == '/') {
        return 'ua' + this.platform.search;
      } else {
        return 'ua' + url + this.platform.search;
      }
    }
  }

}
