<ng-content select="app-header"></ng-content>
<ng-content select="div.main-content"></ng-content>
<ng-content select="bz-footer"></ng-content>

<div (click)="deleteAllPopap($event)" [ngClass]="{'desktop-clc': (popapVisible | async)}">
  <ng-template #coreLazyComponent></ng-template>
</div>

<div class="classPopupGlobalApp" id="popupGlobalApp" (click)="close($event)">
</div>
