import { PlatformLocation } from '@angular/common';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { LocalizationService } from '../../localization.service';
import { ScreenDetectedService } from '../../screen-detected.service';

@Injectable({
  providedIn: 'root'
})
export class PharmaciesNavigationHelperService {

  public readonly navigateEmmiter: Subject<void> = new Subject();

  constructor(
    private readonly screenDetectedService: ScreenDetectedService,
    private readonly localizationService: LocalizationService,
    private readonly platform: PlatformLocation
  ) { }

  public emittNavigation() {
    if (this.screenDetectedService.isDesktop()) {
      this.navigateEmmiter.next(null);
    }
  }

  public getProductIdFromLocationPathname(): string {
    const lang: string = this.localizationService.getLang();
    const currentPathName: string = this.platform.pathname.slice(1);
    const splitedPathName: string[] = currentPathName.split('/');
    const productId: string = lang === 'ru' ? splitedPathName[1].split('-')[0] : splitedPathName[2].split('-')[0];
    return productId;
  }
}
