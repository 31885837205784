import { Component, OnInit, } from '@angular/core';
import { AppState } from '@appStates';
import { WorkingPanelMenu } from '@interfaces';
import { select, Store } from '@ngrx/store';
import { selectListoptionsMobile } from '../../../store/selectors';
import { Observable } from 'rxjs';

@Component({
  selector: 'bz-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.sass']
})
export class FooterComponent implements OnInit {
  public footerMenu: Observable<WorkingPanelMenu[]>;

  constructor(
    private readonly store: Store<AppState>
  ) { }

  ngOnInit(): void {
    this.footerMenu = this.store.pipe(select(selectListoptionsMobile));
  }

}
