import { Injectable } from '@angular/core';
import { Browsers } from 'src/app/shared/enums/browsers.enum';

@Injectable({
  providedIn: 'root'
})
export class DetectedBrowserService {

  constructor() { }

  static whoHere(is: Browsers): boolean {

        const isOpera = (!!window['opr'] && !!window['opr']['addons']) || !!window['opera'] || navigator.userAgent.indexOf(' OPR/') >= 0;

        // Firefox 1.0+
        const isFirefox = typeof ['InstallTrigger'] !== 'undefined';

        // Safari 3.0+ "[object HTMLElementConstructor]"
        const isSafari = /constructor/i.test(window.HTMLElement.toString()) ||
          (function(p) {
            return p.toString() === '[object SafariRemoteNotification]';
          })(!window['safari'] || (typeof 'safari' !== 'undefined' && window['safari']['pushNotification']));

        // Internet Explorer 6-11
        const isIE = /*@cc_on!@*/ false || !!document['documentMode'];

        // Edge 20+
        const isEdge = !isIE && !!window?.['StyleMedia'];

        // Chrome 1 - 79
        const isChrome = !!window['chrome'] && (!!window['chrome']['webstore'] || !!window['chrome']['runtime']);

        // Edge (based on chromium) detection
        const isEdgeChromium = isChrome && navigator.userAgent.indexOf('Edg') != -1;

        // Blink engine detection
        const isBlink = (isChrome || isOpera) && !!window.CSS;

        let result: boolean = false;

        switch (is) {
          case Browsers.chrome:
            result = isChrome;
            break;
          case Browsers.safari:
            result = isSafari;
            break;
          case Browsers.edge:
            result = isEdge || isEdgeChromium;
            break;
          case Browsers.firefox:
            result = isFirefox;
            break;
          case Browsers.opera:
            result = isOpera;
            break;
        }

        return result;

  }

}
