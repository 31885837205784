const { version: appVersion } = require('../../package.json');

export const environment = {
  name: 'stage',
  version: appVersion,
  build: 'stage',
  baseUrl: 'https://stage.zdorovi.ua',
  restServer: 'stage.zdorovi.ua',
  staticIMG: '//img.stage.zdorovi.ua/',
  seo: 'https://seo-rest.stage.zdorovi.ua',
  sentryTracePropagationTargets: ['stage.zdorovi.ua'],
  production: true,
  gtmIndex: 'GTM-NN6N3Z2',
  hotjar: {
    trackingCode: 3665334,
    version: 6
  },
  index: false,
  serverLogger: {
    enable: false,
    tableBody: false,
    stringBody: false,
  },
  nameApp: 'Zdorovi.ua'
};
