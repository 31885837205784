import { Injectable } from '@angular/core';
import { LocalStorageApiService } from './local-storage-api.service';
import { KeysLS, Languages } from '@enums';
import { MemoryStorageApiService } from './memory-storage-api.service';

@Injectable({
  providedIn: 'root'
})
export class LangStorageService extends LocalStorageApiService {
  public DEFAULT_VALUE = 'ua';
  public KEY = KeysLS.lang;

  constructor(
    private readonly memory: MemoryStorageApiService
  ) {
    super();
  }

  public get lang(): string {
    return this.getData(this.KEY) ?? this.DEFAULT_VALUE;
  }

  public set lang(value: Languages | string) {
    if (this.isSupported()) {
      this.setData(this.KEY, value);
    } else {
      this.memory.set(this.KEY, value);
    }
  }
}
