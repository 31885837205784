import { BzProduct, ICatalogTextWithEditor, ICurrentFilter, IPreparatPageResponse, IPreparatPharmacy, ITableCatalogProductsResponse, ITradeGoodsResponse, ListOptions, Options } from '@interfaces';
import { createAction, props } from '@ngrx/store';
import { IProduct, PermisionsProduct } from 'src/app/shared/interfaces/product.model';

export enum SearchActions {
  SetTarget = '[Search] SetTarget',
  GetOptions = '[Search] GetOptions',
  ClearOptions = '[Search] ClearOptions',
  GetFoundSuccess = '[Search] GetFoundSuccess',
  ClearFoundSuccess = '[Search] ClearFoundSuccess',
  ResetFilterBuffer = '[Search] ResetFilterBuffer',
  ActiveFilters = '[Search] ActiveFilters',
  GetCustomFound = '[Search] GetCustomFound',
  LoadFiltersPage = '[Search] LoadFiltersPage',
  ShowMoreLoadData = '[Search] Show More Load Data',
  ResetPageCountLoadData = '[Search] ResetPageCountLoadData',
  AddPageCountLoadData = '[Search] AddPageCountLoadData',
  AddInstructionForPopup = '[Search] AddInstructionForPopup',
  AddAnalogsForPopup = '[Search] AddAnalogsForPopup',
  AddPermissionForPopup = '[Search] AddPermissionForPopup',
  AddProductInList = '[Search] AddProductInList',
  DeleteProductsFromList = '[Search] DeleteProductsFromList',
  DeleteOneProductFromList = '[Search] DeleteOneProductFromList',
  AddProductIsFavorite = '[Search] AddProductIsFavorite',
  DeleteProductFromFavorite = '[Search] DeleteProductFromFavorite',
  SetCurrentFilters = '[Search] SetCurrentFilters',
  DeleteOneCurrentFilter = '[Search] DeleteOneCurrentFilter',
  SetH1ForSearchListPage = '[Search] Set Title For Search By List Page',
  SetSeoTextWithEditorForThirdLvlAction = '[Search] Set Seo Text For Third Level Catalog Page',
  SetSeoFaqForListPage = '[Search] Set Seo Faq For List Page',
  SetTableForListPage = '[Search] Set Seo Table With Prices For List Page',
  SetSeoAnalogsForListPage = '[Search] Set Seo Analogs For List Page',
  ResetPageState = '[Search] Reset Page State',
  SetLoadingPageState = '[Search] Set Loading Page State',
  SetPreparatSeoData = '[Search] Set Preparat Seo Data',
  TogglePreparatPharmacyProducts = '[Search] Toggle Preparat Pharmacy Products'
}

export const SetTargetSuccess = createAction(
  SearchActions.SetTarget,
  props<{
    target: string,
    life?: 'option' | '',
    option?: ListOptions,
    p?: boolean,
    anyParams?: any
  }>()
);

export const GetOptionsSuccess = createAction(
  SearchActions.GetOptions,
  props<{ options: Options[] }>()
);

export const SetH1ForSearchListPageAction = createAction(
  SearchActions.SetH1ForSearchListPage,
  props<{ title: string }>()
);

export const SetSeoTextWithEditorForThirdLvlAction = createAction(
  SearchActions.SetSeoTextWithEditorForThirdLvlAction,
  props<{ textWithEditor: ICatalogTextWithEditor | null }>()
);

export const SetFaqForListPageAction = createAction(
  SearchActions.SetSeoFaqForListPage,
  props<{ faq: ITradeGoodsResponse | null }>()
);

export const SetTableForListPageAction = createAction(
  SearchActions.SetTableForListPage,
  props<{ table: ITableCatalogProductsResponse | null }>()
);

export const SetSeoAnalogsForListPageAction = createAction(
  SearchActions.SetSeoAnalogsForListPage,
  props<{ analogs: BzProduct[] | null }>()
);

export const ClearOptionsSuccess = createAction(
  SearchActions.ClearOptions
);

export const GetFoundSuccess = createAction(
  SearchActions.GetFoundSuccess,
  props<{ found: any,  showMore?: boolean}>()
);

export const ClearFoundActionSuccess = createAction(
  SearchActions.ClearFoundSuccess
);

export const ActiveFiltersSuccess = createAction(
  SearchActions.ActiveFilters,
  props<{ count: number }>()
);

export const ResetFilterBufferAction = createAction(
  SearchActions.ResetFilterBuffer,
  props<{ status: boolean }>()
);

export const AddPageCountLoadDataAction = createAction(
  SearchActions.AddPageCountLoadData,
  props<{ pages?: [number, number] }>()
);

export const ResetPageCountLoadDataAction = createAction(
  SearchActions.ResetPageCountLoadData
);

export const ShowMoreLoadDataAction = createAction(
  SearchActions.ShowMoreLoadData,
  props<{ load: boolean }>()
);

export const AddInstructionForPopupAction = createAction(
  SearchActions.AddInstructionForPopup,
  props<{ instruction: string }>()
);

export const AddAnalogsForPopupAction = createAction(
  SearchActions.AddAnalogsForPopup,
  props<{ analogs: { data: BzProduct[]; product: IProduct } }>()
);

export const AddPermissionForPopupAction = createAction(
  SearchActions.AddPermissionForPopup,
  props<{ permission: PermisionsProduct[] }>()
);

export const LoadFiltersPageSuccess = createAction(
  SearchActions.LoadFiltersPage,
  props<{ filters: any }>()
);

export const AddProductInListAction = createAction(
  SearchActions.AddProductInList,
  props<{ product: number }>()
);

export const DeleteProductsFromListAction = createAction(
  SearchActions.DeleteProductsFromList
);

export const DeleteOneProductFromListAction = createAction(
  SearchActions.DeleteOneProductFromList,
  props<{ product: number }>()
);

export const AddProductInFavoriteAction = createAction(
  SearchActions.AddProductIsFavorite,
  props<{ id: number }>()
);

export const DeleteProductFromFavoriteAction = createAction(
  SearchActions.DeleteProductFromFavorite,
  props<{ product: number }>()
);

export const SetCurrentFiltersAction = createAction(
  SearchActions.SetCurrentFilters,
  props<{ current: ICurrentFilter[] }>()
);

export const DeleteOneCurrentFilterAction = createAction(
  SearchActions.DeleteOneCurrentFilter,
  props<{ id: string }>()
);

export const ResetPageStateAction = createAction(
  SearchActions.ResetPageState
);

export const SetPageLoadingAction = createAction(
  SearchActions.SetLoadingPageState,
  props<{ loading: boolean }>()
);

export const SetPreparatSeoDataAction = createAction(
  SearchActions.SetPreparatSeoData,
  props<{ seoData: IPreparatPageResponse | null }>()
);

export const TogglePreparatPharmacyProducts = createAction(
  SearchActions.TogglePreparatPharmacyProducts,
  props<{ pharmacy: IPreparatPharmacy }>()
);
