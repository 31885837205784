import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared';
import { TranslocoModule } from '@ngneat/transloco';
import { RouterModule } from '@angular/router';
import { BzFaqComponent } from './bz-faq.component';



@NgModule({
    declarations: [BzFaqComponent],
    imports: [
        CommonModule,
        SharedModule,
        TranslocoModule,
        RouterModule
    ],
    exports: [BzFaqComponent]
})
export class BzFAQModule { }
