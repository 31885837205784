import { createAction, props } from '@ngrx/store';
import {Contact} from '../state/contacts.page';

export enum ContactsActions {
  UpdateContacts = '[ContactsPage] UpdateContacts'
}

export const UpdateContactsAction = createAction(
  ContactsActions.UpdateContacts,
  props<{ contacts: Contact[] }>()
);
