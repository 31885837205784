import { SetCurrentBlockMobilePageSuccess, SetVisiableBooleanAction } from '../../../store/actions';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { AppState } from '@appStates';
import { ScreenDetectedService } from '@core/services';
import { ListLazyComponents, TypeDevice } from '@enums';
import { select, Store } from '@ngrx/store';
import { selectStateCurrentBlockMobilePage, selectVisibleBoolean } from '../../../store/selectors';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LazyLoadComponentsService } from 'src/app/lazy-load-components-ui/factories/lazy-load-components.service';
import { GlobalPopupService } from 'src/app/lazy-load-components-ui/factories/global-popup.service';

@Component({
  selector: 'bz-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.sass']
})
export class LayoutComponent implements OnInit, AfterViewInit, OnDestroy {
  public popapVisible: Observable<boolean>;

  public TypeDevice = TypeDevice;
  private componentDestroyer = new Subject();

  @ViewChild('coreLazyComponent', { read: ViewContainerRef }) popap: ViewContainerRef;

  constructor(
    public readonly screen: ScreenDetectedService,
    private readonly gps: GlobalPopupService,
    private readonly lazy: LazyLoadComponentsService,
    private readonly store: Store<AppState>
  ) { }

  ngOnInit(): void {
    this.setVisibleBoolean();
  }

  public setVisibleBoolean(): void {
    this.popapVisible = this.store.pipe(select(selectVisibleBoolean));
  }

  public ngAfterViewInit(): void {
    this.getDesktopMenu();
  }

  public getDesktopMenu(): void {
    if (this.screen.geTypeDevice() === TypeDevice.desktop) {
      this.store.pipe(
        select(selectStateCurrentBlockMobilePage),
        takeUntil(this.componentDestroyer)
      ).subscribe((type: ListLazyComponents) => {
        if (type) {
          this.store.dispatch(SetVisiableBooleanAction({ visible: true }));
          this.lazy.addCoreLazyComponent(this.popap, type);
        } else {
          this.store.dispatch(SetVisiableBooleanAction({ visible: false }));
        }
      });
    }
  }

  public deleteAllPopap(event: PointerEvent): void {
    event.stopPropagation();
    if ((event.target as Element).className === 'desktop-clc') {
      this.store.dispatch(SetCurrentBlockMobilePageSuccess({ block: null }));
      this.lazy.clearContainer(this.popap);
    }
  }

  public close(event: PointerEvent): void {
    const component: Element =
      document.getElementsByTagName('bz-short-instruction')[0] ||
      document.getElementsByTagName('bz-long-instruction')[0] ||
      document.getElementsByTagName('bz-analogs')[0] ||
      document.getElementsByTagName('bz-permission-product')[0];
    if (component === event.target) {
      this.gps.delPopup(event);
    }
  }

  ngOnDestroy(): void {
    this.componentDestroyer.next(null);
    this.componentDestroyer.complete();
  }

}
