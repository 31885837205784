<div class="wrapper" [ngStyle]="{'width': width}">
    <ng-container *ngFor="let item of menu | async">

                <bz-button class="btn-nav"
                           [ngClass]="{'location-text-width': item.description === 'location'}"
                    *ngIf="item.description !== 'translate'; else trl"
                    [content]="item.buttonSetting.content"
                    [defaultIconColor]="colorsApp.icon"
                    [isCounter]="(item.description === 'list' || item.description === 'cart')"
                    [counterValue]="
                        (item.description == 'list' && (listCount | async) >= 0) ? (listCount | async) :
                        (item.description == 'cart' && (cartCount) >= 0) ? cartCount : 0 "
                    [text]="((listSum | async) > 0 && item.description == 'list' || (cartSum) > 0 && item.description == 'cart') ?
                     (item.description == 'list' ? ((listSum | async) | number : '1.2-2') + ' ₴' : ((cartSum) | number: '1.2-2') + ' ₴')
                     : (item.description === 'location' ? city : (item.buttonSetting.text  | transloco))"
                    [size]="(item.buttonSetting.size?.w && item.buttonSetting.size?.h) ? item.buttonSetting.size : {w: null, h: null}"
                    [textColor]="((listSum | async) > 0 && item.description == 'list' || (cartSum) > 0 && item.description == 'cart') ? colors.primary : item.buttonSetting.textColor"
                    [icon]="item.buttonSetting.icon"
                    (appClick)="clickElMenu(item)"
                ></bz-button>

                <ng-template #trl>

                    <bz-btn-lang
                        [lang]="lang | async"
                        [animationBtnLang]="show"
                    ></bz-btn-lang>

                </ng-template>

    </ng-container>
</div>
