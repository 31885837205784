import { createAction, props } from '@ngrx/store';
import { BzProduct } from '@interfaces';

export enum ViewedActions {
  LoadViewedProducts = '[Viewed] LoadViewedProducts',
  AddProductToListFromViewed = '[Viewed] AddProductToList',
  AddProductToFavoriteFromViewed = '[Viewed] AddProductToFavorite',
  UnmarkListIconsFromListPage = '[Viewed] UnmarkListIconsFromListPage',
  UnmarkListIconFromListPage = '[Viewed] UnmarkListiconFromListPage'
}

export const LoadViewedProductsAction = createAction(
  ViewedActions.LoadViewedProducts,
  props<{ products: BzProduct[] }>()
);

export const AddProductToListFromViewedAction = createAction(
  ViewedActions.AddProductToListFromViewed,
  props<{ id: number }>()
);

export const AddProductToFavoriteFromViewedAction = createAction(
  ViewedActions.AddProductToFavoriteFromViewed,
  props<{ id: number }>()
);

export const UnmarkListIconsFromListPageAction = createAction(
  ViewedActions.UnmarkListIconsFromListPage
);

export const UnmarkListIconFromListPageAction = createAction(
  ViewedActions.UnmarkListIconFromListPage,
  props<{ id: number }>()
);