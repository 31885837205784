import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AppModule } from './app.module';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    AppModule,
    ReactiveFormsModule,
  ],
  bootstrap: [AppComponent]
})

export class AppBrowserModule {}
