import { BzProduct, IAdviceDay, IAllAdviceList, ISingleAdviceDay, ITopReserveSingleGroup, Slide } from '@interfaces';
import { createAction, props } from '@ngrx/store';

export enum StartPageActions {
  SetActiveSlideSlider = '[StartPage] Set SetActiveSlideSlider',
  AddFavoritesBlocksInSlider = '[StartPage] Set Add Favorites Blocks In Slider',
  AddViewedBlocksInSlider = '[StartPage] Add Viewed Blocks In Slider',
  SetCurrentAdviceDayId = '[StartPage] SetCurrentAdviceDayId',
  SetCurrentMostOftenGroup = '[StartPAge] SetCurrentMostOftenGroup',
  SetAdviceDayTipsList = '[StartPage] SetAdviceDayTipsList',
  SetMostOftenProducts = '[StartPage] SetMostOftenProductsMap',
  AddOftenProductToList = '[StartPage] AddOftenProductToList',
  AddOftenProductToFavorite = '[StartPage] AddOftenProductToFavorite',
  UnmarkOftenProductListIconFromListPage = '[StartPage] UnmarkOftenProductListIconFromListPage',
  UnmarkAllOftenProductListIconFromListPage = '[StartPage] UnmarkAllOftenProductListIconFromListPage',
  UnmarkFavoriteProductFromPrivatePageToHomePage = '[StartPage] UnmarkFavoriteProductFromPrivatePageToHomePage',
  MarkAsFavoriteFromPrivateViewedPageToHomeTopReserve = '[StartPage] MarkAsFavoriteFromPrivateViewedPageToHomeTopReserve',
  SetCurrentSingleAdviceDay = '[StartPage] SetCurrentSingleAdviceDay',
  SetOftenKeys = '[StartPage] SetCurrentOftenCategory',
  SetOneOftenProductsGroup = '[StartPage] SetOneOftenProductsGroup',
  SetAllAdviceTipsWithPageNum = '[StartPage] SetAllAdviceTipsWithPageNum',
  SetCurrentAdvicesPage = '[StartPage] SetCurrentAdvicesPage',
  AddOrDeleteProductToList = '[StartPage] AddOrDeleteProductToList',
  UnmarkInListProductFromListPage = '[StartPage] UnmarkInListProductFromListPage',
  UnmarkAllInListProductsFromListPage = '[StartPage] UnmarkAllInListProductsFromListPage',
  AddOrDeleteProductToFavorite = '[StartPage] AddOrDeleteProductToFavorite',
  SetAdviceStateWhenClickedFromAllAdvicePage = '[StartPage] SetAdviceStateWhenClickedFromAllAdvicePage',
  ToggleFavoriteOftenProductFromViewProducts = '[StartPage] ToggleFavoriteOftenProductFromViewProducts'
}

export const AddFavoritesBlocksInSliderAction = createAction(
  StartPageActions.AddFavoritesBlocksInSlider,
  props<{favorites: BzProduct[]}>()
);

export const AddViewedBlocksInSliderAction = createAction(
  StartPageActions.AddViewedBlocksInSlider,
  props<{vieweds: BzProduct[]}>()
);

export const SetActiveSlideSliderAction = createAction(
  StartPageActions.SetActiveSlideSlider,
  props<{bid: number, id: number}>()
);

export const SetCurrentAdviceDayIdAction = createAction(
  StartPageActions.SetCurrentAdviceDayId,
  props<{ id: string }>()
);

export const SetAdviceStateWhenClickedFromAllAdvicePageAction = createAction(
  StartPageActions.SetAdviceStateWhenClickedFromAllAdvicePage,
  props<{ state: boolean}>()
);

export const SetCurrentMostOftenGroupAction = createAction(
  StartPageActions.SetCurrentMostOftenGroup,
  props<{ id: string }>()
);

export const SetAdviceDayTipsListAction = createAction(
  StartPageActions.SetAdviceDayTipsList,
  props<{ list: IAdviceDay[] }>()
);

export const SetMostOftenProductsAction = createAction(
  StartPageActions.SetMostOftenProducts,
  props<{ data: BzProduct[] }>()
);

export const AddOftenProductToListAction = createAction(
  StartPageActions.AddOftenProductToList,
  props<{ product: BzProduct }>()
);

export const AddOftenProductToFavoriteAction = createAction(
  StartPageActions.AddOftenProductToFavorite,
  props<{ product: BzProduct }>()
);

export const UnmarkOftenProductListIconFromListPageAction = createAction(
  StartPageActions.UnmarkOftenProductListIconFromListPage,
  props<{ product: BzProduct }>()
);

export const UnmarkAllOftenProductListIconFromListPageAction = createAction(
  StartPageActions.UnmarkAllOftenProductListIconFromListPage
);

export const UnmarkFavoriteProductFromPrivatePageToHomePageAction = createAction(
  StartPageActions.UnmarkFavoriteProductFromPrivatePageToHomePage,
  props<{ product: BzProduct }>()
);

export const MarkAsFavoriteFromPrivateViewedPageToHomeTopReserveAction = createAction(
  StartPageActions.MarkAsFavoriteFromPrivateViewedPageToHomeTopReserve,
  props<{product: BzProduct }>()
);

export const SetCurrentSingleAdviceDayAction = createAction(
  StartPageActions.SetCurrentSingleAdviceDay,
  props<{advice: ISingleAdviceDay}>()
);

export const SetOftenKeysAction = createAction(
  StartPageActions.SetOftenKeys,
  props<{ keys: string[] }>()
);

export const SetOneOftenProductsGroupAction = createAction(
  StartPageActions.SetOneOftenProductsGroup,
  props<{group: ITopReserveSingleGroup}>()
);

export const SetAllAdviceTipsWithPageNumAction = createAction(
  StartPageActions.SetAllAdviceTipsWithPageNum,
  props<{data: IAllAdviceList}>()
);

export const SetCurrentAdvicesPageAction = createAction(
  StartPageActions.SetCurrentAdvicesPage,
  props<{page: number}>()
);

export const AddOrDeleteProductToList = createAction(
  StartPageActions.AddOrDeleteProductToList,
  props<{id: number}>()
);

export const UnmarkInListProductFromListPageAction = createAction(
  StartPageActions.UnmarkInListProductFromListPage,
  props<{id: number}>()
);

export const UnmarkAllInListProductsFromListPageAction = createAction(
  StartPageActions.UnmarkAllInListProductsFromListPage
);

export const AddOrDeleteProductToFavoriteAction = createAction(
  StartPageActions.AddOrDeleteProductToFavorite,
  props<{id: number}>()
);

export const ToggleFavoriteOftenProductFromViewProducts = createAction(
  StartPageActions.ToggleFavoriteOftenProductFromViewProducts,
  props<{ product: BzProduct }>()
);
