export enum Pages {
    start,
    search,
    category,
    info,
    pharmacies,
    mobile,
    global,
    services,
    tips,
    often,
    sitemap,
    links
}
