import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { ICity, IProduct } from '@interfaces';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { InfoDrugApiService } from '../services/api.services/info-drug-api.service';
import { LocationService } from '@core/services';
import { isPlatformServer } from '@angular/common';
import { GeoLocationService } from '../services/positioning.services/geo-location.service';

@Injectable({
  providedIn: 'root'
})
export class PharmaciesGuard  {

  constructor(
    private infoDrugApiService: InfoDrugApiService,
    private location: LocationService,
    private geo: GeoLocationService,
    @Inject(PLATFORM_ID) private readonly platformId: Object
  ) { }

  resolve(
    route: ActivatedRouteSnapshot
  ): Observable<{
    city: ICity,
    product: IProduct,
    permissionStatus: PermissionStatus | null,
    isGeolocationDisabled: boolean,
    isList: boolean
  }> {
    const productId: string = route.params.url.split('-')[0];
    const townFromUrl: string = route.params.town;

    return forkJoin([
      this.infoDrugApiService.getProductById(+productId, townFromUrl),
      townFromUrl ? this.location.getTownByName(townFromUrl) : of({} as ICity),
      isPlatformServer(this.platformId) ? of(null) : this.geo.getPermissionStatus(),
    ]).pipe(
      map(([product, city, permissionStatus]: [IProduct, ICity, PermissionStatus | null]) => {
        const result: {
          city: ICity,
          product: IProduct,
          permissionStatus: PermissionStatus,
          isGeolocationDisabled: boolean,
          isList: boolean
        } = {
          city: city,
          product: product,
          permissionStatus,
          isGeolocationDisabled: !permissionStatus || permissionStatus.state !== 'granted',
          isList: false
        };
        return result;
      })
    );
  }

}
