<ng-container *ngIf="isRus; then rus; else ua">
</ng-container>
<ng-template #rus>
  <nav class="catalog-columns-wrapper">

    <div class="catalog-column left-section">
      <div class="first-level-catalog" #menuRight>

        <div #mLiki class="first-level-tab" (mouseenter)="menu.next([liki, mLiki, 'mouseenter'])"
          (mouseleave)="menu.next([liki, mLiki, 'mouseleave'])">
          <img class="fisrt-level-tab-image" src="assets/catalog-icons/liki.svg" alt="Лекарства" title="Лекарства">
          <p class="first-level-tab-title">
            <a [routerLink]="'/catalog/liki'" (click)="clearSearchState()">Лекарства</a>
          </p>
        </div>

        <div #mMedDev class="first-level-tab" (mouseenter)="menu.next([medDev, mMedDev, 'mouseenter'])"
          (mouseleave)="menu.next([medDev, mMedDev, 'mouseleave'])">
          <img class="fisrt-level-tab-image" src="assets/catalog-icons/med_tech.svg"
            alt="Изделия медицинского назначения и ортопедия" title="Изделия медицинского назначения и ортопедия">
          <p class="first-level-tab-title">
            <a [routerLink]="'/catalog/virobi-medichnogo-priznachennya-ta-ortopediya'"
              (click)="clearSearchState()">Изделия медицинского назначения и ортопедия</a>
          </p>
        </div>

        <div #mhealthOther class="first-level-tab" (mouseenter)="menu.next([healthOther, mhealthOther, 'mouseenter'])"
          (mouseleave)="menu.next([healthOther, mhealthOther, 'mouseleave'])">
          <img class="fisrt-level-tab-image" src="assets/catalog-icons/health_other.svg"
            alt="Лечебно-профилактические средства" title="Лечебно-профилактические средства">
          <p class="first-level-tab-title">
            <a [routerLink]="'/catalog/likuvalno-profilaktichni-zasobi'"
              (click)="clearSearchState()">Лечебно-профилактические средства</a>
          </p>
        </div>

        <div #mBeauty class="first-level-tab" (mouseenter)="menu.next([beauty, mBeauty, 'mouseenter'])"
          (mouseleave)="menu.next([beauty, mBeauty, 'mouseleave'])">
          <img class="fisrt-level-tab-image" src="assets/catalog-icons/beauty.svg" alt="Красота и уход"
            title="Красота и уход">
          <p class="first-level-tab-title">
            <a [routerLink]="'/catalog/krasa-ta-doglyad'" (click)="clearSearchState()">Красота и уход</a>
          </p>
        </div>

        <div #mChildren class="first-level-tab" (mouseenter)="menu.next([children, mChildren, 'mouseenter'])"
          (mouseleave)="menu.next([children, mChildren, 'mouseleave'])">
          <img class="fisrt-level-tab-image" src="assets/catalog-icons/children.svg" alt="Товары для детей и мам"
            title="Товары для детей и мам">
          <p class="first-level-tab-title">
            <a [routerLink]="'/catalog/tovari-dlya-ditey-ta-mam'" (click)="clearSearchState()">Товары для детей и
              мам</a>
          </p>
        </div>

        <div #mMedTools class="first-level-tab" (mouseenter)="menu.next([medTools, mMedTools, 'mouseenter'])"
          (mouseleave)="menu.next([medTools, mMedTools, 'mouseleave'])">
          <img class="fisrt-level-tab-image" src="assets/catalog-icons/med_tools.svg" alt="Медицинская техника"
            title="Медицинская техника">
          <p class="first-level-tab-title">
            <a [routerLink]="'/catalog/medichna-tehnika'" (click)="clearSearchState()">Медицинская техника</a>
          </p>
        </div>

        <div #mVitamin class="first-level-tab" (mouseenter)="menu.next([vitamin, mVitamin, 'mouseenter'])"
          (mouseleave)="menu.next([vitamin, mVitamin, 'mouseleave'])">
          <img class="fisrt-level-tab-image" src="assets/catalog-icons/vitamin.svg"
            alt="Витамины и минеральные диетические добавки" title="Витамины и минеральные диетические добавки">
          <p class="first-level-tab-title">
            <a [routerLink]="'/catalog/vitamini-ta-mineralni-dietichni-dobavki'" (click)="clearSearchState()">Витамины и
              минеральные диетические добавки</a>
          </p>
        </div>

      </div>
    </div>
    <div class="catalog-column right-section">

      <div #liki style="z-index: 2" class="second-level-catalog" (mouseenter)="block(liki, mLiki)">
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/catalog/vitamini'" (click)="clearSearchState()">Витамины</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/vitamin-c'" (click)="clearSearchState()">Витамин C</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/preparati-scho-mistyat-vitamin-a-e-i-d'" (click)="clearSearchState()">Витамины
              A, E и D</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/vitamini-dlya-vagitnih-ta-goduyuchih'" (click)="clearSearchState()">Беременным и
              кормящим</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/vitamini-grupi-b'" (click)="clearSearchState()">Витамины группы B</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/vitamini-dlya-ochey'" (click)="clearSearchState()">Для глаз</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/polivitamini'" (click)="clearSearchState()">Поливитамины</a>
          </span>
          <span class="inner-text all-category" style="font-weight: normal">
            <a [routerLink]="'/catalog/vitamini'" (click)="clearSearchState()">Все категории</a>
          </span>
        </div>
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/catalog/nevrologichni-preparati'" (click)="clearSearchState()">Неврологические</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/antidepresanti'" (click)="clearSearchState()">Антидепрессанты</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/neyroleptiki'" (click)="clearSearchState()">Нейролептики</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/snodiyni-preparati'" (click)="clearSearchState()">Снотворные</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/trankvilizatori'" (click)="clearSearchState()">Транквилизаторы</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/preparati-pri-porushenni-snu-zaspokiylivi-sedativni'"
              (click)="clearSearchState()">При нарушении сна, успокаивающие, седативные</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/nootropni-preparati'" (click)="clearSearchState()">Ноотропные</a>
          </span>
          <span class="inner-text all-category" style="font-weight: normal">
            <a [routerLink]="'/catalog/nevrologichni-preparati'" (click)="clearSearchState()">Все категории</a>
          </span>
        </div>
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/catalog/protizastudni-preparati-grip-grz'" (click)="clearSearchState()">Противопростудные
              (грипп, ОРЗ)</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/protivirusni-preparati'" (click)="clearSearchState()">Противовирусные</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/zigrivayuchi-mazi'" (click)="clearSearchState()">Согревающие мази</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/preparati-vid-kashlyu'" (click)="clearSearchState()">От кашля</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/zharoznizhuyuchi-preparati'" (click)="clearSearchState()">Жаропонижающие</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/kompleksni-preparati-vid-zastudi'" (click)="clearSearchState()">Комплексные
              препараты от простуды</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/preparati-vid-bolyu-v-gorli'" (click)="clearSearchState()">От боли в горле</a>
          </span>
          <span class="inner-text all-category" style="font-weight: normal">
            <a [routerLink]="'/catalog/protizastudni-preparati-grip-grz'" (click)="clearSearchState()">Все категории</a>
          </span>
        </div>
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/catalog/imunopreparati'" (click)="clearSearchState()">Иммунопрепараты</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/vakcini-i-sirovatki'" (click)="clearSearchState()">Вакцины и сыворотки</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/bakteriofagi-piobakteriofagi'" (click)="clearSearchState()">Бактериофаги</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/imunoglobulini'" (click)="clearSearchState()">Иммуноглобулины</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/imunostimulyatori'" (click)="clearSearchState()">Иммуностимуляторы</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/imunodepresanti'" (click)="clearSearchState()">Иммунодепрессанты</a>
          </span>
        </div>
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/catalog/preparati-vid-gemoroyu'" (click)="clearSearchState()">От геморроя</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/svichki-vid-gemoroyu'" (click)="clearSearchState()">Свечи от геморроя</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/mazi-vid-gemoroyu'" (click)="clearSearchState()">Мази от геморроя</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/vnutrishni-preparati-dlya-likuvannya-gemoroyu'"
              (click)="clearSearchState()">Внутренние от геморроя</a>
          </span>
        </div>
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/catalog/preparati-v-akusherstvi-ta-ginekologii'"
              (click)="clearSearchState()">Гинекологические</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/antiseptiki-ta-protizapalni-preparati'"
              (click)="clearSearchState()">Противовоспалительные</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/gormonalni-preparati'" (click)="clearSearchState()">Гормональные</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/kontraceptivi'" (click)="clearSearchState()">Контрацептивы</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/preparati-vid-molochnici'" (click)="clearSearchState()">От молочницы</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/preparati-pri-klimaksi'" (click)="clearSearchState()">При климаксе</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/preparati-pri-mastopatii-ta-porushenni-ciklu'" (click)="clearSearchState()">При
              мастопатии и нарушении цикла</a>
          </span>
          <span class="inner-text all-category" style="font-weight: normal">
            <a [routerLink]="'/catalog/preparati-v-akusherstvi-ta-ginekologii'" (click)="clearSearchState()">Все
              категории</a>
          </span>
        </div>
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/catalog/preparati-dlya-travnoi-sistemi'" (click)="clearSearchState()">Пищеварительная
              система</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/preparati-dlya-vidnovlennya-funkcii-pechinki-zhovchoginni-gepatoprotektori'"
              (click)="clearSearchState()">Гепатопротекторы</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/sorbenti-ta-antidoti'" (click)="clearSearchState()">Сорбенты</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/pronosni-preparati'" (click)="clearSearchState()">Слабительные</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/preparati-vid-nudoti-dispepsii'" (click)="clearSearchState()">От тошноты,
              диспепсии</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/preparati-pri-gastriti-ta-virazci'" (click)="clearSearchState()">При гастрите и
              язве</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/preparati-dlya-vidnovlennya-mikroflori-kishechnika-shlunku'"
              (click)="clearSearchState()">Восстановление микрофлоры</a>
          </span>
          <span class="inner-text all-category" style="font-weight: normal">
            <a [routerLink]="'/catalog/preparati-dlya-travnoi-sistemi'" (click)="clearSearchState()">Все категории</a>
          </span>
        </div>
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/catalog/protialergeni-preparati'" (click)="clearSearchState()">Противоаллергические</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/tabletki-kapsuli-vid-alergii'" (click)="clearSearchState()">Таблетки от
              аллергии</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/maz-krem-gel-vid-alergii'" (click)="clearSearchState()">Мази от аллергии</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/preparati-vid-alergichnogo-nezhityu'" (click)="clearSearchState()">От
              аллергического насморка</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/enterosorbenti-pri-alergii'" (click)="clearSearchState()">Энтеросорбенты при
              аллергии</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/inshi-protialergeni-preparati'" (click)="clearSearchState()">Прочие
              противоаллергические препараты</a>
          </span>
        </div>
        <div class="link-to-all-category">
          <a [routerLink]="'/catalog/liki'" (click)="clearSearchState()">Все категории Лекарства</a>
        </div>
      </div>
      <div #medDev class="second-level-catalog" (mouseenter)="block(medDev, mMedDev)">
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/catalog/testi-dlya-zhinok'" (click)="clearSearchState()">Тесты для женщин</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/testi-na-vagitnist'" (click)="clearSearchState()">На беременность</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/testi-na-ovulyaciyu'" (click)="clearSearchState()">На овуляцию</a>
          </span>
        </div>
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/catalog/masazheri-dlya-tila'" (click)="clearSearchState()">Массажеры для тела</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/aplikatori-kuznecova'" (click)="clearSearchState()">Аппликаторы Кузнецова</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/aplikatori-lyapko'" (click)="clearSearchState()">Аппликаторы Ляпко</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/banki-vakuumni'" (click)="clearSearchState()">Банки вакуумные</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/masazhni-podushki'" (click)="clearSearchState()">Массажные подушки</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/espanderi'" (click)="clearSearchState()">Эспандеры</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/masazhni-kilimki'" (click)="clearSearchState()">Массажные коврики</a>
          </span>
          <span class="inner-text all-category" style="font-weight: normal">
            <a [routerLink]="'/catalog/masazheri-dlya-tila'" (click)="clearSearchState()">Все категории</a>
          </span>
        </div>
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/catalog/vitratni-materiali-dlya-terapii-ta-diagnostiki'"
              (click)="clearSearchState()">Расходные материалы для терапии и диагностики</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/kateteri-ta-kanyuli'" (click)="clearSearchState()">Катетеры и канюли</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/shprici'" (click)="clearSearchState()">Шприцы</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/aptechki'" (click)="clearSearchState()">Аптечки</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/zondi'" (click)="clearSearchState()">Зонды</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/instrumenti-i-shovniy-material'" (click)="clearSearchState()">Инструменты и
              шовный материал</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/holodoagenti'" (click)="clearSearchState()">Хладагенты</a>
          </span>
          <span class="inner-text all-category" style="font-weight: normal">
            <a [routerLink]="'/catalog/vitratni-materiali-dlya-terapii-ta-diagnostiki'" (click)="clearSearchState()">Все
              категории</a>
          </span>
        </div>
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/catalog/kontracepciya-ta-lubrikanti'" (click)="clearSearchState()">Контрацепция и
              лубриканты</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/prezervativi'" (click)="clearSearchState()">Презервативы</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/spirali'" (click)="clearSearchState()">Спирали</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/intimni-gel-zmazki'" (click)="clearSearchState()">Интимные гель-смазки</a>
          </span>
        </div>
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/catalog/bandazhi'" (click)="clearSearchState()">Бандажи</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/teypi-plastiri-kineziologichni'" (click)="clearSearchState()">Тейпы, пластыри
              кинезиологические</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/do-i-pislyapologovi-pislyaoperaciyni-bandazhi'" (click)="clearSearchState()">До
              и послеродовые, послеоперационные бандажи</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/korektori-postavi'" (click)="clearSearchState()">Корректоры осанки</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/bandazhi-dlya-ruk-ta-plechey'" (click)="clearSearchState()">Бандажи для рук и
              плечей</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/shiyni-komirci-i-bandazhi-dlya-golovi'" (click)="clearSearchState()">Шейные
              воротнички и бандажи для головы</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/poperekovo-krizhovi-ta-grizhovi-bandazhi'"
              (click)="clearSearchState()">Пояснично-крестцовые и грыжевые бандажи</a>
          </span>
          <span class="inner-text all-category" style="font-weight: normal">
            <a [routerLink]="'/catalog/bandazhi'" (click)="clearSearchState()">Все категории</a>
          </span>
        </div>
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/catalog/doglyad-za-hvorimi'" (click)="clearSearchState()">Уход за больными</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/pidguzki-dlya-doroslih'" (click)="clearSearchState()">Подгузники для
              взрослых</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/pelyushki-i-prokladki-urologichni'" (click)="clearSearchState()">Пеленки и
              прокладки урологические</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/grilki'" (click)="clearSearchState()">Грелки</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/sprincivki'" (click)="clearSearchState()">Спринцовки</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/pipetki'" (click)="clearSearchState()">Пипетки</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/kalopriymachi'" (click)="clearSearchState()">Калоприемники</a>
          </span>
          <span class="inner-text all-category" style="font-weight: normal">
            <a [routerLink]="'/catalog/doglyad-za-hvorimi'" (click)="clearSearchState()">Все категории</a>
          </span>
        </div>
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/catalog/medichniy-odyag-ta-zasobi-zahistu'" (click)="clearSearchState()">Медицинская
              одежда и средства защиты</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/maski-medichni-bahili-shapochki'" (click)="clearSearchState()">Маски
              медицинские, бахилы, шапочки</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/berushi'" (click)="clearSearchState()">Беруши</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/rukavichki-medichni'" (click)="clearSearchState()">Перчатки медицинские</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/komplekti-dlya-oglyadiv-i-operaciy'" (click)="clearSearchState()">Комплекты для
              осмотров и операций</a>
          </span>
        </div>
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/catalog/perevyazuvalni-i-fiksuyuchi-materiali'" (click)="clearSearchState()">Перевязочные
              и фиксирующие материалы</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/vata'" (click)="clearSearchState()">Вата</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/plastiri'" (click)="clearSearchState()">Пластыри</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/marlya-ta-servetki'" (click)="clearSearchState()">Марля и салфетки</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/elastichni-binti'" (click)="clearSearchState()">Эластичные бинты</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/dzhguti'" (click)="clearSearchState()">Жгуты</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/gipsovi-binti-ta-pidkladki'" (click)="clearSearchState()">Гипсовые бинты и
              подложки</a>
          </span>
          <span class="inner-text all-category" style="font-weight: normal">
            <a [routerLink]="'/catalog/perevyazuvalni-i-fiksuyuchi-materiali'" (click)="clearSearchState()">Все
              категории</a>
          </span>
        </div>
        <div class="link-to-all-category">
          <a [routerLink]="'/catalog/virobi-medichnogo-priznachennya-ta-ortopediya'" (click)="clearSearchState()">Все
            категории Изделия медицинского назначения и ортопедия</a>
        </div>
      </div>
      <div #healthOther class="second-level-catalog" (mouseenter)="block(healthOther, mhealthOther)">
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/catalog/travlennya-ta-shkt'" (click)="clearSearchState()">Пищеварение и ЖКТ</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/zakvaski'" (click)="clearSearchState()">Закваски</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/mineralna-voda'" (click)="clearSearchState()">Минеральная вода</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/detoksikanti'" (click)="clearSearchState()">Детоксиканты</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/zasobi-dlya-vidnovlennya-vodno-solovogo-balansu'"
              (click)="clearSearchState()">Восстановление водно-солевого баланса</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/specialne-harchuvannya'" (click)="clearSearchState()">Специальное питание</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/protiglisni-zasobi'" (click)="clearSearchState()">Противоглистные средства</a>
          </span>
          <span class="inner-text all-category" style="font-weight: normal">
            <a [routerLink]="'/catalog/travlennya-ta-shkt'" (click)="clearSearchState()">Все категории</a>
          </span>
        </div>
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/catalog/fito-i-aromaterapiya'" (click)="clearSearchState()">Фито и ароматерапия</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/efirni-masla'" (click)="clearSearchState()">Эфирные масла</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/fitochai-i-travi'" (click)="clearSearchState()">Фиточаи и травы</a>
          </span>
        </div>
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/catalog/zasobi-dlya-sercevo-sudinnoi-sistemi'"
              (click)="clearSearchState()">Сердечно-сосудистая система</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/antioksidanti'" (click)="clearSearchState()">Антиоксиданты</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/zasobi-dlya-normalizacii-rivnya-holesterinu'" (click)="clearSearchState()">Для
              нормализации уровня холестерина</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/zasobi-dlya-pidtrimki-sercevo-sudinnoi-sistemi'"
              (click)="clearSearchState()">Для поддержания сердечно-сосудистой системы</a>
          </span>
        </div>
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/catalog/zovnishni-zasobi'" (click)="clearSearchState()">Внешние средства</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/girchichniki-ta-znebolyuyuchi-plastiri'" (click)="clearSearchState()">Горчичники
              и обезболивающие пластыри</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/zasobi-v-ginekologii-ta-proktologii'" (click)="clearSearchState()">Гинекология и
              проктология</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/zasobi-dlya-oporno-ruhovoi-sistemi'"
              (click)="clearSearchState()">Опорно-двигательная система</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/dermatologichni-zasobi'" (click)="clearSearchState()">Дерматологические</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/dlya-profilaktiki-i-likuvannya-vuha'" (click)="clearSearchState()">Для
              профилактики и лечения уха</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/protipedikulozni-zasobi'" (click)="clearSearchState()">Противопедикулезные</a>
          </span>
          <span class="inner-text all-category" style="font-weight: normal">
            <a [routerLink]="'/catalog/zovnishni-zasobi'" (click)="clearSearchState()">Все категории</a>
          </span>
        </div>
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/catalog/zasobi-dlya-normalizacii-funkcii-zoru'" (click)="clearSearchState()">Для
              зрения</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/polipshennya-zoru'" (click)="clearSearchState()">Улучшение зрения</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/doglyad-za-linzami'" (click)="clearSearchState()">Уход за линзами</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/zvolozhennya-ta-komfort-ochey'" (click)="clearSearchState()">Увлажнение и
              комфорт глаз</a>
          </span>
        </div>
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/catalog/zasobi-dlya-obminu-rechovin'" (click)="clearSearchState()">Обмен веществ</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/zhinoche-zdorovya'" (click)="clearSearchState()">Женское здоровье</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/choloviche-zdorovya'" (click)="clearSearchState()">Мужское здоровье</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/shudnennya'" (click)="clearSearchState()">Похудение</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/zasobi-pri-cukrovomu-diabeti'" (click)="clearSearchState()">При сахарном
              диабете</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/zasobi-dlya-schitovidnoi-zalozi'" (click)="clearSearchState()">Для щитовидной
              железы</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/zasobi-dlya-zmicnennya-imunitetu'" (click)="clearSearchState()">Укрепление
              иммунитета</a>
          </span>
          <span class="inner-text all-category" style="font-weight: normal">
            <a [routerLink]="'/catalog/zasobi-dlya-obminu-rechovin'" (click)="clearSearchState()">Все категории</a>
          </span>
        </div>
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/catalog/zasobi-z-inshoyu-perevazhnoyu-dieyu'" (click)="clearSearchState()">Средства с
              другим подавляющим действием</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/dlya-borotbi-zi-shkidlivimi-zvichkami'" (click)="clearSearchState()">Для борьбы
              с вредными привычками</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/rizni-zasobi'" (click)="clearSearchState()">Различные средства</a>
          </span>
        </div>
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/catalog/organi-dihannya'" (click)="clearSearchState()">Органы дыхания</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/profilaktika-ta-polegshennya-bolyu-v-gorli'" (click)="clearSearchState()">Для
              горла</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/profilaktika-zahvoryuvan-i-gigiena-nosu'" (click)="clearSearchState()">Для
              носа</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/zasobi-vid-kashlyu'" (click)="clearSearchState()">От кашля</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/inshi-zasobi-dlya-dihannya'" (click)="clearSearchState()">Другие средства для
              дыхания</a>
          </span>
        </div>
        <div class="link-to-all-category">
          <a [routerLink]="'/catalog/likuvalno-profilaktichni-zasobi'" (click)="clearSearchState()">Все категории
            Лечебно-профилактические средства</a>
        </div>
      </div>
      <div #beauty class="second-level-catalog" (mouseenter)="block(beauty, mBeauty)">
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/catalog/doglyad-za-shkiroyu-oblichchya'" (click)="clearSearchState()">Уход за кожей
              лица</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/micelyarni-ta-termalni-zasobi'" (click)="clearSearchState()">Мицеллярные и
              термальные средства</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/maski-dlya-oblichchya'" (click)="clearSearchState()">Маски для лица</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/kremi-dlya-oblichchya'" (click)="clearSearchState()">Кремы для лица</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/zahist-oblichchya-vid-soncya'" (click)="clearSearchState()">Защита лица от
              солнца</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/ochischennya-shkiri-oblichchya'" (click)="clearSearchState()">Очищение кожи
              лица</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/zasobi-do-i-pislya-golinnya'" (click)="clearSearchState()">Средства до и после
              бритья</a>
          </span>
          <span class="inner-text all-category" style="font-weight: normal">
            <a [routerLink]="'/catalog/doglyad-za-shkiroyu-oblichchya'" (click)="clearSearchState()">Все категории</a>
          </span>
        </div>
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/catalog/osobista-gigena'" (click)="clearSearchState()">Личная гигиена</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/antiseptiki'" (click)="clearSearchState()">Антисептики</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/paperovi-virobi'" (click)="clearSearchState()">Бумажные изделия</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/vatni-virobi'" (click)="clearSearchState()">Ватные изделия</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/vologi-servetki'" (click)="clearSearchState()">Влажные салфетки</a>
          </span>
        </div>
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/catalog/doglyad-za-shkiroyu-golovi-i-volossyam'" (click)="clearSearchState()">Уход за
              кожей головы и волосами</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/maski-dlya-volossya'" (click)="clearSearchState()">Маски для волос</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/shampuni'" (click)="clearSearchState()">Шампуни</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/kondicioneri-ta-balzami'" (click)="clearSearchState()">Кондиционеры и
              бальзамы</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/ukladka-i-stayling'" (click)="clearSearchState()">Укладка и стайлинг</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/zasobi-vid-vipadinnya-volossya'" (click)="clearSearchState()">Средства от
              выпадения волос</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/komleksni-zasobi-dlya-volossya'" (click)="clearSearchState()">Комлексные
              средства для волос</a>
          </span>
        </div>
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/catalog/doglyad-za-porozhninoyu-rota'" (click)="clearSearchState()">Уход за полостью
              рта</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/zubni-schitki'" (click)="clearSearchState()">Зубные щетки</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/zubni-pasti'" (click)="clearSearchState()">Зубные пасты</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/opoliskuvachi-ta-sprei'" (click)="clearSearchState()">Ополаскиватели и спреи</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/zubni-nitki-i-zubochistki'" (click)="clearSearchState()">Зубные нити и
              зубочистки</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/zasobi-dlya-fiksacii-ta-doglyadu-za-zubnimi-protezami'"
              (click)="clearSearchState()">Средства для фиксации и ухода за зубными протезами</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/mizhzubni-schitki-ta-doglyad-za-breket-sistemami'"
              (click)="clearSearchState()">Межзубные щетки и уход за брекет-системами</a>
          </span>
          <span class="inner-text all-category" style="font-weight: normal">
            <a [routerLink]="'/catalog/doglyad-za-porozhninoyu-rota'" (click)="clearSearchState()">Все категории</a>
          </span>
        </div>
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/catalog/doglyad-za-tilom'" (click)="clearSearchState()">Уход за телом</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/dezodoranti-i-antiperspiranti'" (click)="clearSearchState()">Дезодоранты и
              антиперспиранты</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/olii-dlya-masazhu-ta-doglyadu'" (click)="clearSearchState()">Масла для массажа и
              ухода</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/losyoni-emulsii-dlya-tila'" (click)="clearSearchState()">Лосьоны, эмульсии для
              тела</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/kremi-dlya-tila'" (click)="clearSearchState()">Кремы для тела</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/golinnya-ta-depilyaciya'" (click)="clearSearchState()">Бритье и депиляция</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/dlya-vanni-i-dushu'" (click)="clearSearchState()">Для ванны и душа</a>
          </span>
          <span class="inner-text all-category" style="font-weight: normal">
            <a [routerLink]="'/catalog/doglyad-za-tilom'" (click)="clearSearchState()">Все категории</a>
          </span>
        </div>
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/catalog/zhinocha-gigiena'" (click)="clearSearchState()">Женская гигиена</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/prokladki-schodenni'" (click)="clearSearchState()">Прокладки ежедневные</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/prokladki-gigienichni'" (click)="clearSearchState()">Прокладки гигиенические</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/zasobi-dlya-intimnoi-gigieni'" (click)="clearSearchState()">Средства для
              интимной гигиены</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/tamponi-gigienichni'" (click)="clearSearchState()">Тампоны гигиенические</a>
          </span>
        </div>
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/catalog/zasobi-dlya-zasmagi-ta-zahist-vid-soncya'" (click)="clearSearchState()">Для
              загара и защиты от солнца</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/zasobi-pislya-zasmagi'" (click)="clearSearchState()">Средства после загара</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/dlya-bezpechnogo-zagaru'" (click)="clearSearchState()">Для безопасного
              загара</a>
          </span>
        </div>
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/catalog/dekorativna-kosmetika'" (click)="clearSearchState()">Декоративная косметика</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/makiyazh'" (click)="clearSearchState()">Макияж</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/tonalni-zasobi'" (click)="clearSearchState()">Тональные средства</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/demakiyazh'" (click)="clearSearchState()">Демакияж</a>
          </span>
        </div>
        <div class="link-to-all-category">
          <a [routerLink]="'/catalog/krasa-ta-doglyad'" (click)="clearSearchState()">Все категории Красота и уход</a>
        </div>
      </div>
      <div #children class="second-level-catalog" (mouseenter)="block(children, mChildren)">
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/catalog/doglyad-za-ditinoyu'" (click)="clearSearchState()">Уход за ребенком</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/pelyushki-ta-servetki-dityachi'" (click)="clearSearchState()">Пеленки и детские
              салфетки</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/igrashki'" (click)="clearSearchState()">Игрушки</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/pustushki-prorizuvachi-grizunki'" (click)="clearSearchState()">Пустышки,
              прорезыватели, грызунки</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/doglyad-za-zubami-ditey'" (click)="clearSearchState()">Уход за зубами детей</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/doglyad-za-volossyam-ditey'" (click)="clearSearchState()">Уход за волосами
              детей</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/pidguzki-dlya-ditey'" (click)="clearSearchState()">Подгузники для детей</a>
          </span>
          <span class="inner-text all-category" style="font-weight: normal">
            <a [routerLink]="'/catalog/doglyad-za-ditinoyu'" (click)="clearSearchState()">Все категории</a>
          </span>
        </div>
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/catalog/goduvannya'" (click)="clearSearchState()">Кормление</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/nagrudniki-ta-slyunyavchiki'" (click)="clearSearchState()">Нагрудники и
              слюнявчики</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/plyashechki-i-chashki-neprolivayki'" (click)="clearSearchState()">Бутылочки и
              чашки-непроливайки</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/dityachiy-posud'" (click)="clearSearchState()">Посуда</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/paketi-ta-konteyneri-dlya-zberigannya-moloka'"
              (click)="clearSearchState()">Пакеты и контейнеры для хранения молока</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/soski'" (click)="clearSearchState()">Соски</a>
          </span>
        </div>
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/catalog/tovari-dlya-mam'" (click)="clearSearchState()">Товары для мам</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/molokovidsmoktuvachi'" (click)="clearSearchState()">Молокоотсосы</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/laktaciyni-vkladishi-i-nakladki-na-grudi'"
              (click)="clearSearchState()">Лактационные вкладыши и накладки на грудь</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/bilizna-dlya-mam'" (click)="clearSearchState()">Белье</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/specialne-harchuvannya-dlya-mam'" (click)="clearSearchState()">Специальное
              питание</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/kosmetichni-zasobi-dlya-mam'" (click)="clearSearchState()">Косметические
              средства</a>
          </span>
        </div>
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/catalog/dityache-harchuvannya'" (click)="clearSearchState()">Детское питание</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/dityachi-pyure'" (click)="clearSearchState()">Пюре</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/bezmolochni-kashi-ta-krupi'" (click)="clearSearchState()">Безмолочные каши и
              крупы</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/dityachi-solodoschi'" (click)="clearSearchState()">Сладости</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/dityachi-soki-i-napoi'" (click)="clearSearchState()">Соки и напитки</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/molochni-sumishi'" (click)="clearSearchState()">Молочные смеси</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/specialne-dityache-harchuvannya'" (click)="clearSearchState()">Специальное
              питание</a>
          </span>
          <span class="inner-text all-category" style="font-weight: normal">
            <a [routerLink]="'/catalog/dityache-harchuvannya'" (click)="clearSearchState()">Все категории</a>
          </span>
        </div>
        <div class="link-to-all-category">
          <a [routerLink]="'/catalog/tovari-dlya-ditey-ta-mam'" (click)="clearSearchState()">Все категории Товары для
            детей и мам</a>
        </div>
      </div>
      <div #medTools class="second-level-catalog" (mouseenter)="block(medTools, mMedTools)">
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/catalog/insha-medichna-tehnika'" (click)="clearSearchState()">Другая медицинская
              техника</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/pulsometri'" (click)="clearSearchState()">Пульсометры</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/kisnevi-koncentratori'" (click)="clearSearchState()">Кислородные
              концентраторы</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/stetoskopi-i-fonendoskopi'" (click)="clearSearchState()">Стетоскопы и
              фонендоскопы</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/sluhovi-aparati'" (click)="clearSearchState()">Слуховые аппараты</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/elektrichni-grilki-i-prostiradla'" (click)="clearSearchState()">Электрические
              грелки и простыни</a>
          </span>
        </div>
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/catalog/glyukometri-ta-vitratni-materiali'" (click)="clearSearchState()">Глюкометры и
              расходные материалы</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/glyukometri'" (click)="clearSearchState()">Глюкометры</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/test-smuzhki-diagnostichni'" (click)="clearSearchState()">Тест-полоски
              диагностические</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/lanceti'" (click)="clearSearchState()">Ланцеты</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/shprici-insulinovi'" (click)="clearSearchState()">Шприцы инсулиновые</a>
          </span>
        </div>
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/catalog/tonometri'" (click)="clearSearchState()">Тонометры</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/avtomatichni-tonometri'" (click)="clearSearchState()">Автоматические</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/mehanichni-tonometri'" (click)="clearSearchState()">Механические</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/napivavtomatichni-tonometri'"
              (click)="clearSearchState()">Полуавтоматические</a>
          </span>
        </div>
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/catalog/termometri'" (click)="clearSearchState()">Термометры</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/elektroni-termometri'" (click)="clearSearchState()">Электронные</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/infrachervoni-termometri'" (click)="clearSearchState()">Инфракрасные</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/rtutni-i-bezrtutni-sklyani-termometri'" (click)="clearSearchState()">Ртутные и
              безртутные стеклянные</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/pobutovi-termometri'" (click)="clearSearchState()">Бытовые</a>
          </span>
        </div>
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/catalog/ingalyatori-nebulayzeri'" (click)="clearSearchState()">Ингаляторы
              (небулайзеры)</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/kompresorni-ingalyatori'" (click)="clearSearchState()">Компрессорные</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/ultrazvukovi-ingalyatori'" (click)="clearSearchState()">Ультразвуковые</a>
          </span>
        </div>
        <div class="link-to-all-category">
          <a [routerLink]="'/catalog/medichna-tehnika'" (click)="clearSearchState()">Все категории Медицинская
            техника</a>
        </div>
      </div>
      <div #vitamin class="second-level-catalog" (mouseenter)="block(vitamin, mVitamin)">
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/catalog/mineralni-dobavki'" (click)="clearSearchState()">Минеральные добавки</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/ribyachiy-zhir-i-omega-3-6-9'" (click)="clearSearchState()">Рыбий жир и Омега-3,
              6, 9</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/koenzim-q10'" (click)="clearSearchState()">Коэнзим Q10</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/dobavki-zaliza'" (click)="clearSearchState()">Железо</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/dobavki-magniyu'" (click)="clearSearchState()">Магний</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/dobavki-cinku'" (click)="clearSearchState()">Цинк</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/kompleksni-mineralni-dobavki'" (click)="clearSearchState()">Комплексные
              минералы</a>
          </span>
          <span class="inner-text all-category" style="font-weight: normal">
            <a [routerLink]="'/catalog/mineralni-dobavki'" (click)="clearSearchState()">Все категории</a>
          </span>
        </div>
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/catalog/vitaminni-dobavki'" (click)="clearSearchState()">Витаминные добавки</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/aminokisloti'" (click)="clearSearchState()">Аминокислоты</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/dobavki-vitaminu-b'" (click)="clearSearchState()">Витамин B</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/dobavki-vitaminu-c'" (click)="clearSearchState()">Витамин C</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/dobavki-vitaminu-d'" (click)="clearSearchState()">Витамин D</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/dobavki-vitaminiv-a-e'" (click)="clearSearchState()">Витамин A, E</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/category/kompleksni-vitaminni-dobavki'" (click)="clearSearchState()">Комплексные
              витамины</a>
          </span>
        </div>
        <div class="link-to-all-category">
          <a [routerLink]="'/catalog/vitamini-ta-mineralni-dietichni-dobavki'" (click)="clearSearchState()">Все
            категории Витамины и минеральные диетические добавки</a>
        </div>
      </div>
    </div>
  </nav>
</ng-template>
<ng-template #ua>
  <nav class="catalog-columns-wrapper">

    <div class="catalog-column left-section">
      <div class="first-level-catalog" #menuRight>

        <div #mLiki class="first-level-tab" (mouseenter)="menu.next([liki, mLiki, 'mouseenter'])"
          (mouseleave)="menu.next([liki, mLiki, 'mouseleave'])">
          <img class="fisrt-level-tab-image" src="assets/catalog-icons/liki.svg" alt="Ліки" title="Ліки">
          <p class="first-level-tab-title">
            <a [routerLink]="'/ua/catalog/liki'" (click)="clearSearchState()">Ліки</a>
          </p>
        </div>

        <div #mMedDev class="first-level-tab" (mouseenter)="menu.next([medDev, mMedDev, 'mouseenter'])"
          (mouseleave)="menu.next([medDev, mMedDev, 'mouseleave'])">
          <img class="fisrt-level-tab-image" src="assets/catalog-icons/med_tech.svg"
            alt="Вироби медичного призначення та ортопедія" title="Вироби медичного призначення та ортопедія">
          <p class="first-level-tab-title">
            <a [routerLink]="'/ua/catalog/virobi-medichnogo-priznachennya-ta-ortopediya'"
              (click)="clearSearchState()">Вироби медичного призначення та ортопедія</a>
          </p>
        </div>

        <div #mhealthOther class="first-level-tab" (mouseenter)="menu.next([healthOther, mhealthOther, 'mouseenter'])"
          (mouseleave)="menu.next([healthOther, mhealthOther, 'mouseleave'])">
          <img class="fisrt-level-tab-image" src="assets/catalog-icons/health_other.svg"
            alt="Лікувально-профілактичні засоби" title="Лікувально-профілактичні засоби">
          <p class="first-level-tab-title">
            <a [routerLink]="'/ua/catalog/likuvalno-profilaktichni-zasobi'"
              (click)="clearSearchState()">Лікувально-профілактичні засоби</a>
          </p>
        </div>

        <div #mBeauty class="first-level-tab" (mouseenter)="menu.next([beauty, mBeauty, 'mouseenter'])"
          (mouseleave)="menu.next([beauty, mBeauty, 'mouseleave'])">
          <img class="fisrt-level-tab-image" src="assets/catalog-icons/beauty.svg" alt="Краса та догляд"
            title="Краса та догляд">
          <p class="first-level-tab-title">
            <a [routerLink]="'/ua/catalog/krasa-ta-doglyad'" (click)="clearSearchState()">Краса та догляд</a>
          </p>
        </div>

        <div #mChildren class="first-level-tab" (mouseenter)="menu.next([children, mChildren, 'mouseenter'])"
          (mouseleave)="menu.next([children, mChildren, 'mouseleave'])">
          <img class="fisrt-level-tab-image" src="assets/catalog-icons/children.svg" alt="Товари для дітей та мам"
            title="Товари для дітей та мам">
          <p class="first-level-tab-title">
            <a [routerLink]="'/ua/catalog/tovari-dlya-ditey-ta-mam'" (click)="clearSearchState()">Товари для дітей та
              мам</a>
          </p>
        </div>

        <div #mMedTools class="first-level-tab" (mouseenter)="menu.next([medTools, mMedTools, 'mouseenter'])"
          (mouseleave)="menu.next([medTools, mMedTools, 'mouseleave'])">
          <img class="fisrt-level-tab-image" src="assets/catalog-icons/med_tools.svg" alt="Медична техніка"
            title="Медична техніка">
          <p class="first-level-tab-title">
            <a [routerLink]="'/ua/catalog/medichna-tehnika'" (click)="clearSearchState()">Медична техніка</a>
          </p>
        </div>

        <div #mVitamin class="first-level-tab" (mouseenter)="menu.next([vitamin, mVitamin, 'mouseenter'])"
          (mouseleave)="menu.next([vitamin, mVitamin, 'mouseleave'])">
          <img class="fisrt-level-tab-image" src="assets/catalog-icons/vitamin.svg"
            alt="Вітаміни та мінеральні дієтичні добавки" title="Вітаміни та мінеральні дієтичні добавки">
          <p class="first-level-tab-title">
            <a [routerLink]="'/ua/catalog/vitamini-ta-mineralni-dietichni-dobavki'"
              (click)="clearSearchState()">Вітаміни та мінеральні дієтичні добавки</a>
          </p>
        </div>

      </div>
    </div>
    <div class="catalog-column right-section">

      <div #liki style="z-index: 2" class="second-level-catalog" (mouseenter)="block(liki, mLiki)">
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/ua/catalog/vitamini'" (click)="clearSearchState()">Вітаміни</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/vitamin-c'" (click)="clearSearchState()">Вітамін C</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/preparati-scho-mistyat-vitamin-a-e-i-d'"
              (click)="clearSearchState()">Вітаміни A, E та D</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/vitamini-dlya-vagitnih-ta-goduyuchih'" (click)="clearSearchState()">Вагітним
              та годуючим</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/vitamini-grupi-b'" (click)="clearSearchState()">Вітаміни групи B</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/vitamini-dlya-ochey'" (click)="clearSearchState()">Для очей</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/polivitamini'" (click)="clearSearchState()">Полівітаміни</a>
          </span>
          <span class="inner-text all-category" style="font-weight: normal">
            <a [routerLink]="'/ua/catalog/vitamini'" (click)="clearSearchState()">Всі категорії</a>
          </span>
        </div>
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/ua/catalog/nevrologichni-preparati'" (click)="clearSearchState()">Неврологічні</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/antidepresanti'" (click)="clearSearchState()">Антидепресанти</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/neyroleptiki'" (click)="clearSearchState()">Нейролептики</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/snodiyni-preparati'" (click)="clearSearchState()">Снодійні</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/trankvilizatori'" (click)="clearSearchState()">Транквілізатори</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/preparati-pri-porushenni-snu-zaspokiylivi-sedativni'"
              (click)="clearSearchState()">При порушенні сну, заспокійливі, седативні</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/nootropni-preparati'" (click)="clearSearchState()">Ноотропні</a>
          </span>
          <span class="inner-text all-category" style="font-weight: normal">
            <a [routerLink]="'/ua/catalog/nevrologichni-preparati'" (click)="clearSearchState()">Всі категорії</a>
          </span>
        </div>
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/ua/catalog/protizastudni-preparati-grip-grz'" (click)="clearSearchState()">Протизастудні
              (грип, ГРЗ)</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/protivirusni-preparati'" (click)="clearSearchState()">Противірусні</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/zigrivayuchi-mazi'" (click)="clearSearchState()">Зігріваючі мазі</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/preparati-vid-kashlyu'" (click)="clearSearchState()">Від кашлю</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/zharoznizhuyuchi-preparati'" (click)="clearSearchState()">Жарознижуючі</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/kompleksni-preparati-vid-zastudi'" (click)="clearSearchState()">Комплексні
              препарати від застуди</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/preparati-vid-bolyu-v-gorli'" (click)="clearSearchState()">Від болю в
              горлі</a>
          </span>
          <span class="inner-text all-category" style="font-weight: normal">
            <a [routerLink]="'/ua/catalog/protizastudni-preparati-grip-grz'" (click)="clearSearchState()">Всі
              категорії</a>
          </span>
        </div>
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/ua/catalog/imunopreparati'" (click)="clearSearchState()">Імунопрепарати</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/vakcini-i-sirovatki'" (click)="clearSearchState()">Вакцини і сироватки</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/bakteriofagi-piobakteriofagi'" (click)="clearSearchState()">Бактеріофаги</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/imunoglobulini'" (click)="clearSearchState()">Імуноглобуліни</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/imunostimulyatori'" (click)="clearSearchState()">Імуностимулятори</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/imunodepresanti'" (click)="clearSearchState()">Імунодепресанти</a>
          </span>
        </div>
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/ua/catalog/preparati-vid-gemoroyu'" (click)="clearSearchState()">Від геморою</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/svichki-vid-gemoroyu'" (click)="clearSearchState()">Свічки від геморою</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/mazi-vid-gemoroyu'" (click)="clearSearchState()">Мазі від геморою</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/vnutrishni-preparati-dlya-likuvannya-gemoroyu'"
              (click)="clearSearchState()">Внутрішні від геморою</a>
          </span>
        </div>
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/ua/catalog/preparati-v-akusherstvi-ta-ginekologii'"
              (click)="clearSearchState()">Гінекологічні</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/antiseptiki-ta-protizapalni-preparati'"
              (click)="clearSearchState()">Протизапальні</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/gormonalni-preparati'" (click)="clearSearchState()">Гормональні</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/kontraceptivi'" (click)="clearSearchState()">Контрацептиви</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/preparati-vid-molochnici'" (click)="clearSearchState()">Від молочниці</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/preparati-pri-klimaksi'" (click)="clearSearchState()">При клімаксі</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/preparati-pri-mastopatii-ta-porushenni-ciklu'"
              (click)="clearSearchState()">При мастопатії та порушенні циклу</a>
          </span>
          <span class="inner-text all-category" style="font-weight: normal">
            <a [routerLink]="'/ua/catalog/preparati-v-akusherstvi-ta-ginekologii'" (click)="clearSearchState()">Всі
              категорії</a>
          </span>
        </div>
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/ua/catalog/preparati-dlya-travnoi-sistemi'" (click)="clearSearchState()">Травна
              система</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/preparati-dlya-vidnovlennya-funkcii-pechinki-zhovchoginni-gepatoprotektori'"
              (click)="clearSearchState()">Гепатопротектори</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/sorbenti-ta-antidoti'" (click)="clearSearchState()">Сорбенти</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/pronosni-preparati'" (click)="clearSearchState()">Проносні</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/preparati-vid-nudoti-dispepsii'" (click)="clearSearchState()">Від нудоти,
              диспепсії</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/preparati-pri-gastriti-ta-virazci'" (click)="clearSearchState()">При гастриті
              та виразці</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/preparati-dlya-vidnovlennya-mikroflori-kishechnika-shlunku'"
              (click)="clearSearchState()">Відновлення мікрофлори</a>
          </span>
          <span class="inner-text all-category" style="font-weight: normal">
            <a [routerLink]="'/ua/catalog/preparati-dlya-travnoi-sistemi'" (click)="clearSearchState()">Всі
              категорії</a>
          </span>
        </div>
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/ua/catalog/protialergeni-preparati'" (click)="clearSearchState()">Протиалергічні</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/tabletki-kapsuli-vid-alergii'" (click)="clearSearchState()">Таблетки від
              алергіі</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/maz-krem-gel-vid-alergii'" (click)="clearSearchState()">Мазі від алергії</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/preparati-vid-alergichnogo-nezhityu'" (click)="clearSearchState()">Від
              алергічного нежитю</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/enterosorbenti-pri-alergii'" (click)="clearSearchState()">Ентеросорбенти при
              алергії</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/inshi-protialergeni-preparati'" (click)="clearSearchState()">Інші
              протиалергенні препарати</a>
          </span>
        </div>
        <div class="link-to-all-category">
          <a [routerLink]="'/ua/catalog/liki'" (click)="clearSearchState()">Всі категорії Ліки</a>
        </div>
      </div>
      <div #medDev class="second-level-catalog" (mouseenter)="block(medDev, mMedDev)">
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/ua/catalog/testi-dlya-zhinok'" (click)="clearSearchState()">Тести для жінок</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/testi-na-vagitnist'" (click)="clearSearchState()">На вагітність</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/testi-na-ovulyaciyu'" (click)="clearSearchState()">На овуляцію</a>
          </span>
        </div>
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/ua/catalog/masazheri-dlya-tila'" (click)="clearSearchState()">Масажери для тіла</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/aplikatori-kuznecova'" (click)="clearSearchState()">Аплікатори Кузнєцова</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/aplikatori-lyapko'" (click)="clearSearchState()">Аплікатори Ляпко</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/banki-vakuumni'" (click)="clearSearchState()">Банки вакуумні</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/masazhni-podushki'" (click)="clearSearchState()">Масажні подушки</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/espanderi'" (click)="clearSearchState()">Еспандери</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/masazhni-kilimki'" (click)="clearSearchState()">Масажні килимки</a>
          </span>
          <span class="inner-text all-category" style="font-weight: normal">
            <a [routerLink]="'/ua/catalog/masazheri-dlya-tila'" (click)="clearSearchState()">Всі категорії</a>
          </span>
        </div>
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/ua/catalog/vitratni-materiali-dlya-terapii-ta-diagnostiki'"
              (click)="clearSearchState()">Витратні матеріали для терапії та діагностики</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/kateteri-ta-kanyuli'" (click)="clearSearchState()">Катетери та канюлі</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/shprici'" (click)="clearSearchState()">Шприци</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/aptechki'" (click)="clearSearchState()">Аптечки</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/zondi'" (click)="clearSearchState()">Зонди</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/instrumenti-i-shovniy-material'" (click)="clearSearchState()">Інструменти і
              шовний матеріал</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/holodoagenti'" (click)="clearSearchState()">Холодоагенти</a>
          </span>
          <span class="inner-text all-category" style="font-weight: normal">
            <a [routerLink]="'/ua/catalog/vitratni-materiali-dlya-terapii-ta-diagnostiki'"
              (click)="clearSearchState()">Всі категорії</a>
          </span>
        </div>
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/ua/catalog/kontracepciya-ta-lubrikanti'" (click)="clearSearchState()">Контрацепція та
              лубриканти</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/prezervativi'" (click)="clearSearchState()">Презервативи</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/spirali'" (click)="clearSearchState()">Спіралі</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/intimni-gel-zmazki'" (click)="clearSearchState()">Інтимні гель-змазки</a>
          </span>
        </div>
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/ua/catalog/bandazhi'" (click)="clearSearchState()">Бандажі</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/teypi-plastiri-kineziologichni'" (click)="clearSearchState()">Тейпи, пластирі
              кінезіологічні</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/do-i-pislyapologovi-pislyaoperaciyni-bandazhi'"
              (click)="clearSearchState()">До і післяпологові, післяопераційні бандажі</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/korektori-postavi'" (click)="clearSearchState()">Коректори постави</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/bandazhi-dlya-ruk-ta-plechey'" (click)="clearSearchState()">Бандажі для рук
              та плечей</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/shiyni-komirci-i-bandazhi-dlya-golovi'" (click)="clearSearchState()">Шийні
              комірці і бандажі для голови</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/poperekovo-krizhovi-ta-grizhovi-bandazhi'"
              (click)="clearSearchState()">Попереково-крижові та грижові бандажи</a>
          </span>
          <span class="inner-text all-category" style="font-weight: normal">
            <a [routerLink]="'/ua/catalog/bandazhi'" (click)="clearSearchState()">Всі категорії</a>
          </span>
        </div>
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/ua/catalog/doglyad-za-hvorimi'" (click)="clearSearchState()">Догляд за хворими</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/pidguzki-dlya-doroslih'" (click)="clearSearchState()">Підгузки для
              дорослих</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/pelyushki-i-prokladki-urologichni'" (click)="clearSearchState()">Пелюшки і
              прокладки урологічні</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/grilki'" (click)="clearSearchState()">Грілки</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/sprincivki'" (click)="clearSearchState()">Спринцівки</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/pipetki'" (click)="clearSearchState()">Піпетки</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/kalopriymachi'" (click)="clearSearchState()">Калоприймачі</a>
          </span>
          <span class="inner-text all-category" style="font-weight: normal">
            <a [routerLink]="'/ua/catalog/doglyad-za-hvorimi'" (click)="clearSearchState()">Всі категорії</a>
          </span>
        </div>
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/ua/catalog/medichniy-odyag-ta-zasobi-zahistu'" (click)="clearSearchState()">Медичний одяг
              та засоби захисту</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/maski-medichni-bahili-shapochki'" (click)="clearSearchState()">Маски медичні,
              бахіли, шапочки</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/berushi'" (click)="clearSearchState()">Беруші</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/rukavichki-medichni'" (click)="clearSearchState()">Рукавички медичні</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/komplekti-dlya-oglyadiv-i-operaciy'" (click)="clearSearchState()">Комплекти
              для оглядів і операцій</a>
          </span>
        </div>
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/ua/catalog/perevyazuvalni-i-fiksuyuchi-materiali'"
              (click)="clearSearchState()">Перевʼязувальні і фіксуючі матеріали</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/vata'" (click)="clearSearchState()">Вата</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/plastiri'" (click)="clearSearchState()">Пластирі</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/marlya-ta-servetki'" (click)="clearSearchState()">Марля та серветки</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/elastichni-binti'" (click)="clearSearchState()">Еластичні бинти</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/dzhguti'" (click)="clearSearchState()">Джгути</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/gipsovi-binti-ta-pidkladki'" (click)="clearSearchState()">Гіпсові бинти та
              підкладки</a>
          </span>
          <span class="inner-text all-category" style="font-weight: normal">
            <a [routerLink]="'/ua/catalog/perevyazuvalni-i-fiksuyuchi-materiali'" (click)="clearSearchState()">Всі
              категорії</a>
          </span>
        </div>
        <div class="link-to-all-category">
          <a [routerLink]="'/ua/catalog/virobi-medichnogo-priznachennya-ta-ortopediya'" (click)="clearSearchState()">Всі
            категорії Вироби медичного призначення та ортопедія</a>
        </div>
      </div>
      <div #healthOther class="second-level-catalog" (mouseenter)="block(healthOther, mhealthOther)">
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/ua/catalog/travlennya-ta-shkt'" (click)="clearSearchState()">Травлення та ШКТ</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/zakvaski'" (click)="clearSearchState()">Закваски</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/mineralna-voda'" (click)="clearSearchState()">Мінеральна вода</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/detoksikanti'" (click)="clearSearchState()">Детоксиканти</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/zasobi-dlya-vidnovlennya-vodno-solovogo-balansu'"
              (click)="clearSearchState()">Відновлення водно-сольового балансу</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/specialne-harchuvannya'" (click)="clearSearchState()">Спеціальне
              харчування</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/protiglisni-zasobi'" (click)="clearSearchState()">Протиглисні засоби</a>
          </span>
          <span class="inner-text all-category" style="font-weight: normal">
            <a [routerLink]="'/ua/catalog/travlennya-ta-shkt'" (click)="clearSearchState()">Всі категорії</a>
          </span>
        </div>
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/ua/catalog/fito-i-aromaterapiya'" (click)="clearSearchState()">Фіто і ароматерапія</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/efirni-masla'" (click)="clearSearchState()">Ефірні масла</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/fitochai-i-travi'" (click)="clearSearchState()">Фіточаї і трави</a>
          </span>
        </div>
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/ua/catalog/zasobi-dlya-sercevo-sudinnoi-sistemi'"
              (click)="clearSearchState()">Серцево-судинна система</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/antioksidanti'" (click)="clearSearchState()">Антиоксиданти</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/zasobi-dlya-normalizacii-rivnya-holesterinu'"
              (click)="clearSearchState()">Для нормалізації рівня холестерину</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/zasobi-dlya-pidtrimki-sercevo-sudinnoi-sistemi'"
              (click)="clearSearchState()">Для підтримки серцево-судинної системи</a>
          </span>
        </div>
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/ua/catalog/zovnishni-zasobi'" (click)="clearSearchState()">Зовнішні засоби</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/girchichniki-ta-znebolyuyuchi-plastiri'"
              (click)="clearSearchState()">Гірчичники та знеболюючі пластирі</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/zasobi-v-ginekologii-ta-proktologii'"
              (click)="clearSearchState()">Гінекологія та проктологія</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/zasobi-dlya-oporno-ruhovoi-sistemi'"
              (click)="clearSearchState()">Опорно-рухова система</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/dermatologichni-zasobi'" (click)="clearSearchState()">Дерматологічні</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/dlya-profilaktiki-i-likuvannya-vuha'" (click)="clearSearchState()">Для
              профілактики і лікування вуха</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/protipedikulozni-zasobi'" (click)="clearSearchState()">Протипедикульозні</a>
          </span>
          <span class="inner-text all-category" style="font-weight: normal">
            <a [routerLink]="'/ua/catalog/zovnishni-zasobi'" (click)="clearSearchState()">Всі категорії</a>
          </span>
        </div>
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/ua/catalog/zasobi-dlya-normalizacii-funkcii-zoru'" (click)="clearSearchState()">Для
              зору</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/polipshennya-zoru'" (click)="clearSearchState()">Поліпшення зору</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/doglyad-za-linzami'" (click)="clearSearchState()">Догляд за линзами</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/zvolozhennya-ta-komfort-ochey'" (click)="clearSearchState()">Зволоження та
              комфорт очей</a>
          </span>
        </div>
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/ua/catalog/zasobi-dlya-obminu-rechovin'" (click)="clearSearchState()">Обмін речовин</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/zhinoche-zdorovya'" (click)="clearSearchState()">Жіноче здоровʼя</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/choloviche-zdorovya'" (click)="clearSearchState()">Чоловіче здоровʼя</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/shudnennya'" (click)="clearSearchState()">Схуднення</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/zasobi-pri-cukrovomu-diabeti'" (click)="clearSearchState()">При цукровому
              діабеті</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/zasobi-dlya-schitovidnoi-zalozi'" (click)="clearSearchState()">Для щитовидної
              залози</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/zasobi-dlya-zmicnennya-imunitetu'" (click)="clearSearchState()">Зміцнення
              імунітету</a>
          </span>
          <span class="inner-text all-category" style="font-weight: normal">
            <a [routerLink]="'/ua/catalog/zasobi-dlya-obminu-rechovin'" (click)="clearSearchState()">Всі категорії</a>
          </span>
        </div>
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/ua/catalog/zasobi-z-inshoyu-perevazhnoyu-dieyu'" (click)="clearSearchState()">Засоби з
              іншою переважною дією</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/dlya-borotbi-zi-shkidlivimi-zvichkami'" (click)="clearSearchState()">Для
              боротьби зі шкідливими звичками</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/rizni-zasobi'" (click)="clearSearchState()">Різні засоби</a>
          </span>
        </div>
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/ua/catalog/organi-dihannya'" (click)="clearSearchState()">Органи дихання</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/profilaktika-ta-polegshennya-bolyu-v-gorli'" (click)="clearSearchState()">Для
              горла</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/profilaktika-zahvoryuvan-i-gigiena-nosu'" (click)="clearSearchState()">Для
              носу</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/zasobi-vid-kashlyu'" (click)="clearSearchState()">Від кашлю</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/inshi-zasobi-dlya-dihannya'" (click)="clearSearchState()">Інші засоби для
              дихання</a>
          </span>
        </div>
        <div class="link-to-all-category">
          <a [routerLink]="'/ua/catalog/likuvalno-profilaktichni-zasobi'" (click)="clearSearchState()">Всі категорії
            Лікувально-профілактичні засоби</a>
        </div>
      </div>
      <div #beauty class="second-level-catalog" (mouseenter)="block(beauty, mBeauty)">
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/ua/catalog/doglyad-za-shkiroyu-oblichchya'" (click)="clearSearchState()">Догляд за шкірою
              обличчя</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/micelyarni-ta-termalni-zasobi'" (click)="clearSearchState()">Міцелярні та
              термальні засоби</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/maski-dlya-oblichchya'" (click)="clearSearchState()">Маски для обличчя</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/kremi-dlya-oblichchya'" (click)="clearSearchState()">Креми для обличчя</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/zahist-oblichchya-vid-soncya'" (click)="clearSearchState()">Захист обличчя
              від сонця</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/ochischennya-shkiri-oblichchya'" (click)="clearSearchState()">Очищення шкіри
              обличчя</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/zasobi-do-i-pislya-golinnya'" (click)="clearSearchState()">Засоби до і після
              гоління</a>
          </span>
          <span class="inner-text all-category" style="font-weight: normal">
            <a [routerLink]="'/ua/catalog/doglyad-za-shkiroyu-oblichchya'" (click)="clearSearchState()">Всі
              категорії</a>
          </span>
        </div>
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/ua/catalog/osobista-gigena'" (click)="clearSearchState()">Особиста гігієна</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/antiseptiki'" (click)="clearSearchState()">Антисептики</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/paperovi-virobi'" (click)="clearSearchState()">Паперові вироби</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/vatni-virobi'" (click)="clearSearchState()">Ватні вироби</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/vologi-servetki'" (click)="clearSearchState()">Вологі серветки</a>
          </span>
        </div>
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/ua/catalog/doglyad-za-shkiroyu-golovi-i-volossyam'" (click)="clearSearchState()">Догляд
              за шкірою голови і волоссям</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/maski-dlya-volossya'" (click)="clearSearchState()">Маски для волосся</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/shampuni'" (click)="clearSearchState()">Шампуні</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/kondicioneri-ta-balzami'" (click)="clearSearchState()">Кондиціонери та
              бальзами</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/ukladka-i-stayling'" (click)="clearSearchState()">Укладка і стайлінг</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/zasobi-vid-vipadinnya-volossya'" (click)="clearSearchState()">Засоби від
              випадіння волосся</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/komleksni-zasobi-dlya-volossya'" (click)="clearSearchState()">Комплексні
              засоби для волосся</a>
          </span>
        </div>
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/ua/catalog/doglyad-za-porozhninoyu-rota'" (click)="clearSearchState()">Догляд за
              порожниною рота</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/zubni-schitki'" (click)="clearSearchState()">Зубні щітки</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/zubni-pasti'" (click)="clearSearchState()">Зубні пасти</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/opoliskuvachi-ta-sprei'" (click)="clearSearchState()">Ополіскувачі та
              спреї</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/zubni-nitki-i-zubochistki'" (click)="clearSearchState()">Зубні нитки і
              зубочистки</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/zasobi-dlya-fiksacii-ta-doglyadu-za-zubnimi-protezami'"
              (click)="clearSearchState()">Засоби для фіксації та догляду за зубними протезами</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/mizhzubni-schitki-ta-doglyad-za-breket-sistemami'"
              (click)="clearSearchState()">Міжзубні щітки та догляд за брекет-системами</a>
          </span>
          <span class="inner-text all-category" style="font-weight: normal">
            <a [routerLink]="'/ua/catalog/doglyad-za-porozhninoyu-rota'" (click)="clearSearchState()">Всі категорії</a>
          </span>
        </div>
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/ua/catalog/doglyad-za-tilom'" (click)="clearSearchState()">Догляд за тілом</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/dezodoranti-i-antiperspiranti'" (click)="clearSearchState()">Дезодоранти і
              антиперспіранти</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/olii-dlya-masazhu-ta-doglyadu'" (click)="clearSearchState()">Олії для масажу
              та догляду</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/losyoni-emulsii-dlya-tila'" (click)="clearSearchState()">Лосьйони, емульсії
              для тіла</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/kremi-dlya-tila'" (click)="clearSearchState()">Креми для тіла</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/golinnya-ta-depilyaciya'" (click)="clearSearchState()">Гоління та
              депіляція</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/dlya-vanni-i-dushu'" (click)="clearSearchState()">Для ванни і душу</a>
          </span>
          <span class="inner-text all-category" style="font-weight: normal">
            <a [routerLink]="'/ua/catalog/doglyad-za-tilom'" (click)="clearSearchState()">Всі категорії</a>
          </span>
        </div>
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/ua/catalog/zhinocha-gigiena'" (click)="clearSearchState()">Жіноча гігієна</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/prokladki-schodenni'" (click)="clearSearchState()">Прокладки щоденні</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/prokladki-gigienichni'" (click)="clearSearchState()">Прокладки гігієнічні</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/zasobi-dlya-intimnoi-gigieni'" (click)="clearSearchState()">Засоби для
              інтимної гігієни</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/tamponi-gigienichni'" (click)="clearSearchState()">Тампони гігієнічні</a>
          </span>
        </div>
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/ua/catalog/zasobi-dlya-zasmagi-ta-zahist-vid-soncya'" (click)="clearSearchState()">Для
              засмаги та захист від сонця</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/zasobi-pislya-zasmagi'" (click)="clearSearchState()">Засоби після засмаги</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/dlya-bezpechnogo-zagaru'" (click)="clearSearchState()">Для безпечного
              загару</a>
          </span>
        </div>
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/ua/catalog/dekorativna-kosmetika'" (click)="clearSearchState()">Декоративна косметика</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/makiyazh'" (click)="clearSearchState()">Макіяж</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/tonalni-zasobi'" (click)="clearSearchState()">Тональні засоби</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/demakiyazh'" (click)="clearSearchState()">Демакіяж</a>
          </span>
        </div>
        <div class="link-to-all-category">
          <a [routerLink]="'/ua/catalog/krasa-ta-doglyad'" (click)="clearSearchState()">Всі категорії Краса та
            догляд</a>
        </div>
      </div>
      <div #children class="second-level-catalog" (mouseenter)="block(children, mChildren)">
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/ua/catalog/doglyad-za-ditinoyu'" (click)="clearSearchState()">Догляд за дитиною</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/pelyushki-ta-servetki-dityachi'" (click)="clearSearchState()">Пелюшки та
              серветки дитячі</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/igrashki'" (click)="clearSearchState()">Іграшки</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/pustushki-prorizuvachi-grizunki'" (click)="clearSearchState()">Пустушки,
              прорізувачі, гризунки</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/doglyad-za-zubami-ditey'" (click)="clearSearchState()">Догляд за зубами
              дітей</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/doglyad-za-volossyam-ditey'" (click)="clearSearchState()">Догляд за волоссям
              дітей</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/pidguzki-dlya-ditey'" (click)="clearSearchState()">Підгузки для дітей</a>
          </span>
          <span class="inner-text all-category" style="font-weight: normal">
            <a [routerLink]="'/ua/catalog/doglyad-za-ditinoyu'" (click)="clearSearchState()">Всі категорії</a>
          </span>
        </div>
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/ua/catalog/goduvannya'" (click)="clearSearchState()">Годування</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/nagrudniki-ta-slyunyavchiki'" (click)="clearSearchState()">Нагрудники та
              слюнявчики</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/plyashechki-i-chashki-neprolivayki'" (click)="clearSearchState()">Пляшечки і
              чашки-непроливайки</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/dityachiy-posud'" (click)="clearSearchState()">Посуд</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/paketi-ta-konteyneri-dlya-zberigannya-moloka'"
              (click)="clearSearchState()">Пакети та контейнери для зберігання молока</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/soski'" (click)="clearSearchState()">Соски</a>
          </span>
        </div>
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/ua/catalog/tovari-dlya-mam'" (click)="clearSearchState()">Товари для мам</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/molokovidsmoktuvachi'" (click)="clearSearchState()">Молоковідсмоктувачі</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/laktaciyni-vkladishi-i-nakladki-na-grudi'"
              (click)="clearSearchState()">Лактаційні вкладиші і накладки на груди</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/bilizna-dlya-mam'" (click)="clearSearchState()">Білизна</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/specialne-harchuvannya-dlya-mam'" (click)="clearSearchState()">Спеціальне
              харчування</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/kosmetichni-zasobi-dlya-mam'" (click)="clearSearchState()">Косметичні
              засоби</a>
          </span>
        </div>
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/ua/catalog/dityache-harchuvannya'" (click)="clearSearchState()">Дитяче харчування</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/dityachi-pyure'" (click)="clearSearchState()">Пюре</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/bezmolochni-kashi-ta-krupi'" (click)="clearSearchState()">Безмолочні каші та
              крупи</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/dityachi-solodoschi'" (click)="clearSearchState()">Солодощі</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/dityachi-soki-i-napoi'" (click)="clearSearchState()">Соки і напої</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/molochni-sumishi'" (click)="clearSearchState()">Молочні суміші</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/specialne-dityache-harchuvannya'" (click)="clearSearchState()">Спеціальне
              харчування</a>
          </span>
          <span class="inner-text all-category" style="font-weight: normal">
            <a [routerLink]="'/ua/catalog/dityache-harchuvannya'" (click)="clearSearchState()">Всі категорії</a>
          </span>
        </div>
        <div class="link-to-all-category">
          <a [routerLink]="'/ua/catalog/tovari-dlya-ditey-ta-mam'" (click)="clearSearchState()">Всі категорії Товари для
            дітей та мам</a>
        </div>
      </div>
      <div #medTools class="second-level-catalog" (mouseenter)="block(medTools, mMedTools)">
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/ua/catalog/insha-medichna-tehnika'" (click)="clearSearchState()">Інша медична техніка</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/pulsometri'" (click)="clearSearchState()">Пульсометри</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/kisnevi-koncentratori'" (click)="clearSearchState()">Кисневі
              концентратори</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/stetoskopi-i-fonendoskopi'" (click)="clearSearchState()">Стетоскопи і
              фонендоскопи</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/sluhovi-aparati'" (click)="clearSearchState()">Слухові апарати</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/elektrichni-grilki-i-prostiradla'" (click)="clearSearchState()">Електричні
              грілки і простирадла</a>
          </span>
        </div>
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/ua/catalog/glyukometri-ta-vitratni-materiali'" (click)="clearSearchState()">Глюкометри та
              витратні матеріали</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/glyukometri'" (click)="clearSearchState()">Глюкометри</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/test-smuzhki-diagnostichni'" (click)="clearSearchState()">Тест-смужки
              діагностичні</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/lanceti'" (click)="clearSearchState()">Ланцети</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/shprici-insulinovi'" (click)="clearSearchState()">Шприци інсулінові</a>
          </span>
        </div>
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/ua/catalog/tonometri'" (click)="clearSearchState()">Тонометри</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/avtomatichni-tonometri'" (click)="clearSearchState()">Автоматичні</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/mehanichni-tonometri'" (click)="clearSearchState()">Механічні</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/napivavtomatichni-tonometri'"
              (click)="clearSearchState()">Напівавтоматичні</a>
          </span>
        </div>
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/ua/catalog/termometri'" (click)="clearSearchState()">Термометри</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/elektroni-termometri'" (click)="clearSearchState()">Електроні</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/infrachervoni-termometri'" (click)="clearSearchState()">Інфрачервоні</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/rtutni-i-bezrtutni-sklyani-termometri'" (click)="clearSearchState()">Ртутні і
              безртутні скляні</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/pobutovi-termometri'" (click)="clearSearchState()">Побутові</a>
          </span>
        </div>
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/ua/catalog/ingalyatori-nebulayzeri'" (click)="clearSearchState()">Інгалятори
              (небулайзери)</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/kompresorni-ingalyatori'" (click)="clearSearchState()">Компресорні</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/ultrazvukovi-ingalyatori'" (click)="clearSearchState()">Ультразвукові</a>
          </span>
        </div>
        <div class="link-to-all-category">
          <a [routerLink]="'/ua/catalog/medichna-tehnika'" (click)="clearSearchState()">Всі категорії Медична
            техніка</a>
        </div>
      </div>
      <div #vitamin class="second-level-catalog" (mouseenter)="block(vitamin, mVitamin)">
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/ua/catalog/mineralni-dobavki'" (click)="clearSearchState()">Мінеральні добавки</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/ribyachiy-zhir-i-omega-3-6-9'" (click)="clearSearchState()">Рибʼячий жир і
              Омега-3, 6, 9</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/koenzim-q10'" (click)="clearSearchState()">Коензим Q10</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/dobavki-zaliza'" (click)="clearSearchState()">Залізо</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/dobavki-magniyu'" (click)="clearSearchState()">Магній</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/dobavki-cinku'" (click)="clearSearchState()">Цинк</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/kompleksni-mineralni-dobavki'" (click)="clearSearchState()">Комплексні
              мінерали</a>
          </span>
          <span class="inner-text all-category" style="font-weight: normal">
            <a [routerLink]="'/ua/catalog/mineralni-dobavki'" (click)="clearSearchState()">Всі категорії</a>
          </span>
        </div>
        <div class="category">
          <span class="inner-text">
            <a [routerLink]="'/ua/catalog/vitaminni-dobavki'" (click)="clearSearchState()">Вітамінні добавки</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/aminokisloti'" (click)="clearSearchState()">Амінокислоти</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/dobavki-vitaminu-b'" (click)="clearSearchState()">Вітамін B</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/dobavki-vitaminu-c'" (click)="clearSearchState()">Вітамін C</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/dobavki-vitaminu-d'" (click)="clearSearchState()">Вітамін D</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/dobavki-vitaminiv-a-e'" (click)="clearSearchState()">Вітамін A, E</a>
          </span>
          <span class="inner-text-second">
            <a [routerLink]="'/ua/category/kompleksni-vitaminni-dobavki'" (click)="clearSearchState()">Комплексні
              вітаміни</a>
          </span>
        </div>
        <div class="link-to-all-category">
          <a [routerLink]="'/ua/catalog/vitamini-ta-mineralni-dietichni-dobavki'" (click)="clearSearchState()">Всі
            категорії Вітаміни та мінеральні дієтичні добавки</a>
        </div>
      </div>
    </div>
  </nav>
</ng-template>