import { BzProduct } from '@interfaces';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';


export const adapterFavoriteProducts: EntityAdapter<BzProduct> = createEntityAdapter<BzProduct>();

export interface FavoritePageState {
    products: EntityState<BzProduct>;
    page: number
}

export const initialFavoritePageState: FavoritePageState = {
    products: adapterFavoriteProducts.getInitialState([]),
    page: 1
};