import { ECurrentOrderBlock } from '@enums';
import { ActiveMobileBlock } from 'src/app/pages/pharmacies/pharmacies.component';

export interface PrivatePageState {
    currentBlok: ECurrentOrderBlock;
    exception: string;
    pharmaciesState: ActiveMobileBlock;
    isRoutFromHomePage: boolean;
};

export const initialPrivatePageState: PrivatePageState = {
    currentBlok: ECurrentOrderBlock.orders,
    exception: null,
    pharmaciesState: null,
    isRoutFromHomePage: false
};
