import { DeletePharmcyInCartFromListAction, RunEffectDeletePharmcyInCartFromListAction } from '@actions';
import { Injectable } from '@angular/core';
import { LocalStorageService } from '@core/services';
import { BzApteka } from '@interfaces';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap } from 'rxjs/operators';



@Injectable()
export class IsEmptyCartPharmaEffects {



  constructor(private actions$: Actions, private ls: LocalStorageService) {}

  IsEmptyCartCurrentPharma$ = createEffect(
    () => {
      return this.actions$
      .pipe(
        ofType(RunEffectDeletePharmcyInCartFromListAction),
        mergeMap(
          (action) => {
            return this.ls.areThereAnyProductsFromThisPharmacyInTheShoppingCart(action.pharmcy.id)
              .pipe(
                map((apteka: BzApteka) => {
                  let result: boolean;
                  if (apteka.id) {
                    result = apteka.products.length > action.pharmcy.products.length;
                  }
                  return DeletePharmcyInCartFromListAction({pharmcy: action.pharmcy, isCart: result});
                })
              )
          }
        )
      )
    }
  )

}
