import { FormatPhoneHelperService } from './service/format-phone.helper.service';
import { PhoneFormatPipe } from './pipe/phone-format.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputFormatPhoneDirective } from './directive/input-format-phone.directive';

@NgModule({
  declarations: [
    PhoneFormatPipe,
    InputFormatPhoneDirective
  ],
  imports: [
    CommonModule
  ],
  providers: [
    FormatPhoneHelperService
  ],
  exports: [
    PhoneFormatPipe,
    InputFormatPhoneDirective
  ]
})
export class BzPhoneMaskModule { }
