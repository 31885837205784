import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { EMPTY, Observable, forkJoin, of } from 'rxjs';
import { BzProduct, IProduct } from '@interfaces';
import { LoadFullInstraction, SetLoadingAction } from '../../../store/actions';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '@appStates';
import { RestUrlService, TypeUrl } from './settings/rest-url.service';
import { isPlatformBrowser } from '@angular/common';
import { LocalStorageService } from '../storage.services/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class InfoDrugApiService {
  private readonly _url: string;

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: Object,
    private readonly http: HttpClient,
    private readonly store: Store<AppState>,
    private readonly urlRest: RestUrlService,
    private readonly localStorageService: LocalStorageService
  ) {
    this._url = 'https:' + this.urlRest.getUrl(TypeUrl.rest);
  }

  public getProductById(
    id: number,
    townEn?: string
  ): Observable<IProduct> {
    this.store.dispatch(SetLoadingAction({ loading: true }));
    let params: HttpParams = new HttpParams()
      .set('id', id.toString());
    if (townEn) {
      params = params.append('townEn', townEn);
    }
    return this.http.get<IProduct>(this._url + '/api/info-drug', { params });
  }

  public getAnalogs(product: IProduct): Observable<BzProduct[]> {
    if (!product?.productInfo.substance ||
      !product?.productInfo.ath ||
      !product?.productInfo.dosage ||
      !product?.productInfo.form) { return of([]); }
    const params: HttpParams = new HttpParams()
      .set('id', product?.id.toString())
      .set('substance', product?.productInfo.substance.toString())
      .set('ath', product?.productInfo.ath.toString())
      .set('dosage', product?.productInfo.dosage.toString())
      .set('form', product?.productInfo.form.toString());
    return this.http.get<BzProduct[]>(this._url + '/api/info-drug/analogs', { params }).pipe(
      mergeMap((response: BzProduct[]) => {
        if (isPlatformBrowser(this.platformId)) {
          return forkJoin([of(response), this.localStorageService.getProductsIdSet(), this.localStorageService.getFavoriteIdSet()]);
        } else {
          return forkJoin([of(response), of(new Set<number>()), of(new Set<number>())]);
        }
      }),
      map(([analogs, list, favorite]) => {
        analogs.forEach((product) => {
          list.has(product.id) ? product.inList = true : product.inList = false;
          favorite.has(product.id) ? product.isFavorite = true : product.isFavorite = false;
        });
        return analogs;
      }),
      catchError(() => EMPTY)
    )
  }

  public getSimilarProducts(product: IProduct): Observable<BzProduct[]> {
    if (!product?.productInfo.substance ||
      !product?.productInfo.ath ||
      !product?.productInfo.dosage ||
      !product?.productInfo.form) { return of([]); }
    const params: HttpParams = new HttpParams()
      .set('id', product?.id.toString())
      .set('substance', product?.productInfo.substance.toString())
      .set('ath', product?.productInfo.ath.toString())
      .set('dosage', product?.productInfo.dosage.toString())
      .set('form', product?.productInfo.form.toString());
    return this.http.get<BzProduct[]>(this._url + '/api/info-drug/together', { params }).pipe(
      mergeMap((response: BzProduct[]) => {
        if (isPlatformBrowser(this.platformId)) {
          return forkJoin([of(response), this.localStorageService.getProductsIdSet(), this.localStorageService.getFavoriteIdSet()]);
        } else {
          return forkJoin([of(response), of(new Set<number>()), of(new Set<number>())]);
        }
      }),
      map(([similarProducts, list, favorite]) => {
        similarProducts.forEach((product) => {
          list.has(product.id) ? product.inList = true : product.inList = false;
          favorite.has(product.id) ? product.isFavorite = true : product.isFavorite = false;
        });
        return similarProducts;
      }),
      catchError(() => EMPTY)
    );
  }

  public getFullInstraction(id: number): Observable<boolean> {
    const params: HttpParams = new HttpParams().set('id', id.toString());
    return this.http.get(this._url + '/api/info-drug/full-instruction', { params })
      .pipe(
        map((data: string) => {
          this.store.dispatch(LoadFullInstraction({ fullInstraction: data }));
          return true;
        })
      );
  }

}
