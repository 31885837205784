import { AppState } from '@appStates';
import { createSelector } from '@ngrx/store';
import { ICity } from '@interfaces';
import { LocationPageState } from '@states';
import { IFullAddressResponse, IFullAddress } from 'src/app/shared/interfaces/location/full-adress-response.model';


const selectLocationState = (state: AppState): LocationPageState => state.location;

export const selectCitiesList = createSelector(
  selectLocationState,
  (state: LocationPageState): ICity[] => state.cities
);

export const selectSearchedCitiesHints = createSelector(
  selectLocationState,
  (state: LocationPageState): ICity[] => state.searchCitiesHints
);

export const selectFullAddresses = createSelector(
  selectLocationState,
  (state: LocationPageState): IFullAddressResponse => state.fullAdresses
);

export const selectAddress = createSelector(
  selectLocationState,
  (state: LocationPageState): IFullAddress => state.address
);