import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { RestUrlService, TypeUrl } from './settings/rest-url.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { EMPTY, Observable, forkJoin, of } from 'rxjs';
import { BzProduct, IPreparatPageResponse, ITableCatalogProductsResponse, ITradeGoodsResponse } from '@interfaces';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { LocalStorageService } from '../storage.services/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class TradeNameGoodsService {
  private readonly _url: string;

  constructor(
    private readonly http: HttpClient,
    private readonly urlRest: RestUrlService,
    private readonly localStorageService: LocalStorageService,
    @Inject(PLATFORM_ID) private readonly platformId: Object
  ) {
    this._url = 'https:' + this.urlRest.getUrl(TypeUrl.rest);
  }

  getFaqForTradeNamePage(pattern: string): Observable<ITradeGoodsResponse> {
    const params = new HttpParams().set('pattern', pattern);
    return this.http.get<ITradeGoodsResponse>(this._url + `/api/search-proposed/alpha-faq`, { params }).pipe(
      catchError(() => EMPTY)
    );
  }

  getPricesTableForTradeNamePage(pattern: string): Observable<ITableCatalogProductsResponse> {
    const params = new HttpParams().set('pattern', pattern);
    return this.http.get<ITableCatalogProductsResponse>(this._url + `/api/search-proposed/alpha-table`, { params }).pipe(
      catchError(() => EMPTY)
    );
  }

  getAnalogsForTradeNamePage(pattern: string): Observable<BzProduct[]> {
    const params = new HttpParams().set('pattern', pattern);
    return this.http.get<BzProduct[]>(this._url + `/api/search-proposed/alpha-analogs`, { params }).pipe(
      mergeMap((response: BzProduct[]) => {
        if (isPlatformBrowser(this.platformId)) {
          return forkJoin([of(response), this.localStorageService.getProductsIdSet(), this.localStorageService.getFavoriteIdSet()]);
        } else {
          return forkJoin([of(response), of(new Set<number>()), of(new Set<number>())]);
        }
      }),
      map(([analogs, list, favorite]) => {
        analogs.forEach((product) => {
          list.has(product.id) ? product.inList = true : product.inList = false;
          favorite.has(product.id) ? product.isFavorite = true : product.isFavorite = false;
        });
        return analogs;
      }),
      catchError(() => EMPTY)
    );
  }

  getSeoDataForPreparatPage(pattern: string, townEn: string): Observable<IPreparatPageResponse> {
    const params = new HttpParams().set('pattern', pattern).set('townEn', townEn);
    return this.http.get<IPreparatPageResponse>(this._url + `/api/search-proposed/alpha-town`, { params }).pipe(
      map((response) => {
        return {
          ...response,
          exists: response.exists.map((pharmacy) => {
            return {
              ...pharmacy,
              isExpanded: false,
            }
          })
        };
      }),
      catchError(() => EMPTY)
    );
  }
}
