import { inject } from '@angular/core';
import { ResolveFn, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, map, switchMap } from 'rxjs';
import { IProduct } from '@interfaces';
import { InfoDrugApiService } from '../services/api.services/info-drug-api.service';
import { InfoDrugHelperService } from '../services/helpers/info-drug-helper.service';

export const infoDragResolver: ResolveFn<boolean> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> => {
  const apiInfoDrag: InfoDrugApiService = inject(InfoDrugApiService);
  const helperDrag: InfoDrugHelperService = inject(InfoDrugHelperService);
  const { id } = route.params;

  return apiInfoDrag.getProductById(+id).pipe(
      switchMap((product: IProduct) => helperDrag.intoMergeMapHelper(product)),
      map(([product, analogs, similar, list, favorite]) =>
        helperDrag.intoMapHelper(product, similar, analogs, list, favorite)));
};
