import { Action, createReducer, on } from '@ngrx/store';
import { CatalogPageState, initialCatalogPageState } from '@states';

import * as CatalogActions from '@actions';

const reducer = createReducer(
  initialCatalogPageState,
  on(
    CatalogActions.setSelectedCategoryAction, (state, action) => {
      return ({
        ...state,
        category: action.category
      });
    }
  )
);

export function catalogReducer(state: CatalogPageState | undefined, action: Action): CatalogPageState {
  return reducer(state, action);
}
