import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BzSelectRegionComponent } from './bz-select-city-popup.component';
import { TranslocoModule } from '@ngneat/transloco';
import { ReactiveFormsModule } from '@angular/forms';
import { BzCitiesSearchModule } from '../../blocks-ui/bz-cities-search/bz-cities-search.module';



@NgModule({
  declarations: [BzSelectRegionComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    ReactiveFormsModule,
    BzCitiesSearchModule,
  ],
  exports: [BzSelectRegionComponent]
})
export class BzSelectRegionModule { }
