import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { environment } from 'src/environments/environment';

import { ServiceWorkerModule } from '@angular/service-worker';

import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { FavoriteEffects, NavigateEffects, SearchEffects, IsEmptyCartPharmaEffects, InfoDragEffects } from '@effects';

import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';

import { FooterModule, LayoutModule } from '../modules-ui';
import { appReducer } from 'src/app/store/reducers/app.reducer';
import { PrimaryInterceptor } from './interceptors/primary.interceptor';
import { CategoryRedirectInterceptor } from './interceptors/category-redirect.interceptor';

import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { CustomSerializer } from '../store/router/custom-serializer';
import { CustomHttpClientService } from './services/custom-http-client.service';
import { HeaderModule } from '../modules-ui/layouts';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    StoreModule.forRoot(appReducer),
    EffectsModule.forRoot([SearchEffects, FavoriteEffects, NavigateEffects, IsEmptyCartPharmaEffects, InfoDragEffects]),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
    StoreRouterConnectingModule.forRoot({
      serializer: CustomSerializer,
    }),
    ShareButtonsModule.withConfig({
      debug: true
    }),
    ShareIconsModule,
    HeaderModule,
    FooterModule,
    LayoutModule,
    ServiceWorkerModule.register('ngsw-worker.js',
      {
        enabled: environment.production,
        scope: './',
        registrationStrategy: 'registerImmediately'
      })
  ],
  providers: [
    CustomHttpClientService,
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PrimaryInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CategoryRedirectInterceptor,
      multi: true
    }
  ],
  exports: [
    FooterModule,
    LayoutModule,
    HeaderModule
  ]
})
export class CoreModule {
  constructor(
    @Optional() @SkipSelf() parentModule: CoreModule
  ) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import only in AppModule');
    }
  }
}
