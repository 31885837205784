import { Injectable } from '@angular/core';
import { PREFIX_PHONE } from '../bz-phone-mask.config';

@Injectable()
export class FormatPhoneHelperService {

  constructor() { }

  static format = (value: string, withPrefix: boolean = true): string => {
    const clearValue: string = FormatPhoneHelperService.clear(value);
    if (clearValue.length === 10) {
      const rawValue: RegExpMatchArray = clearValue.replace(/\D/g, '').match(/(\d{3})(\d{3})(\d{4})/);
      return  (withPrefix ? PREFIX_PHONE : '') + '(' + rawValue[1] + ') ' + rawValue[2] + ' ' + rawValue[3];
    } else {
      return clearValue;
    }

  }

  static clear = (value: string): string => {
    const withOutPrefix: string = value.replace(PREFIX_PHONE.trim(), '').trim();
    return withOutPrefix.replace(/[\s\-\(\)]+/g, '');
  }
}
