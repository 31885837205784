import {
  SetCurrentBlockMobilePageSuccess,
  SetStatePrevPageMobileSuccess
} from '../../store/actions';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppState } from '@appStates';
import { ListLazyComponents, TypeDevice } from '@enums';
import { select, Store } from '@ngrx/store';
import { ScreenDetectedService } from '.';
import { LocalizationService } from './localization.service';
import { selectPreviousRoutForMobile } from '../../store/selectors';
import { take } from 'rxjs/operators';
import { RouterStateUrl } from 'src/app/store/router/custom-serializer';
import { getPrevRouteState } from 'src/app/store/selectors/navigate.selectors';

@Injectable({
  providedIn: 'root'
})
export class ApplicationRoutingService {

  constructor(
    private readonly screen: ScreenDetectedService,
    private readonly locale: LocalizationService,
    private readonly router: Router,
    private readonly store: Store<AppState>
  ) { }

  public navigate(to: ListLazyComponents, from: string): void {
    if (to === ListLazyComponents.toHomePageMobile) {
      this.router.navigate(['/']);
      return;
    }

    if (to === null) {
      // let prev: string;
      // this.store.pipe(take(1), select(selectPreviousRoutForMobile))
      //   .subscribe((prevRout: string) => prev = prevRout);
      // this.router.navigateByUrl(prev);
      window.history.back();
      this.store.dispatch(SetStatePrevPageMobileSuccess({ rout: null }));
      return;
    }

    this.store.dispatch(SetCurrentBlockMobilePageSuccess({ block: to }));

    switch (this.screen.geTypeDevice()) {
      case TypeDevice.mobile:
        if (!window.location.pathname.includes('mobile')) {
          this.router.navigate(this.locale.getReturnParamsLang(['mobile']));
          this.store.dispatch(SetStatePrevPageMobileSuccess({ rout: from }));
        }
        break;
      case TypeDevice.desktop:
        break;
    }
  }

  public returnPrevPageMobile(): void {
    let prev: RouterStateUrl;
    this.store.pipe(take(1), select(getPrevRouteState)).subscribe((p: RouterStateUrl) => prev = p);
    this.router.navigateByUrl(prev.url);
  }

}
