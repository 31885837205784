import { NgModule } from '@angular/core';
import { SafeHTMLPipe } from './pipes/safe-html.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MathfloorPipe } from './pipes/mathfloor.pipe';
import { MoneyFormatPipe } from './pipes/money-format.pipe';
import { IsImgNullPipe } from './pipes/is-img-null.pipe';
import { DeclensionPipe } from './pipes/declension.pipe';
import { DeclensionStringPipe } from './pipes/declension-string.pipe';
import { OutsideDirective } from './directives/outside.directive';
import { MathroundPipe } from './pipes/mathround.pipe';
import { QuantityTextPipe } from './pipes/quantity-text.pipe';
import { AppShellRenderDirective } from './directives/app-shell-render.directive';
import { AppShellNoRenderDirective } from './directives/app-shell-no-render.directive';
import { BzImageDirective, ScreenDirective } from '@directives';
import { OthersClickCatcherDirective } from './directives/others-click-catcher.directive';
import { NavigateEmitterDirective } from './directives/navigate-emitter.directive';
import { TranslateDirective } from './directives/translate.directive';
import { AplhabeticalPipe } from './pipes/alphabetical-links.pipe';
import { PharmaciesFiltersCountPipe } from './pipes/pharmacies-filters-count.pipe';
import { InfoDrugRouterLinkPipe } from './pipes/get-link-to-info-drug.pipe';
import { FilterListLinkPipe } from './pipes/list-link-for-filters.pipe';
import { IsCurrentOrderPipe } from './pipes/is-current-order-by-status-id.pipe';
import { GetLinkForOrderSharingPipe } from './pipes/get-link-for-order-sharing.pipe';
import { BannerIndexPipe } from './pipes/banner-index.pipe';

@NgModule({
  declarations: [
    SafeHTMLPipe,
    MoneyFormatPipe,
    MathfloorPipe,
    IsImgNullPipe,
    DeclensionPipe,
    DeclensionStringPipe,
    AplhabeticalPipe,
    BannerIndexPipe,
    InfoDrugRouterLinkPipe,
    IsCurrentOrderPipe,
    PharmaciesFiltersCountPipe,
    OutsideDirective,
    MathroundPipe,
    QuantityTextPipe,
    FilterListLinkPipe,
    GetLinkForOrderSharingPipe,
    AppShellRenderDirective,
    AppShellNoRenderDirective,
    ScreenDirective,
    OthersClickCatcherDirective,
    NavigateEmitterDirective,
    TranslateDirective,
    BzImageDirective
  ],
  exports: [
    SafeHTMLPipe,
    MoneyFormatPipe,
    MathfloorPipe,
    IsImgNullPipe,
    DeclensionPipe,
    DeclensionStringPipe,
    AplhabeticalPipe,
    BannerIndexPipe,
    InfoDrugRouterLinkPipe,
    IsCurrentOrderPipe,
    PharmaciesFiltersCountPipe,
    FormsModule,
    ReactiveFormsModule,
    OutsideDirective,
    MathroundPipe,
    QuantityTextPipe,
    FilterListLinkPipe,
    GetLinkForOrderSharingPipe,
    AppShellRenderDirective,
    AppShellNoRenderDirective,
    ScreenDirective,
    OthersClickCatcherDirective,
    NavigateEmitterDirective,
    TranslateDirective,
    BzImageDirective
  ]
})
export class SharedModule { }
