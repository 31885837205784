import { LoadFavoriteProductsAction, MarkFavoriteAction } from '@actions';
import { Injectable } from '@angular/core';
import { AppState } from '@appStates';
import { BzProduct } from '@interfaces';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { selectAllFavoriteProducts } from '@selectors';
import { of } from 'rxjs';
import { map, mergeMap, withLatestFrom } from 'rxjs/operators';



@Injectable()
export class FavoriteEffects {

  constructor(private actions$: Actions, private store: Store<AppState>) { }

  getFavoriteProducts$ = createEffect(() => this.actions$.pipe(
    ofType(MarkFavoriteAction),
    withLatestFrom(this.store.select(selectAllFavoriteProducts)),
    mergeMap(([action, state]) => {
      let products: BzProduct[] = state;
      products.pop();
      let result: BzProduct[] = [{ ...action.product, isFavorite: true }, ...products];
      return of(result).pipe(
        map((res: BzProduct[]) => LoadFavoriteProductsAction({ products: res }))
      );
    })
  ));

}
