<div class="left">
  <a [routerLink]="getHomePageLink()">
    <p class="logo" [class.logo-not-home]="!isHomePage"></p>
  </a>
</div>
<div class="center">
  <app-input-search *ngIf="!isHomePage" [headerLocation]="true"></app-input-search>
</div>
<div class="right">
  <ng-container>
    <app-working-panel [menu]="menuWP" [width]="'auto'"></app-working-panel>
  </ng-container>
</div>
