import { BzProduct } from '@interfaces'
import { IProduct } from '../../shared/interfaces/product.model'

export interface IProductState {
    product: IProduct;
    analogs: BzProduct[];
    similar: BzProduct[];
    fullInstraction: string;
    loading: boolean;
}

export const initialProductState: IProductState = {
    product: null,
    analogs: [],
    similar: [],
    fullInstraction: null,
    loading: false
}
