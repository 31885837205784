import { Action, createReducer, on } from '@ngrx/store';
import * as NavigationsActions from '../actions/navigation.actions';
import { RouterStateUrl } from '../router/custom-serializer';
import { initialNavigationState, NavigationState } from '../state/routs-app.state';

export const reducer = createReducer(
  initialNavigationState,
  on(
    NavigationsActions.AddNavigationsPrevRout, (state, action) => {
      let prev: RouterStateUrl = { ...action.rout };
      const target: string = action.state.url ?? '';
      if (prev.url === "" || !prev.url) prev.url = "/";
      // if (prev.url.includes('/mobile') || target.includes('/mobile')) prev = {...state.rout};
      // if (prev.url.includes('/pharmacies')) prev = {...state.rout};
      if (prev.url === target) return { ...initialNavigationState };
      return ({
        ...state,
        rout: prev
      })
    }
  )
);

export function navigationReducer(state: NavigationState | undefined, action: Action): NavigationState {
  return reducer(state, action);
}
