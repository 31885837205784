<nav class="crumbs" [ngClass]="{
  'scrollbar': screenDetectedService.isMobile(),
  'padding-top': setPaddingMobile
}">
  <span>
    <a [routerLink]="getHomePageLink()">{{'search-for-drugs' | transloco}}</a>
  </span>
  <span>
    <a>{{ secondLinkText }}</a>
  </span>
</nav>
