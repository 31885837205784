import { DeletePharmcyInCartFromListAction, DeleteToTopInListPharmaciesAction, UpdateCartMarkerAction } from '../../../store/actions';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { AppState } from '@appStates';
import { KeysLS } from '@enums';
import { environment } from '@env';
import { BzApteka, BzProduct, HistorySearchList, IOrderIdWithDate, ListOptions } from '@interfaces';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, combineLatest, of, throwError } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { ListLocalStorageService } from './list-local-storage.service';
import { OrderLocalStorageService } from './order-local-storage.service';
import { ViewedLocalStorageService } from './viewed-local-storage.service';
import { FavoriteLocalStorageService } from './favorite-local-storage.service';
import { HistoryLocalStorageService } from './history-local-storage.service';
import { CartLocalStorageService } from './cart-local-storage.service';
import { MemoryStorageApiService } from './memory-storage-api.service';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  public limitLengthHS = 5;
  public limitLengthViewed = 10;
  public apiUrl: string = environment.restServer;

  public listSubject = new BehaviorSubject<BzProduct[]>(null);
  public listCountSubject = new BehaviorSubject<number>(0);
  public listSumSubject = new BehaviorSubject<number>(0);
  public isListHasProductWithoutMiddlePrice = new BehaviorSubject<boolean>(false);

  public cartSubject = new BehaviorSubject<BzApteka[]>(null);
  public cartCountSubject = new BehaviorSubject<number>(0);
  public cartSumSubject = new BehaviorSubject<number>(0);
  public cartEconomySubject = new BehaviorSubject<number>(null);

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: Object,
    private readonly listLocalStorageService: ListLocalStorageService,
    private readonly orderLocalStorageService: OrderLocalStorageService,
    private readonly viewedLocalStorageService: ViewedLocalStorageService,
    private readonly favoriteLocalStorageService: FavoriteLocalStorageService,
    private readonly historyLocalStorageService: HistoryLocalStorageService,
    private readonly cartLocalStorageService: CartLocalStorageService,
    private readonly memory: MemoryStorageApiService,
    private readonly store: Store<AppState>
  ) { }

  public checkLocalStorageStates(): void {
    if (this.orderLocalStorageService.historyOrdersId === null || this.orderLocalStorageService.historyOrdersId === undefined) {
      this.orderLocalStorageService.historyOrdersId = '';
    }
    if (this.orderLocalStorageService.ordersHistoryIdsWithDate === null || this.orderLocalStorageService.ordersHistoryIdsWithDate === undefined) {
      this.orderLocalStorageService.ordersHistoryIdsWithDate = '[]';
    }
    if (this.viewedLocalStorageService.viewed === null || this.viewedLocalStorageService.viewed === undefined) {
      this.viewedLocalStorageService.viewed = '';
    }
    if (this.favoriteLocalStorageService.favorite === null || this.favoriteLocalStorageService.favorite === undefined) {
      this.favoriteLocalStorageService.favorite = '';
    }
    if (this.historyLocalStorageService.history === null || this.historyLocalStorageService.history === undefined) {
      this.historyLocalStorageService.history = '[]';
    }
    if (this.cartLocalStorageService.cart === null || this.cartLocalStorageService.cart === undefined) {
      this.cartLocalStorageService.cart = '[]';
    }
    if (this.listLocalStorageService.list === null || this.listLocalStorageService.list === undefined) {
      this.listLocalStorageService.list = '[]';
    }
    if (this.orderLocalStorageService.orderWithError === null || this.orderLocalStorageService.orderWithError === undefined) {
      this.orderLocalStorageService.orderWithError = '[]';
    }
  }

  public toHS(source: 'arbitrary' | 'specific', value: string, option?: ListOptions): void {
    const newItem: HistorySearchList = { source, value, option: option || null };
    const currentState: string = this.historyLocalStorageService.history;
    let currentObjects: HistorySearchList[];
    currentObjects = currentState && currentState.length > 0 ? JSON.parse(currentState) : [];
    const searchValues: Set<string> = new Set();
    currentObjects.forEach(element => searchValues.add(element.value));
    if (!searchValues.has(newItem.value)) {
      currentObjects.unshift(newItem);
      if (currentObjects.length > this.limitLengthHS) {
        currentObjects.pop();
      }
      this.historyLocalStorageService.history = JSON.stringify(currentObjects);
    }
  }

  public getHS(): Observable<HistorySearchList[]> {
    return this.getObservableFromLocalStorage('history')
      .pipe(
        map((value: string) => {
          return value.length > 0 ? JSON.parse(value) : [];
        })
      );
  }

  public getIdsAllGoodsInPharmacies(): Observable<Map<number, Set<number>>> {
    return this.getObservableFromLocalStorage('cart').pipe(
      map((data: string) => {
        const parsedData: BzApteka[] = data.length > 0 ? JSON.parse(data) : [];
        const result: Map<number, Set<number>> = new Map();
        parsedData.forEach(
          (apteka: BzApteka) => {
            if (apteka.id) {
              result.set(
                apteka.id,
                new Set(apteka.products.map((product: BzProduct) => product.id))
              );
            }
          }
        );
        return result;
      })
    );
  }

  public getProductsFromList(): Observable<BzProduct[]> {
    return this.getObservableFromLocalStorage('list').pipe(
      map((data: string) => data.length > 0 ? JSON.parse(data) : [])
    );
  }

  public getProductsIdSet(): Observable<Set<number>> {
    if (isPlatformBrowser(this.platformId)) {
      return this.getObservableFromLocalStorage('list').pipe(
        map((data: string) => {
          const productsArray: BzProduct[] = data?.length > 0 ? JSON.parse(data) : [];
          const result: Set<number> = new Set();
          productsArray.forEach((product: BzProduct) => result.add(product.id));
          return result;
        })
      );
    } else { of(new Set()); }
  }

  public getProducts(): void {
    this.getObservableFromLocalStorage('cart').pipe(take(1)).subscribe((data: string) => {
      const pharmaciesArray: BzApteka[] = data.length > 0 ? JSON.parse(data) : [];
      this.cartSubject.next(pharmaciesArray);
      pharmaciesArray.forEach((apteka: BzApteka) => {
        let newTotalPriceWithDiscount = 0;
        let newTotalEconomy = 0;
        let newTotalOldSum = 0;
        apteka.products.forEach((product: BzProduct) => {
          newTotalPriceWithDiscount += Number((product.packageNum * product.priceSingle).toFixed(2)) + (product.blisterNum * product.priceBlister);
          newTotalEconomy += (product.packageNum * product.priceEconomy) + (product.blisterNum * product.priceEconomyBlister);
          newTotalOldSum += Number((product.price + product.totalEconomy).toFixed(2));
        });
        apteka.prices.withDiscount = newTotalPriceWithDiscount;
        apteka.prices.discount = newTotalEconomy;
        apteka.prices.outDiscount = newTotalOldSum;
      });
      this.cartLocalStorageService.cart = JSON.stringify(pharmaciesArray);
    });
  }

  public updateOrder(aptekaId: number, productId: number): void {
    this.getObservableFromLocalStorage('cart').pipe(take(1)).subscribe((data: string) => {
      const parsedData: BzApteka[] = data.length > 0 ? JSON.parse(data) : [];
      const idx: number = parsedData.findIndex(element => element.id === aptekaId);
      if (idx == -1) return;
      if (parsedData[idx].products.length <= 1) {
        this.deleteFromCart(aptekaId);
        this.store.dispatch(UpdateCartMarkerAction({ markerID: parsedData[idx].id, value: false }));
        this.store.dispatch(DeletePharmcyInCartFromListAction({ pharmcy: parsedData[idx] }));
        this.store.dispatch(DeleteToTopInListPharmaciesAction({ id: parsedData[idx].id }));
        return;
      }
      parsedData[idx].products = parsedData[idx].products.filter(element => element.id !== productId);
      parsedData[idx].productTotalNum = parsedData[idx].products.length;
      this.cartLocalStorageService.cart = JSON.stringify(parsedData);
      this.getProducts();
      this.sumCartProducts();
      this.getCartLength();
    });
  }

  public deleteCurrentOrderFromCart(aptekaId: number, productsIds: Set<number>): void {
    this.getObservableFromLocalStorage('cart').pipe(take(1)).subscribe((data: string) => {
      const arrayPharmacies: BzApteka[] = data.length > 0 ? JSON.parse(data) : [];
      const idx: number = arrayPharmacies.findIndex(element => element.id === aptekaId);
      if (idx == -1) return;
      const apteka: BzApteka = arrayPharmacies[idx];
      apteka.products = apteka.products.filter((product: BzProduct) => !productsIds.has(product.id));
      apteka.productTotalNum = apteka.products.length;

      if (apteka.products.length === 0) {
        this.deleteFromCart(aptekaId);                                                       // удаляем аптеку, если продуктов в ней не осталось
        this.store.dispatch(UpdateCartMarkerAction({ markerID: apteka.id, value: false }));  // снимаем маркер и меняем цвет и текст кнопки
        this.store.dispatch(DeletePharmcyInCartFromListAction({ pharmcy: apteka }));         // и выходим из метода..
        return;
      }

      this.cartLocalStorageService.cart = JSON.stringify(arrayPharmacies);
      this.getProducts();
      this.sumCartProducts();
      this.getCartLength();
    });
  }

  public addToCart(apteka: BzApteka): void {
    const currentState: string = this.cartLocalStorageService.cart;
    let currentObjects: BzApteka[];
    currentObjects = currentState.length > 0 ? JSON.parse(currentState) : [];
    const pharmaciesIds: Set<number> = new Set();
    currentObjects.forEach((element: BzApteka) => pharmaciesIds.add(element.id));
    if (!pharmaciesIds.has(apteka.id)) {
      currentObjects.unshift(apteka);
    } else {
      const idx: number = currentObjects.findIndex(element => element.id === apteka.id);
      const newProducts: Map<number, BzProduct> = new Map();
      [...currentObjects[idx].products, ...apteka.products].forEach(element => newProducts.set(element.id, element));
      currentObjects[idx].products = Array.from(newProducts.values());
      currentObjects[idx].productTotalNum = currentObjects[idx].products.length;
    }
    this.cartLocalStorageService.cart = JSON.stringify(currentObjects);
    this.sumCartProducts();
    this.getCartLength();
  }

  public addProductToCart(apteka: BzApteka, productId: number): void {
    this.getObservableFromLocalStorage('cart').pipe(take(1)).subscribe((data: string) => {
      const parsedData: BzApteka[] = data.length > 0 ? JSON.parse(data) : [];
      const idsSet: Set<number> = new Set(parsedData.map(apteka => apteka.id));
      if (!idsSet.has(apteka.id)) {
        parsedData.push({ ...apteka, products: apteka.products.filter(i => i.id === productId) });
      } else {
        const idx: number = parsedData.findIndex(element => element.id === apteka.id);
        const newList = apteka.products.filter(element => element.id === productId);       // оставить в массиве входящей аптеки только продукт на которой нажали (добавили в корзину)
        parsedData[idx].products = [...parsedData[idx].products, ...newList];              // объеденить в один массив текущие и входящие товары
        const productsMap: Map<number, BzProduct> = new Map();                             // собрать в мапу только уникальные продукты
        parsedData[idx].products.forEach(element => {
          productsMap.set(element.id, element);
        });
        parsedData[idx].products = Array.from(productsMap.values());                       // новый массив уникальных продуктов из мапы
        parsedData[idx].productTotalNum = parsedData[idx].products.length;
      }
      this.cartLocalStorageService.cart = JSON.stringify(parsedData);
      this.sumCartProducts();
      this.getCartLength();
    });
  }

  public deleteFromCart(id: number): void {
    const currentState: string = this.cartLocalStorageService.cart;
    let currentObjects: BzApteka[];
    currentObjects = currentState.length > 0 ? JSON.parse(currentState) : [];
    const idx: number = currentObjects.findIndex(element => element.id === id);
    currentObjects.splice(idx, 1);
    this.cartLocalStorageService.cart = JSON.stringify(currentObjects);
    this.getProducts();
    this.getCartLength();
    this.sumCartProducts();
  }

  public clearCart(): void {
    this.cartLocalStorageService.cart = '[]';
    this.getProducts();
    this.getCartLength();
    this.sumCartProducts();
  }

  public getList(): void {
    this.getObservableFromLocalStorage('list')
      .pipe(
        take(1)
      )
      .subscribe((data: string) => {
        const productsArray: BzProduct[] = data.length > 0 ? JSON.parse(data) : [];
        this.listSubject.next(productsArray);
      });
  }

  public getListLength(): void {
    this.getObservableFromLocalStorage('list')
      .pipe(
        take(1)
      )
      .subscribe((data: string) => {
        const productsArray: BzProduct[] = data.length > 0 ? JSON.parse(data) : [];
        this.listCountSubject.next(productsArray.length);
      });
  }

  public getCartLength(): void {
    this.getObservableFromLocalStorage('cart').pipe(take(1)).subscribe((data: string) => {
      let result = 0;
      const arrayPharmacies: BzApteka[] = data.length > 0 ? JSON.parse(data) : [];
      if (arrayPharmacies.length > 0) {
        const result1 = arrayPharmacies.map(item => {
          if (item?.products?.length) {
            return item.products.length
          }
        }).filter(e => e);
        result = result1.reduce((a, c) => a + c);
      }
      this.cartCountSubject.next(result);
    });
  }

  public sumProductsList(): void {
    let sum: number;
    this.getObservableFromLocalStorage('list')
      .pipe(
        take(1)
      )
      .subscribe((data: string) => {
        const arr: number[] = [];
        const productsArray: BzProduct[] = data.length > 0 ? JSON.parse(data) : [];
        productsArray.forEach((element: BzProduct) => {
          if (element.middle_p) {
            arr.push((element.middle_p * element.packageNum) + (element.priceBlister * element.blisterNum));
          } else {
            this.isListHasProductWithoutMiddlePrice.next(true);
            arr.push(0);
          }
        });
        sum = arr.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
        this.listSumSubject.next(sum);
      });
  }

  public sumCartProducts(): void {
    let sum: number;
    let economy: number;
    this.getObservableFromLocalStorage('cart').pipe(take(1)).subscribe((data: string) => {
      const arrayPharmacies: BzApteka[] = data.length > 0 ? JSON.parse(data) : [];
      const sumArr: number[] = [];
      const economArr: number[] = [];
      arrayPharmacies.forEach((apteka: BzApteka) => {
        if (apteka.products) {
          apteka.products.forEach((product: BzProduct) => {
            sumArr.push(product.price);                      // подсчет общей суммы товаров в корзине
            economArr.push(product.totalEconomy);            // подсчет общей суммы экономии
          });
        }
      });

      economy = economArr.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
      sum = sumArr.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
      this.cartSumSubject.next(sum);
      this.cartEconomySubject.next(economy);
    });
  }

  public incrementNumberOfPackagesInCart(apteka: BzApteka, product: BzProduct): void {
    this.getObservableFromLocalStorage('cart').pipe(
      take(1)
    ).subscribe((data: string) => {
      const parsedData: BzApteka[] = data.length > 0 ? JSON.parse(data) : [];
      const idx: number = parsedData.findIndex(element => element.id === apteka.id);
      parsedData[idx].products.forEach((singleProduct: BzProduct) => {
        if (singleProduct.id === product.id) {
          if (singleProduct.packageNum < singleProduct.balancePackage) {
            singleProduct.packageNum++;
            singleProduct.totalEconomy = (singleProduct.packageNum * singleProduct.priceEconomy) + (singleProduct.blisterNum * singleProduct.priceEconomyBlister);
            singleProduct.price += singleProduct.priceSingle;
            if (singleProduct.packageNum > singleProduct.balanceNum) {      // проверить текущее количество (после добавления) с максимальным
              singleProduct.packageNum--;                                   // и если количество упаковок привышает максимум, то отнять одну упаковку
              singleProduct.totalEconomy = (singleProduct.packageNum * singleProduct.priceEconomy) + (singleProduct.blisterNum * singleProduct.priceEconomyBlister);
              singleProduct.price -= singleProduct.priceSingle;
            }
          }
        }
      });
      this.cartLocalStorageService.cart = JSON.stringify(parsedData);
      this.sumCartProducts();
      this.getProducts();
    });
  }

  public incrementNumberOfPackagesInList(product: BzProduct): void {
    const currentState: string = this.listLocalStorageService.list;
    let currentObjects: BzProduct[];
    currentObjects = currentState.length > 0 ? JSON.parse(currentState) : [];
    const idx: number = currentObjects.findIndex(element => element.id === product.id);
    currentObjects[idx].packageNum++;

    this.listLocalStorageService.list = JSON.stringify(currentObjects);

    this.getList();
    this.sumProductsList();
    this.getProducts();
  }

  public incrementNumberOfBlistersInList(product: BzProduct): void {
    const currentState: string = this.listLocalStorageService.list;
    let currentObjects: BzProduct[];
    currentObjects = currentState.length > 0 ? JSON.parse(currentState) : [];
    const idx: number = currentObjects.findIndex(element => element.id === product.id);
    currentObjects[idx].blisterNum++;
    if (currentObjects[idx].blisterNum >= currentObjects[idx].packageContentNum) {
      currentObjects[idx].packageNum++;
      currentObjects[idx].blisterNum = 0;
    }

    this.listLocalStorageService.list = JSON.stringify(currentObjects);

    this.getList();
    this.sumProductsList();
  }

  public incrementNumberOfBlistersInCart(apteka: BzApteka, product: BzProduct): void {
    this.getObservableFromLocalStorage('cart').pipe(
      take(1)
    ).subscribe((data: string) => {
      const parsedData: BzApteka[] = data.length > 0 ? JSON.parse(data) : [];
      const idx: number = parsedData.findIndex(element => element.id === apteka.id);
      parsedData[idx].products.forEach((element: BzProduct) => {
        if (element.id === product.id) {
          if (element.packageNum < element.balancePackage) {              // если количество пачек меньше максимального количества в аптеке можем увеличивать количество блистеров
            element.blisterNum++;
          }
          if (element.blisterNum >= element.packageContentNum) {          // если количество блистеров ровно или больше максимальному количеству блистеров в упаковке,
            element.blisterNum = 0;                                       // то сбросить текущее количество блистеров до 0 и увеличить количество пачек на 1
            element.packageNum++;
            if (element.packageNum <= element.balanceNum) {
              element.price = (element.packageNum * element.priceSingle) + (element.blisterNum * element.priceBlister);
              element.totalEconomy = (element.packageNum * element.priceEconomy) + (element.blisterNum * element.priceEconomyBlister);
            }
            return;
          }

          if (element.blisterNum < element.packageContentNum) {
            if (element.blisterNum < element.balanceBlister && element.packageNum == element.balancePackage) {
              element.blisterNum++;
            }
            element.price = (element.packageNum * element.priceSingle) + (element.blisterNum * element.priceBlister);
            element.totalEconomy = (element.packageNum * element.priceEconomy) + (element.blisterNum * element.priceEconomyBlister);
          }
        }
      });

      this.cartLocalStorageService.cart = JSON.stringify(parsedData);
      this.sumCartProducts();
      this.getProducts();
    });
  }

  public decrementNumberOfPackagesInCart(apteka: BzApteka, product: BzProduct): void {
    this.getObservableFromLocalStorage('cart').pipe(
      take(1)
    ).subscribe((data: string) => {
      const parsedData: BzApteka[] = data.length > 0 ? JSON.parse(data) : [];
      const idx: number = parsedData.findIndex(element => element.id === apteka.id);
      parsedData[idx].products.forEach((element: BzProduct) => {
        if (element.id === product.id) {
          if (element.packageNum > 0) {
            element.packageNum--;
            if (element.packageNum < 0) {
              element.packageNum += 1;
              return;
            }
            element.totalEconomy = (element.packageNum * element.priceEconomy) + (element.blisterNum * element.priceEconomyBlister);
            element.price -= element.priceSingle;
          }
          if (element.packageNum == 0 && element.blisterNum == 0) {
            if (element.packageContentNum !== 1) {
              element.blisterNum = 1;
            } else {
              element.packageNum = 1;
            }
            element.totalEconomy = (element.packageNum * element.priceEconomy) + (element.blisterNum * element.priceEconomyBlister);
            element.price = (element.packageNum * element.priceSingle) + (element.blisterNum * element.priceBlister);
          }
        }
      });

      this.cartLocalStorageService.cart = JSON.stringify(parsedData);
      this.sumCartProducts();
      this.getProducts();
    });
  }

  public decrementNumberOfBlistersInCart(apteka: BzApteka, product: BzProduct): void {
    this.getObservableFromLocalStorage('cart').pipe(
      take(1)
    ).subscribe((data: string) => {
      const parsedData: BzApteka[] = data.length > 0 ? JSON.parse(data) : [];
      const idx: number = parsedData.findIndex(element => element.id === apteka.id);
      parsedData[idx].products.forEach((element: BzProduct) => {
        if (element.id === product.id) {
          if (element.blisterNum > 0 && element.packageNum > 0) {
            element.blisterNum--;
          } else {
            if (element.packageNum > 0) {
              element.packageNum--;
              element.blisterNum = element.packageContentNum - 1;
            } else if (element.packageNum == 0 && element.blisterNum > 1) {
              element.blisterNum--;
            }
          }
          element.price = (element.packageNum * element.priceSingle) + (element.blisterNum * element.priceBlister);
          element.totalEconomy = (element.packageNum * element.priceEconomy) + (element.blisterNum * element.priceEconomyBlister);
        }
      });
      this.cartLocalStorageService.cart = JSON.stringify(parsedData);
      this.sumCartProducts();
      this.getProducts();
    });
  }

  public decrementNumberOfPackagesInList(product: BzProduct): void {
    const currentState: string = this.listLocalStorageService.list;
    let currentObjects: BzProduct[];
    currentObjects = currentState.length > 0 ? JSON.parse(currentState) : [];
    const idx: number = currentObjects.findIndex(element => element.id === product.id);
    if (currentObjects[idx].packageNum > 0) {
      currentObjects[idx].packageNum--;
      if (currentObjects[idx].packageNum < 0) return;
      if (currentObjects[idx].packageNum == 0 && currentObjects[idx].blisterNum == 0) {
        if (currentObjects[idx].packageContentNum == 1) {
          currentObjects[idx].packageNum = 1;
        } else {
          currentObjects[idx].blisterNum = 1;
        }
      }
      if (currentObjects[idx].packageNum == 0 && currentObjects[idx].packageContentNum == 1) {
        currentObjects[idx].packageNum = 1;
      }
    }
    this.listLocalStorageService.list = JSON.stringify(currentObjects);
    this.getList();
    this.sumProductsList();
  }

  public decrementNumberOfBlistersInList(product: BzProduct): void {
    const currentState: string = this.listLocalStorageService.list;
    let currentObjects: BzProduct[];
    currentObjects = currentState.length > 0 ? JSON.parse(currentState) : [];
    const idx: number = currentObjects.findIndex(element => element.id === product.id);
    if (currentObjects[idx].blisterNum >= 0) {
      if (currentObjects[idx].blisterNum == 0) {                                              // если количество блистеров равно 0, то отнять одну упаковку,
        if (currentObjects[idx].packageNum == 0) { return; }                                  // проверить на количество упаковок, не равно ли оно 0,
        currentObjects[idx].packageNum--;
        currentObjects[idx].blisterNum = currentObjects[idx].packageContentNum - 1;           // затем установить новое количество блистеров (максимальное минус один)
      } else {                                                                                // если количество блистеров больше 0, то отнять один блистер
        currentObjects[idx].blisterNum--;
        if (currentObjects[idx].packageNum < 1 && currentObjects[idx].blisterNum < 1) {       // если общее количество пачек меньше 1, то установить дэфолтный 1 блистер
          currentObjects[idx].blisterNum = 1;
        }
      }
    }

    this.listLocalStorageService.list = JSON.stringify(currentObjects);
    this.getList();
    this.sumProductsList();
  }

  public areThereAnyProductsFromThisPharmacyInTheShoppingCart(id: number): Observable<BzApteka> {
    return this.getObservableFromLocalStorage('cart').pipe(
      take(1),
      map((data: string) => {
        const parsedData: BzApteka[] = data.length > 0 ? JSON.parse(data) : [];
        const idx: number = parsedData.findIndex(element => element.id === id);
        if (idx == -1) {
          return {} as BzApteka;
        } else {
          return parsedData[idx];
        }
      })
    );
  }

  public addProductTolist(product: BzProduct): void {
    const currentState: string = this.listLocalStorageService.list;
    let currentObjects: BzProduct[];
    currentObjects = currentState.length > 0 ? JSON.parse(currentState) : [];
    const productsIds: Set<number> = new Set();
    currentObjects.forEach(element => productsIds.add(element.id));
    if (!productsIds.has(product.id)) {
      currentObjects.unshift(product);
    } else {
      const sameId: number = currentObjects.findIndex(element => element.id === product.id);
      currentObjects.splice(sameId, 1);
    }
    this.listLocalStorageService.list = JSON.stringify(currentObjects);
    this.getListLength();
    this.sumProductsList();
  }

  public deleteProductFromList(product: BzProduct): void {
    const currentState: string = this.listLocalStorageService.list;
    let currentObjects: BzProduct[];
    currentObjects = currentState.length > 0 ? JSON.parse(currentState) : [];
    const sameId: number = currentObjects.findIndex(element => element.id === product.id);
    currentObjects.splice(sameId, 1);
    if (currentObjects.some(product => product.middle_p === undefined)) {
      this.isListHasProductWithoutMiddlePrice.next(true);
    } else {
      this.isListHasProductWithoutMiddlePrice.next(false);
    }
    this.listLocalStorageService.list = JSON.stringify(currentObjects);
    this.getList();
    this.getListLength();
    this.sumProductsList();
  }

  public clearList(): void {
    this.listLocalStorageService.list = '[]';
    this.getList();
    this.getListLength();
    this.sumProductsList();
    this.isListHasProductWithoutMiddlePrice.next(false);
  }

  // User logic below //

  public setUID(uid: string): void {
    const key: string = KeysLS.id.toString();
    localStorage.setItem(key, uid);
  }

  public getUID(): string {
    const key: string = KeysLS.id.toString();
    return localStorage.getItem(key);
  }

  // Adding to Fovorite logic below //

  public getProductsFromFavorite(): Observable<string[]> {
    if (isPlatformBrowser(this.platformId)) {
      return this.getObservableFromLocalStorage('favorite').pipe(
        map((value: string) => value.split(','))
      );
    } else {
      return of([]);
    }
  }

  public getFavoriteIdSet(): Observable<Set<number>> {
    if (isPlatformBrowser(this.platformId)) {
      return this.getObservableFromLocalStorage('favorite').pipe(
        map((value: string) => {
          const idsSet: Set<number> = new Set();
          if (value) {
            const array: string[] = value.split(',');
            array.forEach(id => idsSet.add(+id));
          }
          return idsSet;
        })
      );
    } else { of(new Set()); }

  }

  public getCartPharmaIdsSet(): Observable<Set<number>> {
    return this.getObservableFromLocalStorage('cart').pipe(
      map((data: string) => {
        const parsedData: BzApteka[] = data.length > 0 ? JSON.parse(data) : [];
        const result: Set<number> = new Set();
        parsedData.forEach((element: BzApteka) => result.add(element.id));
        return result;
      })
    );
  }

  public addProductToFavorite(id: number): void {
    const currentState: string = this.favoriteLocalStorageService.favorite;
    let idsArray: string[];
    if (currentState.length > 0) {
      idsArray = currentState.split(',');
    } else {
      idsArray = [];
    }
    if (!idsArray.includes(id.toString())) {
      idsArray.unshift(id.toString());
      this.favoriteLocalStorageService.favorite = idsArray.join(',');
    } else {
      const sameId: number = idsArray.findIndex(idx => idx == id.toString());
      idsArray.splice(sameId, 1);
      this.favoriteLocalStorageService.favorite = idsArray.join(',');
    }
  }

  public getIdsArrayFromFavorite(): Observable<string[]> {
    return this.getObservableFromLocalStorage('favorite').pipe(
      map((value: string) => value.split(','))
    );
  }

  // Adding to viewed logic below //

  public addProductToViewed(id: number): void {
    const currentState: string = this.viewedLocalStorageService.viewed;
    let idsArray: string[];
    if (currentState.length > 0) {
      idsArray = currentState.split(',');
    } else {
      idsArray = [];
    }
    if (!idsArray.includes(id.toString())) {
      idsArray.unshift(id.toString());
      if (idsArray.length <= this.limitLengthViewed) {
        this.viewedLocalStorageService.viewed = idsArray.join(',');
      } else {
        idsArray.pop();
        this.viewedLocalStorageService.viewed = idsArray.join(',');
      }
    }
  }

  public getIdsArrayFromViewed(): Observable<string[]> {
    return this.getObservableFromLocalStorage('viewed').pipe(
      map((value: string) => value.split(','))
    );
  }

  // Order logic below //

  public setOrderToDb(apteka: BzApteka): void {
    const currentState: string = this.orderLocalStorageService.orderWithError;
    let currentObjects: BzApteka[];
    currentObjects = currentState.length > 0 ? JSON.parse(currentState) : [];
    currentObjects.push(apteka);
    this.orderLocalStorageService.orderWithError = JSON.stringify(currentObjects);
  }

  public deleteOneOrderFromDb(apteka: BzApteka): void {
    const currentState: string = this.orderLocalStorageService.orderWithError;
    let currentObjects: BzApteka[];
    currentObjects = currentState.length > 0 ? JSON.parse(currentState) : [];
    const sameId: number = currentObjects.findIndex(element => element.id === apteka.id);
    currentObjects.splice(sameId, 1);
    this.orderLocalStorageService.orderWithError = JSON.stringify(currentObjects);
  }

  public getOrderFromDb(): Observable<BzApteka[]> {
    return this.getObservableFromLocalStorage('orderWithError').pipe(
      map(data => data.length > 0 ? JSON.parse(data) : [])
    );
  }

  // history orders ID logic below

  public isOrderExistInHistory(id: string): boolean {
    const currentState: IOrderIdWithDate[] = JSON.parse(this.orderLocalStorageService.ordersHistoryIdsWithDate);
    if (currentState.some((order) => order.id === id)) {
      return true;
    }
    return false;
  }

  public setShareOrderToHistory(order: IOrderIdWithDate): void {
    let currentState: IOrderIdWithDate[] = JSON.parse(this.orderLocalStorageService.ordersHistoryIdsWithDate);
    currentState = [order, ...currentState];
    this.orderLocalStorageService.ordersHistoryIdsWithDate = JSON.stringify(currentState);
  }

  public setOrderIdToHistory(id: string): void {
    const order: IOrderIdWithDate = { id, timestamp: new Date().getTime() };
    let currentState: IOrderIdWithDate[] = JSON.parse(this.orderLocalStorageService.ordersHistoryIdsWithDate);

    if (currentState.every((order) => order.id !== id)) {
      currentState = [order, ...currentState];
      this.orderLocalStorageService.ordersHistoryIdsWithDate = JSON.stringify(currentState);
    }
  }

  public getHistoryOrders(): Observable<IOrderIdWithDate[]> {
    return combineLatest([
      this.getObservableFromLocalStorage('historyOrdersId'),
      this.getObservableFromLocalStorage('ordersHistoryIdsWithDate')
    ]).pipe(
      map(([historyOrdersId, ordersHistoryIdsWithDate]) => {
        const ordersWithoutDate: IOrderIdWithDate[] =
          historyOrdersId.length > 0 ? historyOrdersId.split(',').map((orderId) => ({ id: orderId, timestamp: null })) : [];
        const ordersWithDate: IOrderIdWithDate[] = JSON.parse(ordersHistoryIdsWithDate);
        return [...ordersWithDate, ...ordersWithoutDate];
      })
    );
  }

  public removeOrderFromHistory(id: string): void {
    let stateWithDates: IOrderIdWithDate[] = JSON.parse(this.orderLocalStorageService.ordersHistoryIdsWithDate);
    let stateWithoutDates: string = this.orderLocalStorageService.historyOrdersId;

    // Проверка старого стейта заказов без дат
    if (stateWithoutDates.length > 0) {
      const orderIds: string[] = stateWithoutDates.split(',').filter((orderId) => orderId !== id);
      this.orderLocalStorageService.historyOrdersId = orderIds.join(',');
    }

    // Проверка нового стейта заказов с датами
    if (stateWithDates.some((o) => o.id === id)) {
      stateWithDates = [...stateWithDates].filter((order) => order.id !== id);
      this.orderLocalStorageService.ordersHistoryIdsWithDate = JSON.stringify(stateWithDates);
    }
  }

  private getObservableFromLocalStorage(storeName: string): Observable<string> {
    try {
      const testKey = 'testKey';
      localStorage.setItem(testKey, testKey);
      localStorage.removeItem(testKey);
      return of(localStorage.getItem(storeName)).pipe(
        catchError(error => {
          throwError(new Error(error));
          return of('');
        }),
      );
    } catch (e) {
      return of(this.memory.get(storeName)).pipe(
        catchError(error => {
          throwError(new Error(error));
          return of('');
        }),
      );
    }
  }

}
