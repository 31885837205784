import { Injectable } from '@angular/core';
import { LocalStorageApiService } from './local-storage-api.service';
import { KeysLS } from '@enums';
import { MemoryStorageApiService } from './memory-storage-api.service';

@Injectable({
  providedIn: 'root'
})
export class OrderLocalStorageService extends LocalStorageApiService {
  public DEFAULT_VALUE = '';
  public KEY = [KeysLS.historyOrdersId, KeysLS.orderWithError, KeysLS.ordersHistoryIdsWithDate];

  constructor(
    private readonly memory: MemoryStorageApiService
  ) {
    super();
  }

  public get historyOrdersId(): string {
    if (this.isSupported()) {
      return this.getData(this.KEY[0]);
    } else {
      return this.memory.get(this.KEY[0]);
    }
  }

  public set historyOrdersId(value: string) {
    if (this.isSupported()) {
      this.setData(this.KEY[0], value);
    } else {
      this.memory.set(this.KEY[0], value);
    }
  }

  public get orderWithError(): string {
    if (this.isSupported()) {
      return this.getData(this.KEY[1]);
    } else {
      return this.memory.get(this.KEY[1]);
    }
  }

  public set orderWithError(value: string) {
    if (this.isSupported()) {
      this.setData(this.KEY[1], value);
    } else {
      this.memory.set(this.KEY[1], value);
    }
  }

  public get ordersHistoryIdsWithDate(): string {
    if (this.isSupported()) {
      return this.getData(this.KEY[2]);
    } else {
      return this.memory.get(this.KEY[2]);
    }
  }

  public set ordersHistoryIdsWithDate(value: string) {
    if (this.isSupported()) {
      this.setData(this.KEY[2], value);
    } else {
      this.memory.set(this.KEY[2], value);
    }
  }
}
