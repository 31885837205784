import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BzCitiesSearchComponent } from './bz-cities-search.component';
import { TranslocoModule } from '@ngneat/transloco';
import { SharedModule } from '@shared';

@NgModule({
    declarations: [BzCitiesSearchComponent],
    imports: [
        CommonModule,
        TranslocoModule,
        SharedModule,
    ],
    exports: [BzCitiesSearchComponent]
})
export class BzCitiesSearchModule { }
