import { AddAnalogsForPopupAction, AddInstructionForPopupAction, AddPermissionForPopupAction } from '../../store/actions';
import { Injectable } from '@angular/core';
import { AppState } from '@appStates';
import { Store } from '@ngrx/store';
import { LazyLoadComponentsService } from './lazy-load-components.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalPopupService {
  constructor(
    private lazy: LazyLoadComponentsService,
    private store: Store<AppState>
  ) { }

  public delPopup(event: PointerEvent): void {
    if (event) {
      this.lazy.clearContainerPopupGlobalContainer(document.getElementById('popupGlobalApp').firstChild);
      this.store.dispatch(AddInstructionForPopupAction({ instruction: null }));
      this.store.dispatch(AddAnalogsForPopupAction({ analogs: null }));
      this.store.dispatch(AddPermissionForPopupAction({ permission: null }));
    }
  }

}
