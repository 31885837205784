import { Action, createReducer, on } from '@ngrx/store';
import { adapterTopReserveProducts, initialStartPageState, StartPageState } from '../state/start.page.state';

import * as StartPageActions from '@actions';
import { BzProduct, SliderItem } from '@interfaces';
import { Update } from '@ngrx/entity';


const reducer = createReducer(
  initialStartPageState,
  on(
    StartPageActions.SetActiveSlideSliderAction, (state, action) => {
      return ({
        ...state,
        slides: Object.assign([], {
          ...state.slides,
          [action.bid]: {
            ...state.slides[action.bid],
            active: action.id
          }
        })
      })
    }
  ),
  on(
    StartPageActions.AddFavoritesBlocksInSliderAction, (state, actions) => {

      const buffer: SliderItem = { ...state.slides[1] };

      if (actions.favorites.length > 0) {
        buffer.slides = []
        actions.favorites.forEach((item, i) => {
          if (i < 4) {
            buffer.slides.push({
              id: i,
              active: i === 0,
              product: item
            })
          }
        })
      } else {
        buffer.slides = [{
          id: 0,
          img: 'maskot_s21.png',
          discription: 'Здесь будут Ваши избранные препараты, как только Вы их добавите сюда',
          active: true
        }]
      }

      return ({
        ...state,
        slides: Object.assign([], {
          ...state.slides,
          [1]: buffer
        })
      })
    }
  ),
  on(
    StartPageActions.AddViewedBlocksInSliderAction, (state, actions) => {

      const buffer: SliderItem = { ...state.slides[2] };

      if (actions.vieweds.length > 0) {
        buffer.slides = []
        actions.vieweds.forEach((item, i) => {
          if (i < 4) {
            buffer.slides.push({
              id: i,
              active: i === 0,
              product: item
            })
          }

        })
      } else {
        buffer.slides = [{
          id: 0,
          img: 'maskot_s22.png',
          discription: 'Здесь будут препараты которыми Вы интересовались',
          active: true
        }]
      }

      return ({
        ...state,
        slides: Object.assign([], {
          ...state.slides,
          [2]: buffer
        })
      })
    }
  ),
  on(
    StartPageActions.SetCurrentAdviceDayIdAction, (state, action) => {
      return ({
        ...state,
        adviceDayId: action.id
      })
    }
  ),
  on(
    StartPageActions.SetCurrentMostOftenGroupAction, (state, action) => {
      if (!action.id) return ({...state});
      return ({
        ...state,
        mostOftenGroup: action.id
      })
    }
  ),
  on(
    StartPageActions.SetAdviceDayTipsListAction, (state, action) => {
      return ({
        ...state,
        adviceDayList: action.list
      });
    }
  ),
  on(
    StartPageActions.SetMostOftenProductsAction, (state, action) => {
      return ({
        ...state,
        mostOftenList: adapterTopReserveProducts.setAll(action.data, state.mostOftenList)
      });
    }
  ),
  on(
    StartPageActions.AddOftenProductToListAction, (state, action) => {
      if (!action.product.key) {
        const products: BzProduct[] = Object.values(state.mostOftenList.entities);
        const ids: Set<number> = new Set();
        products.forEach(product => ids.add(product.id));
        if (ids.has(action.product.id)) {
          const idx: number = products.findIndex(product => product.id === action.product.id);
          const productToUpdate: Update<BzProduct> = {
            id: products[idx].key,
            changes: { inList: !state.mostOftenList.entities[products[idx].key].inList }
          };
          return ({
            ...state,
            mostOftenList: adapterTopReserveProducts.updateOne(productToUpdate, state.mostOftenList)
          });
        } else {
          return ({ ...state });
        }
      } else {
        const productToUpdate: Update<BzProduct> = {
          id: action.product.key,
          changes: { inList: !state.mostOftenList.entities[action.product.key].inList }
        };
        return ({
          ...state,
          mostOftenList: adapterTopReserveProducts.updateOne(productToUpdate, state.mostOftenList)
        });
      }
    }
  ),
  on(
    StartPageActions.AddOftenProductToFavoriteAction, (state, action) => {
      if (!action.product.key) {
        const products: BzProduct[] = Object.values(state.mostOftenList.entities);
        const ids: Set<number> = new Set();
        products.forEach(product => ids.add(product.id));
        if (ids.has(action.product.id)) {
          const idx: number = products.findIndex(product => product.id === action.product.id);
          const productToUpdate: Update<BzProduct> = {
            id: products[idx].key,
            changes: { isFavorite: !state.mostOftenList.entities[products[idx].key].isFavorite }
          };
          return ({
            ...state,
            mostOftenList: adapterTopReserveProducts.updateOne(productToUpdate, state.mostOftenList)
          });
        } else {
          return ({
            ...state
          })
        }
      } else {
        const productToUpdate: Update<BzProduct> = {
          id: action.product.key,
          changes: { isFavorite: !state.mostOftenList.entities[action.product.key].isFavorite }
        };
        return ({
          ...state,
          mostOftenList: adapterTopReserveProducts.updateOne(productToUpdate, state.mostOftenList)
        });
      }
    }
  ),
  on(
    StartPageActions.ToggleFavoriteOftenProductFromViewProducts, (state, action) => {
      const products: BzProduct[] = Object.values(state.mostOftenList.entities);
      const ids: number[] = products.map(product => product.id);
      if (ids.includes(action.product.id)) {
        const idx: number = products.findIndex(product => product.id === action.product.id);
        const productToUpdate: Update<BzProduct> = {
          id: products[idx].key,
          changes: { isFavorite: state.mostOftenList.entities[products[idx].key].isFavorite }
        };
        return ({
          ...state,
          mostOftenList: adapterTopReserveProducts.updateOne(productToUpdate, state.mostOftenList)
        })
      } else {
        return ({
          ...state
        });
      }
    }
  ),
  on(
    StartPageActions.UnmarkOftenProductListIconFromListPageAction, (state, action) => {
      if (Object.values(state.mostOftenList.entities).length === 0) {
        return ({
          ...state
        });
      }
      if (action.product.key) {
        const productToUpdate: Update<BzProduct> = {
          id: action.product.key,
          changes: { inList: false }
        };
        return ({
          ...state,
          mostOftenList: adapterTopReserveProducts.updateOne(productToUpdate, state.mostOftenList)
        });
      } else {
        const products: BzProduct[] = Object.values(state.mostOftenList.entities);
        const ids: Set<number> = new Set();
        products.forEach(product => ids.add(product.id));
        if (ids.has(action.product.id)) {
          const idx: number = products.findIndex(product => product.id === action.product.id);
          const productToUpdate: Update<BzProduct> = {
            id: products[idx].key,
            changes: { inList: !state.mostOftenList.entities[products[idx].key].inList }
          };
          return ({
            ...state,
            mostOftenList: adapterTopReserveProducts.updateOne(productToUpdate, state.mostOftenList)
          });
        } else {
          return ({ ...state });
        }
      }
    }
  ),
  on(
    StartPageActions.UnmarkAllOftenProductListIconFromListPageAction, (state) => {
      const productsToUnmark: Update<BzProduct>[] = Object.values(state.mostOftenList.entities).map((product: BzProduct) => {
        return { id: product.key, changes: { inList: false } };
      });

      if (productsToUnmark.length === 0) {
        return ({
          ...state
        });
      }

      return ({
        ...state,
        mostOftenList: adapterTopReserveProducts.updateMany(productsToUnmark, state.mostOftenList)
      });
    }
  ),
  on(
    StartPageActions.UnmarkFavoriteProductFromPrivatePageToHomePageAction, (state, action) => {
      if (Object.values(state.mostOftenList.entities).length === 0) {
        return ({
          ...state
        });
      }

      let products: BzProduct[] = Object.values(state.mostOftenList.entities);
      let current: BzProduct = products.filter(product => product.id == action.product.id)[0];

      if (current == undefined || null) {
        return ({
          ...state
        });
      }

      const productToUpdate: Update<BzProduct> = {
        id: current.key,
        changes: { isFavorite: false }
      };
      return ({
        ...state,
        mostOftenList: adapterTopReserveProducts.updateOne(productToUpdate, state.mostOftenList)
      });
    }
  ),
  on(
    StartPageActions.MarkAsFavoriteFromPrivateViewedPageToHomeTopReserveAction, (state, action) => {
      if (Object.values(state.mostOftenList.entities).length === 0) {
        return ({
          ...state
        });
      }

      let products: BzProduct[] = Object.values(state.mostOftenList.entities);
      let current: BzProduct = products.filter(product => product.id == action.product.id)[0];

      if (current == undefined || null) {
        return ({
          ...state
        });
      }

      const productToUpdate: Update<BzProduct> = {
        id: current.key,
        changes: { isFavorite: true }
      };
      return ({
        ...state,
        mostOftenList: adapterTopReserveProducts.updateOne(productToUpdate, state.mostOftenList)
      });
    }
  ),
  on(
    StartPageActions.SetCurrentSingleAdviceDayAction, (state, action) => {
      return ({
        ...state,
        singleAdviceDay: action.advice
      });
    }
  ),
  on(
    StartPageActions.SetAdviceStateWhenClickedFromAllAdvicePageAction, (state, action) => {
      return ({
        ...state,
        singleAdviceState: action.state
      });
    }
  ),
  on(
    StartPageActions.SetOftenKeysAction, (state, { keys }) => {
      return ({
        ...state,
        singleOftenKeys: keys
      });
    }
  ),
  on(
    StartPageActions.SetOneOftenProductsGroupAction, (state, action) => {
      return ({
        ...state,
        currentOftenCategory: action.group
      });
    }
  ),
  on(
    StartPageActions.SetAllAdviceTipsWithPageNumAction, (state, action) => {
      return ({
        ...state,
        allAdviceDayTips: action.data
      });
    }
  ),
  on(
    StartPageActions.SetCurrentAdvicesPageAction, (state, action) => {
      if (state.currentAdvicesPage === null) {
        return ({
          ...state,
          currentAdvicesPage: action.page
        });
      } else {
        return ({
          ...state
        });
      };
    }
  ),
  on(
    StartPageActions.AddOrDeleteProductToList, (state, action) => {

      if (state.currentOftenCategory == null) return ({ ...state });

      const idx: number = state.currentOftenCategory.products.findIndex((product: BzProduct) => product.id === action.id);

      if (idx === -1) return ({ ...state });

      let inListState: boolean = state.currentOftenCategory.products[idx].inList;

      const newProducts: BzProduct[] = Object.assign([], {
        ...state.currentOftenCategory.products,
        [idx]: {
          ...state.currentOftenCategory.products[idx],
          inList: !inListState
        }
      });

      return ({
        ...state,
        currentOftenCategory: Object.assign({}, {
          ...state.currentOftenCategory,
          products: newProducts
        })
      });
    }
  ),
  on(
    StartPageActions.UnmarkInListProductFromListPageAction, (state, action) => {
      if (state.currentOftenCategory == null) return ({ ...state });

      const idx: number = state.currentOftenCategory.products.findIndex((product: BzProduct) => product.id === action.id);

      if (idx === -1) return ({ ...state });

      const newProducts: BzProduct[] = Object.assign([], {
        ...state.currentOftenCategory.products,
        [idx]: {
          ...state.currentOftenCategory.products[idx],
          inList: false
        }
      });

      return ({
        ...state,
        currentOftenCategory: Object.assign({}, {
          ...state.currentOftenCategory,
          products: newProducts
        })
      })
    }
  ),
  on(
    StartPageActions.UnmarkAllInListProductsFromListPageAction, (state) => {
      if (state.currentOftenCategory == null) return ({ ...state });

      let currentState: BzProduct[] = Object.assign([], { ...state.currentOftenCategory.products });
      let newProducts: BzProduct[] = [];

      if (currentState.length == 0) {
        return ({
          ...state
        });
      } else {
        currentState.forEach((product: BzProduct) => {
          newProducts.push({ ...product, inList: false });
        });
      }

      return ({
        ...state,
        currentOftenCategory: Object.assign({}, {
          ...state.currentOftenCategory,
          products: newProducts
        })
      });
    }
  ),
  on(
    StartPageActions.AddOrDeleteProductToFavoriteAction, (state, action) => {
      if (state.currentOftenCategory == null) return ({ ...state });

      const idx: number = state.currentOftenCategory.products.findIndex((product: BzProduct) => product.id === action.id);

      if (idx === -1) return ({ ...state });

      let isFavoriteState: boolean = state.currentOftenCategory.products[idx].isFavorite;

      const newProducts: BzProduct[] = Object.assign([], {
        ...state.currentOftenCategory.products,
        [idx]: {
          ...state.currentOftenCategory.products[idx],
          isFavorite: !isFavoriteState
        }
      });

      return ({
        ...state,
        currentOftenCategory: Object.assign({}, {
          ...state.currentOftenCategory,
          products: newProducts
        })
      });
    }
  )

);

export function startPageReducer(state: StartPageState | undefined, action: Action): StartPageState {
  return reducer(state, action);
}
