<hr *ngIf="searchTargetStringSub$ | async" />

<div class="external-wrapper" [ngClass]="{'switcherInHeader': !headerLocation}">
  <div (click)="switchCatalogPopup()" class="btn-catalog-popup" [class.btn-catalog-popup-header]="!headerLocation">
    <span class="btn-catalog-text">{{ 'catalog' | transloco }}</span>
  </div>

  <div class="internal-wrapper"
    [ngClass]="{ 'internal-wrapper-for-header': headerLocation, 'floating-search': (headerLocation && isFocusSearch) }">
    <bz-input-search-wrapper-field id="inputSearchID"
      [ngClass]="{'bz-input-search-wrapper-field-header': !headerLocation, 'bz-input-search-wrapper-field-page': headerLocation }"
      [isPlaceholderVisible]="false">
      <div (click)="requestCustomSearch()" class="search-icon-wrapper"></div>
      <input #search class="input-search" type="search" autocomplete="off" id="livePrSearch" [maxlength]="100"
        [placeholder]="'startTypyForSearch' | transloco" [formControl]="inputSearch" (click)="clickInput(search.value)"
        (focusin)="focusIn()" (focusout)="focusOut()" (keypress)="keypressEnter($event)">
      <div *ngIf="inputSearch.value" class="clear-search" (click)="clearField()">
        <img src="../../../../assets/cancel.svg">
      </div>
    </bz-input-search-wrapper-field>

    <div *ngIf="(hs?.length > 0 || (listOptions | async).length > 0 && desktop_visible_droplist)"
      class="wrapper-search-result scrollbar" (bzOutside)="closePopUp($event)">
      <div *ngIf="hs && hs.length > 0" class="item-search-result">
        <h3 class="title mobile-title" [textContent]="'searchHistory' | transloco"></h3>
        <div class="element-section extra-line-bottom" *ngFor="let h_element of hs">
          <p (click)="historyChangeOption(h_element)" class="element" [textContent]="h_element.value"></p>
        </div>
      </div>
      <div class="item-search-result" *ngFor="let item of listOptions | async; let i = index">
        <h3 class="title" [class.mobile-title]="i === 0 && hs.length === 0" [textContent]="item.name | transloco"></h3>
        <div class="element-section extra-line-bottom" (click)="changeOption(element, item.id, item.name)"
          [class.with-image]="element.info === 'full'" *ngFor="let element of item.hints">
          <img *ngIf="element.picture" class="section-picture" [src]="imagesPath + element.picture + '.webp'">
          <img *ngIf="!element.picture && element.info === 'full'" class="section-picture"
            src="../../../../assets/no_photo.svg">
          <div class="subtitles-wrapper">
            <p class="element" [innerHTML]="element.colorName | safeHTML"></p>
            <p *ngIf="element.content" class="content-number">{{ element.content }}</p>
            <p *ngIf="element.producer" class="content-number">{{ element.producer }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="popup-wrapper" [ngClass]="{ 'popup-wrapper-disabled': popupVisible }"
      (bzOutside)="outsidePopupClick($event)">
      <ng-container *ngIf="reloadPopupVisible">
        <bz-catalog-popup (onClose)="switchCatalogPopup()"></bz-catalog-popup>
      </ng-container>
    </div>

    <div class="error-message">
      <span class="text">{{ errorMessage }}</span>
    </div>
  </div>

  <ng-container *ngIf="!headerLocation">
    <div class="faq-desktop">?</div>
  </ng-container>
</div>
