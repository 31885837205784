import { AppState } from '@appStates';
import { BzApteka } from '@interfaces';
import { createSelector } from '@ngrx/store';
import { OrderPageState } from '@states';

const selectOrderState = (state: AppState): OrderPageState => state.order;

export const selectSuccessPharmacies = createSelector(
    selectOrderState,
    (state: OrderPageState): BzApteka[] => Object.values(state.current.entities)
);

export const selectHistoryOrder = createSelector(
    selectOrderState,
    (state: OrderPageState): BzApteka[] => Object.values(state.history.entities)
);

export const selectErrorsOrder = createSelector(
    selectOrderState,
    (state: OrderPageState): BzApteka[] => Object.values(state.errors.entities)
);

export const selectArchivePageNum = createSelector(
    selectOrderState,
    (state: OrderPageState): number => state.historyPageNum
);
