import * as fromRouter from '@ngrx/router-store';
import { RouterStateUrl } from '../router/custom-serializer';

export interface StoreRootState {
  router: fromRouter.RouterReducerState<RouterStateUrl>;
}

export const initialNavigationState: NavigationState = {
    rout: {
        url : "/",
        params: {},
        queryParams: {},
        data: {}
    }
}

export interface NavigationState {
    rout: RouterStateUrl
}
