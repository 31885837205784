import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { BzProduct } from '@interfaces';
import { forkJoin, of } from 'rxjs';
import { AddFavoritesBlocksInSliderAction, LoadFavoriteProductsAction } from '../../../store/actions';
import { LocalStorageService } from '@core/services';
import { Store } from '@ngrx/store';
import { AppState } from '@appStates';
import { RestUrlService, TypeUrl } from './settings/rest-url.service';

@Injectable({
  providedIn: 'root'
})
export class FavoriteGoodsApiService {

  private readonly _url: string;

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: Object,
    private readonly http: HttpClient,
    private readonly localStorageService: LocalStorageService,
    private readonly store: Store<AppState>,
    private readonly urlRest: RestUrlService
  ) {
    this._url = 'https:' + this.urlRest.getUrl(TypeUrl.rest);
  }

  public getProductsFromFavorite(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.localStorageService.getIdsArrayFromFavorite().pipe(
        switchMap((ids: string[]) => {
          const params: HttpParams = new HttpParams().set('ids', ids.join());
          return this.http.get(this._url + '/api/user-goods', { params }).pipe(
            mergeMap((response: BzProduct[]) => forkJoin([of(response), this.localStorageService.getProductsFromList()])),
            map(([response, products]) => {
              const productsIdFromList: number[] = products.map((item: BzProduct) => item.id);
              const idsSet: Set<number> = new Set(productsIdFromList);
              response.forEach((element: BzProduct) => {
                element.isFavorite = true;
                idsSet.has(element.id) ? element.inList = true : element.inList = false;
              });
              return response;
            })
          )
        }),
        tap((products: BzProduct[]) => {
          this.store.dispatch(LoadFavoriteProductsAction({ products }));
          this.store.dispatch(AddFavoritesBlocksInSliderAction({ favorites: products }));
        })
      ).subscribe();
    }
  }

}
