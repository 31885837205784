import { Directive, ElementRef, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { fromEvent } from 'rxjs';
import { take } from 'rxjs/operators';

@Directive({
  selector: '[bzOutside]'
})
export class OutsideDirective implements OnInit {

  constructor(private elementRef: ElementRef) { }

  @Output() public bzOutside: EventEmitter<Event> = new EventEmitter();

  public captured: boolean = false;

  @HostListener('document: click', ['$event'])
  public onClick(event: Event): void {
    if (!this.captured) {
      return;
    }

    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.bzOutside.emit(event);
    }
  }

  public ngOnInit(): void {
    fromEvent(document, 'click', { capture: true })
      .pipe(take(1))
      .subscribe(() => {
        (this.captured = true)
      });
  }

}
