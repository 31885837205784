import { BzApteka } from '@interfaces';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export interface OrderPageState {
    current: EntityState<BzApteka>;
    history: EntityState<BzApteka>;
    historyPageNum: number,
    errors: EntityState<BzApteka>;
}

export const adapterOrderPharmacies: EntityAdapter<BzApteka> = createEntityAdapter<BzApteka>({
    selectId: (apteka: BzApteka) => apteka.order.id
});

export const adapterHistoryOrders: EntityAdapter<BzApteka> = createEntityAdapter<BzApteka>({
    selectId: (apteka: BzApteka) => apteka.order.id
});

export const adapterErrorsOrder: EntityAdapter<BzApteka> = createEntityAdapter<BzApteka>({
    selectId: (apteka: BzApteka) => apteka.id
});

export const initialOrderPageState: OrderPageState = {
    current: adapterOrderPharmacies.getInitialState([]),
    history: adapterHistoryOrders.getInitialState([]),
    historyPageNum: 1,
    errors: adapterErrorsOrder.getInitialState([])
};
