import { Injectable } from '@angular/core';
import { environment } from '@env';
import { UrlLocalStorageService } from '../../storage.services/url-local-storage.service';

export enum TypeUrl {
  rest = 'clients-rest.',
  img = 'img.',
  map = 'map.',
  location = 'address-rest.',
  websocket = 'socket-clients.'
}

@Injectable({
  providedIn: 'root'
})
export class RestUrlService {

  constructor(
    private readonly urlStorage: UrlLocalStorageService
  ) {
  }

  public setStorageUrl(): void {
    this.urlStorage.url = window.location.hostname;
  }

  private getStorageUrl(): string {
    return environment.restServer;
  }

  public getUrl(type: TypeUrl): string {
    return '//' + type + this.getStorageUrl();
  }
}
