import { Injectable } from '@angular/core';
import { KeysLS } from '@enums';

@Injectable({
  providedIn: 'root'
})
export abstract class LocalStorageApiService {
  abstract DEFAULT_VALUE: string | string[];
  abstract KEY: KeysLS | KeysLS[];

  public isSupported(): boolean {
    try {
      const testKey = 'testKey';
      localStorage.setItem(testKey, testKey);
      localStorage.removeItem(testKey);
      return true;
    } catch (e) {
      return false;
    }
  }

  public getData(key: string): string {
    return this.isSupported() ? localStorage.getItem(key) : null;
  }

  public setData(key: string, value: string): void {
    if (this.isSupported()) {
      localStorage.setItem(key, value);
    }
  }

  public removeData(key: string): void {
    if (this.isSupported()) { localStorage.removeItem(key); }
  }


}
