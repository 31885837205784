import { Action, createReducer, on } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { adapterViewedProducts, initialViewedPageState, ViewedPageState } from '@states';
import { BzProduct } from '@interfaces';
import * as ViewedActions from '@actions';


const reducer = createReducer(
  initialViewedPageState,
  on(
    ViewedActions.LoadViewedProductsAction, (state, action) => ({
      ...state,
      products: adapterViewedProducts.setAll(action.products, state.products)
    })
  ),
  on(
    ViewedActions.AddProductToListFromViewedAction, (state, action) => {
      const idsSet: Set<number | string> = new Set();
      state.products.ids.forEach((element: number | string) => idsSet.add(element));
      if (!idsSet.has(action.id)) {
        return ({
          ...state
        })
      };
      const productToUpdate: Update<BzProduct> = {
        id: action.id,
        changes: { inList: !state.products.entities[action.id].inList }
      };
      return ({
        ...state,
        products: adapterViewedProducts.updateOne(productToUpdate, state.products)
      })
    }
  ),
  on(
    ViewedActions.AddProductToFavoriteFromViewedAction, (state, action) => {
      const idsSet: Set<number | string> = new Set();
      state.products.ids.forEach((element: number | string) => idsSet.add(element));
      if (!idsSet.has(action.id)) {
        return ({
          ...state
        })
      };
      const productToUpdate: Update<BzProduct> = {
        id: action.id,
        changes: { isFavorite: !state.products.entities[action.id].isFavorite }
      };
      return ({
        ...state,
        products: adapterViewedProducts.updateOne(productToUpdate, state.products)
      })
    }
  ),
  on(
    ViewedActions.UnmarkListIconsFromListPageAction, (state) => {
      const productsToUnmark: Update<BzProduct>[] = Object.values(state.products.entities).map(product => {
        return { id: product.id, changes: { inList: false } };
      });
      return ({
        ...state,
        products: adapterViewedProducts.updateMany(productsToUnmark, state.products)
      })
    }
  ),
  on(
    ViewedActions.UnmarkListIconFromListPageAction, (state, action) => {
      const productToUpdate: Update<BzProduct> = {
        id: action.id,
        changes: { inList: false }
      };
      return ({
        ...state,
        products: adapterViewedProducts.updateOne(productToUpdate, state.products)
      })
    }
  )
);

export function viewedReducer(state: ViewedPageState | undefined, action: Action): ViewedPageState {
  return reducer(state, action);
}
