import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'bz-input-search-wrapper-field',
  templateUrl: './input-search-wrapper-field.component.html',
  styleUrls: ['./input-search-wrapper-field.component.sass']
})
export class InputSearchWrapperFieldComponent {

  @Output() public onClickField: EventEmitter<any> = new EventEmitter();
  @Input() public isPlaceholderVisible: boolean = true;

  public toClick() {
    this.onClickField.next(null);
  }

}
