import { ICity } from '@interfaces';
import { IFullAddressResponse, IFullAddress } from 'src/app/shared/interfaces/location/full-adress-response.model';

export interface LocationPageState {
  cities: ICity[];
  searchCitiesHints: ICity[];
  fullAdresses: IFullAddressResponse;
  address: IFullAddress;
}

export const initialLocationPageState: LocationPageState = {
  cities: [],
  searchCitiesHints: [],
  fullAdresses: null,
  address: null,
};
