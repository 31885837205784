import { Directive, HostListener } from '@angular/core';
import { LocalizationService, ScreenDetectedService } from '@core/services';
import { Languages } from '../enums/languages.enum';

@Directive({
  selector: '[translateToggler]'
})
export class TranslateDirective {

  constructor(
    private readonly locale: LocalizationService,
    private readonly screen: ScreenDetectedService
  ) { }

  @HostListener('click', ['$event']) public toggleTranslate(): void {
    if (this.screen.isDesktop()) {
      let language: string = this.locale.getLang();
      language = language === Languages.ru ? Languages.ua : Languages.ru;
      this.locale.setLang(language, true);
    }
  }

}
