import { Action, createReducer, on } from '@ngrx/store';
import { initialProductState, IProductState } from '@states';

import * as InfoDrugActions from '@actions';
import * as SearchActions from '../actions/search.actions';
import { BzProduct } from '@interfaces';

const reducer = createReducer(
  initialProductState,
  on(
    InfoDrugActions.LoadDrugInfosAction, (state, { product }) => ({
      ...state,
      product,
    })
  ),
  on(
    InfoDrugActions.SetAnalogsAction, (state, { products }) => ({
      ...state,
      analogs: products,
    })
  ),
  on(
    InfoDrugActions.SetSimilarProductsAction, (state, { products }) => ({
      ...state,
      similar: products,
    })
  ),
  on(
    InfoDrugActions.LoadFullInstraction, (state, { fullInstraction }) => ({
      ...state,
      fullInstraction,
    })
  ),
  on(
    InfoDrugActions.ProductToListAction, (state) => {
      const newProduct = { ...state.product, isList: !state.product.isList }
      return ({
        ...state,
        product: newProduct
      })
    }
  ),
  on(
    InfoDrugActions.SetLoadingAction, (state, { loading }) => {
      return ({
        ...state,
        loading,
      });
    }
  ),
  on(
    InfoDrugActions.ProductToFavoriteAction, (state) => {
      const newProduct = { ...state.product, isFavorite: !state.product.isFavorite }
      return ({
        ...state,
        product: newProduct
      })
    }
  ),
  on(
    InfoDrugActions.DeleteFromListInfoDrugAction, (state) => {
      const newProduct = { ...state.product, isList: false }
      return ({
        ...state,
        product: newProduct
      })
    }
  ),
  on(
    SearchActions.AddProductInListAction, (state, { product }) => {
      const productId = (state.analogs ?? []).findIndex((item: BzProduct) => item.id === product);
      if (productId === -1) return ({ ...state });
      return ({
        ...state,
        analogs: Object.assign([], {
          ...state.analogs,
          [productId]: {
            ...state.analogs[productId],
            inList: !state.analogs[productId].inList
          }
        }),
      });
    }
  ),
  on(
    SearchActions.AddProductInListAction, (state, { product }) => {
      const productId = (state.similar ?? []).findIndex((item: BzProduct) => item.id === product);
      if (productId === -1) return ({ ...state });
      return ({
        ...state,
        similar: Object.assign([], {
          ...state.similar,
          [productId]: {
            ...state.similar[productId],
            inList: !state.similar[productId].inList
          }
        })
      });
    }
  ),
  on(
    SearchActions.AddProductInFavoriteAction, (state, { id }) => {
      const productId = (state.analogs ?? []).findIndex((item: BzProduct) => item.id === id);
      if (productId === -1) return ({ ...state });
      return ({
        ...state,
        analogs: Object.assign([], {
          ...state.analogs,
          [productId]: {
            ...state.analogs[productId],
            isFavorite: !state.analogs[productId].isFavorite
          }
        })
      })
    }
  ),
  on(
    SearchActions.AddProductInFavoriteAction, (state, { id }) => {
      const productId = (state.similar ?? []).findIndex((item: BzProduct) => item.id === id);
      if (productId === -1) return ({ ...state });
      return ({
        ...state,
        similar: Object.assign([], {
          ...state.similar,
          [productId]: {
            ...state.similar[productId],
            isFavorite: !state.similar[productId].isFavorite
          }
        })
      })
    }
  ),
  on(
    SearchActions.DeleteProductsFromListAction, (state) => {
      const analogsProducts = [...state.analogs].map(product => ({ ...product, inList: false }));
      const similarProducts = [...state.similar].map(product => ({ ...product, inList: false }));
      return ({
        ...state,
        analogs: analogsProducts,
        similar: similarProducts,
      })
    }
  ),
  on(
    SearchActions.DeleteOneProductFromListAction, (state, { product }) => {
      const productId = (state.analogs ?? []).findIndex((item: BzProduct) => item.id == product);
      return ({
        ...state,
        analogs: Object.assign([], {
          ...state.analogs,
          [productId]: {
            ...state.analogs[productId],
            inList: false
          }
        })
      })
    }
  ),
  on(
    SearchActions.DeleteOneProductFromListAction, (state, { product }) => {
      const productId = (state.similar ?? []).findIndex((item: BzProduct) => item.id == product);
      return ({
        ...state,
        similar: Object.assign([], {
          ...state.similar,
          [productId]: {
            ...state.similar[productId],
            inList: false
          }
        })
      })
    }
  ),
);

export function productReducer(state: IProductState | undefined, action: Action): IProductState {
  return reducer(state, action);
}
