import { Action, createReducer, on } from '@ngrx/store';
import { GlobalAppState, initialGlobalAppState } from '@states';

import * as GlobalAppActions from '@actions';

const reducerGlobal = createReducer(
  initialGlobalAppState,
  on(
    GlobalAppActions.SetPopupVisibleChangeLocationAction, (state, action) => ({
      ...state,
      popupChangeLocation: action.visible
    })
  ),
  on(
    GlobalAppActions.SetVariantsLocationInPopupAction, (state, action) => ({
      ...state,
      variantsLocationInPopup: action.variants
    })
  ),
  on(
    GlobalAppActions.SetCurrentCityId, (state, action) => ({
      ...state,
      cityId: action.id
    })
  ),
  on(
    GlobalAppActions.SetCurrentUserTownWithCoords, (state, { town }) => ({
      ...state,
      userTown: town
    })
  ),
  on(
    GlobalAppActions.SetCurrentCityAction, (state, action) => ({
      ...state,
      cityName: action.city
    })
  ),
  on(
    GlobalAppActions.SetCurrenCityLabelSuccess, (state, action) => ({
      ...state,
      label: action.label
    })
  ),
  on(
    GlobalAppActions.LoadWorkingPanelMenuSuccess, (state) => ({
      ...state
    })
  ),
  on(
    GlobalAppActions.SetLangAppSuccess, (state, action) => ({
      ...state,
      lang: action.lang
    })
  ),
  on(
    GlobalAppActions.SetStatePrevPageMobileSuccess, (state, action) => ({
      ...state,
      sideMenu: {
        ...state.sideMenu,
        previousRout: action.rout
      }
    })
  ),
  on(
    GlobalAppActions.SetCurrentBlockMobilePageSuccess, (state, action) => ({
      ...state,
      sideMenu: {
        ...state.sideMenu,
        currentBlock: action.block
      }
    })
  ),
  on(
    GlobalAppActions.SetCurrentCitySuccess, (state, action) => ({
      ...state,
      city: action.city
    })
  ),
  on(
    GlobalAppActions.SetCurrentCoordSuccess, (state, action) => ({
      ...state,
      coord: action.coord
    })
  ),
  on(
    GlobalAppActions.SetVisiableBooleanAction, (state, action) => ({
      ...state,
      visible: action.visible
    })
  ),
  on(
    GlobalAppActions.SetCurrentTownEnAction, (state, { townEn }) => ({
      ...state,
      townEn
    })
  ),
  on(
    GlobalAppActions.SetIsGeoLocationDisabled, (state, { value }) => ({
      ...state,
      isGeoLocationDisabled: value,
    })
  )
);

export function globalAppReducer(state: GlobalAppState | undefined, action: Action): GlobalAppState {
  return reducerGlobal(state, action);
}

