import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { LocalizationService, SeoService } from '@core/services';
import { environment } from '@env';
import { map } from 'rxjs/operators';
import { Pages } from '@enums';
import { AbstractionCatalogMap, ICatalogJSON, ISecondLevel } from '@interfaces';

@Injectable({
  providedIn: 'root'
})
export class CiteMapGuard  {

  constructor(
    private readonly http: HttpClient,
    private readonly seo: SeoService,
    private readonly locale: LocalizationService
  ) {
  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | boolean {
    const lang: string = this.locale.getLang();
    return this.http.get('https:' + environment.staticIMG + `catalog/catalog_${lang}.json`).pipe(
      map(
        (catalogJSON: any[]) => {
          this.seo.getTegRobots(Pages.category);
          this.seo.updateLinkHreflang();

          const jcm: Map<string, AbstractionCatalogMap> = new Map();

          catalogJSON.forEach((item: ICatalogJSON) => {
            jcm.set(item.url, {
              ...item,
              child: [...item.categoryLvl2List]
            });
            item.categoryLvl2List.forEach((el: ISecondLevel) => {
              jcm.set(el.url, {
                ...el,
                main: [item],
                child: [...el.categoryLvl3List]
              });
              el.categoryLvl3List.forEach((lv: any) => {
                jcm.set(lv.url, {
                  ...lv,
                  child: null
                });
              });
            });
          });

          return jcm;
        }
      )
    );
  }

}
