import { ECurrentOrderBlock } from '@enums';
import { createAction, props } from '@ngrx/store';
import { ActiveMobileBlock } from 'src/app/pages/pharmacies/pharmacies.component';

export const PrivatePageChangeCurrentBlock = createAction(
  '[PrivatePage] Private Page Change Current Block',
  props<{ currentBlock: ECurrentOrderBlock }>()
);

export const PrivatePageExceptionReturnBtn = createAction(
  '[PrivatePage] Private Page Exception Return Btn',
  props<{ exception: string }>()
);

export const PharmaciesPageState = createAction(
  '[PharmaciesPage] Pharmacies Page Mobile State',
  props<{ currentState: ActiveMobileBlock }>()
);

export const IsRoutFromHomePage = createAction(
  '[PrivatePage] Is Rout To Private Page From Home Page',
  props<{ isFromHome: boolean }>()
);
