import { Action, createReducer, on } from '@ngrx/store';
import { adapterErrorsOrder, adapterHistoryOrders, adapterOrderPharmacies, initialOrderPageState, OrderPageState } from '@states';
import * as OrderActions from '@actions';
import { BzApteka } from '@interfaces';

const reducer = createReducer(
  initialOrderPageState,
  on(
    OrderActions.SetOrderPharmaciesAction, (state, action) => ({
      ...state,
      current: adapterOrderPharmacies.addOne(action.data, state.current)
    })
  ),
  on(
    OrderActions.CancelOrderAction, (state, action) => ({
      ...state,
      current: adapterOrderPharmacies.removeOne(action.id.toString(), state.current)
    })
  ),
  on(
    OrderActions.AddOneOrderAction, (state, action) => ({
      ...state,
      current: adapterOrderPharmacies.addOne(action.apteka, state.current)
    })
  ),
  on(
    OrderActions.AddOrdersToHistory, (state, action) => {
      const ordersState: BzApteka[] = Object.values(state.history.entities);
      const result: BzApteka[] = ordersState.concat(action.data);
      return ({
        ...state,
        history: adapterHistoryOrders.addMany(result, state.history)
      })
    }
  ),
  on(
    OrderActions.RemoveOrderFromHistory, (state, { orderId }) => {
      return ({
        ...state,
        history: adapterHistoryOrders.removeOne(orderId, state.history),
        current: adapterHistoryOrders.removeOne(orderId, state.current),
      })
    }
  ),
  on(
    OrderActions.CancelOneOrderAndArchiveAction, (state, action) => ({
      ...state,
      current: adapterHistoryOrders.updateOne({ id: action.apteka.order.id, changes: action.apteka }, state.current),
    })
  ),
  on(
    OrderActions.AddOrderWithErrorAction, (state, action) => ({
      ...state,
      errors: adapterErrorsOrder.addOne(action.pharm, state.errors)
    })
  ),
  on(
    OrderActions.DeleteOrderWithErrorsAction, (state, action) => ({
      ...state,
      errors: adapterErrorsOrder.removeOne(action.id, state.errors)
    })
  ),
  on(
    OrderActions.UpdateOrderAction, (state, action) => {
      return ({
        ...state,
        current: adapterOrderPharmacies.updateOne(
          { id: action.pharm.order.id, changes: action.pharm },
          state.current
        )
      });
    }
  ),
  on(
    OrderActions.ClearCurrentOrdersStateAction, (state) => {
      return ({
        ...state,
        current: adapterOrderPharmacies.removeAll(state.current)
      });
    }
  ),
  on(
    OrderActions.ClearHistoryOrdersStateAction, (state) => {
      return ({
        ...state,
        history: adapterHistoryOrders.removeAll(state.history)
      });
    }
  ),
  on(
    OrderActions.SetUpdatedOrderAction, (state, action) => {
      return ({
        ...state,
        current: adapterOrderPharmacies.setOne(action.order, state.current)
      });
    }
  ),
  on(
    OrderActions.IncrementHistoryPageNumAction, (state) => {
      const currentPageNum: number = state.historyPageNum;
      return ({
        ...state,
        historyPageNum: currentPageNum + 1
      });
    }
  ),
  on(
    OrderActions.ResetPageNumArchiveOrdersAction, (state) => {
      return ({
        ...state,
        historyPageNum: 0
      });
    }
  )
);

export function orderReducer(state: OrderPageState | undefined, action: Action): OrderPageState {
  return reducer(state, action);
}

