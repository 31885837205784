import { Action, createReducer, on } from '@ngrx/store';
import { initialPrivatePageState, PrivatePageState } from '../state/private.page.state';
import * as PrivatPageAction from '../actions/private-page.actions';


export const privatePageFeatureKey = 'privatePage';

const reducer = createReducer(
  initialPrivatePageState,
  on(PrivatPageAction.PrivatePageChangeCurrentBlock, (state, action) => ({
    ...state,
    currentBlok: action.currentBlock
  })),
  on(PrivatPageAction.PrivatePageExceptionReturnBtn, (state, action) => ({
    ...state,
    exception: action.exception
  })),
  on(PrivatPageAction.PharmaciesPageState, (state, action) => ({
    ...state,
    pharmaciesState: action.currentState
  })),
  on(PrivatPageAction.IsRoutFromHomePage, (state, action) => ({
    ...state,
    isRoutFromHomePage: action.isFromHome
  }))

);

export function privatePageReducer(state: PrivatePageState | undefined, action: Action): PrivatePageState {
  return reducer(state, action);
}
