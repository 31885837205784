import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { ListLazyComponents, TypeDevice } from '@enums';
import { SharedModule } from '@shared';
import { environment } from '@env';
import { LocalizationService, ScreenDetectedService } from '@core/services';
import { SwitchLocaleDesktopComponent } from './entryComponents/switch-locale-desktop/switch-locale-desktop.component';
import { LazyLoadComponentsService } from 'src/app/lazy-load-components-ui/factories/lazy-load-components.service';
import { WorkingPanelModule } from '../../blocks-ui/bz-working-panel/working-panel.component';
import { selectListoptionsDesktop } from '../../../store/selectors';
import { select, Store } from '@ngrx/store';
import { AppState } from '@appStates';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ButtonModule } from 'src/app/modules-ui/action-elements/bz-button/button.module';
import { ReturnBtnArrowModule } from '../../action-elements/bz-return-btn-arrow/return-btn-arrow.module';
import { InputSearchModule } from "../../action-elements/bz-input-search/input-search.component";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass']
})
export class HeaderComponent implements OnInit {
  @ViewChild('desktopSideMenu', { read: ViewContainerRef }) menu: ViewContainerRef;

  public environment = environment;

  public menuWP: Observable<any>;
  public menuWPwidth: string;
  public isHomePage: boolean = true;

  public typeDevice: typeof TypeDevice = TypeDevice;

  constructor(
    private readonly screenDetectedService: ScreenDetectedService,
    private readonly store: Store<AppState>,
    private readonly lazy: LazyLoadComponentsService,
    private readonly locale: LocalizationService,
    private readonly router: Router
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.menuWP = this.store.pipe(select(selectListoptionsDesktop));
      this.menuWPwidth = this.screenDetectedService.isDesktop() ? 'auto' : '100%';
    }, 0);

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      event.url === "/" ||
        this.router.url.split('?')[0] === "/" ||
        event.url === "/ua" ||
        this.router.url.split('?')[0] === "/ua"
        ? this.isHomePage = true
        : this.isHomePage = false;
    });
  }

  public clickLogo(): void {
    if (this.locale.getLang() == 'ru') {
      this.router.navigate(['/']);
    } else {
      this.router.navigate([`/${this.locale.getLang()}`]);
    }
  }

  public getHomePageLink(): string {
    return this.locale.getRouterLinkApp('');
  }

  public switchMenu(): void {
    if (!this.menu.length) {
      this.lazy.addCoreLazyComponent(
        this.menu, ListLazyComponents.menu);
    } else {
      this.lazy.clearContainer(this.menu);
    }

  }

}

@NgModule({
  declarations: [HeaderComponent, SwitchLocaleDesktopComponent],
  imports: [
    CommonModule,
    SharedModule,
    ReturnBtnArrowModule,
    ButtonModule,
    WorkingPanelModule,
    InputSearchModule,
    RouterModule
  ],
  exports: [HeaderComponent]
})
export class HeaderModule { }
