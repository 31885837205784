import { Action, createReducer, on } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { adapterFavoriteProducts, FavoritePageState, initialFavoritePageState } from '@states';
import { BzProduct } from '@interfaces';
import * as FavoriteActions from '@actions';


const reducer = createReducer(
  initialFavoritePageState,
  on(
    FavoriteActions.LoadFavoriteProductsAction, (state, action) => {
      return ({
        ...state,
        products: adapterFavoriteProducts.setAll(action.products, state.products)
      });
    }
  ),
  on(
    FavoriteActions.UnmarkFavoriteAction, (state, action) => {
      const id: string = action.id.toString();
      return ({
        ...state,
        products: adapterFavoriteProducts.removeOne(id, state.products)
      })
    }
  ),
  on(
    FavoriteActions.MarkFavoriteAction, (state, action) => {
      const productToAdd: BzProduct = { ...action.product, isFavorite: true };
      return ({
        ...state,
        products: adapterFavoriteProducts.addOne(productToAdd, state.products)
      });
    }
  ),
  on(
    FavoriteActions.InListToggleAction, (state, action) => {
      const idsSet: Set<number | string> = new Set();
      state.products.ids.forEach((element: number | string) => idsSet.add(element));
      if (!idsSet.has(action.id)) {
        return ({
          ...state
        });
      };
      const productToUpdate: Update<BzProduct> = {
        id: action.id,
        changes: { inList: !state.products.entities[action.id].inList }
      };
      return ({
        ...state,
        products: adapterFavoriteProducts.updateOne(productToUpdate, state.products)
      });
    }
  ),
  on(
    FavoriteActions.UnmarkInListProductFromListAction, (state, action) => {
      const productToUnmark: Update<BzProduct> = {
        id: action.id,
        changes: { inList: false }
      };
      return ({
        ...state,
        products: adapterFavoriteProducts.updateOne(productToUnmark, state.products)
      });
    }
  ),
  on(
    FavoriteActions.UnmarkInListProductsFromListAction, (state) => {
      const productsToUnmark: Update<BzProduct>[] = Object.values(state.products.entities).map((product: BzProduct) => {
        return { id: product.id, changes: { inList: false } };
      });
      return ({
        ...state,
        products: adapterFavoriteProducts.updateMany(productsToUnmark, state.products)
      });
    }
  ),
  on(
    FavoriteActions.IncrementPageStateAction, (state) => {
      return ({
        ...state,
        page: state.page + 1
      });
    }
  )
);

export function favoriteReducer(state: FavoritePageState | undefined, action: Action): FavoritePageState {
  return reducer(state, action);
}

