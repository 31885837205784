import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LocalizationService } from '../services/localization.service';

@Injectable()
export class CategoryRedirectInterceptor implements HttpInterceptor {

  constructor(
    private readonly router: Router,
    private readonly localizationService: LocalizationService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse) {
          const lang: string = this.localizationService.getLang();
          if (error.url.includes('/search-proposed/block-drug')) {
            if (error.status === 301) {
              if (lang === 'ru') {
                this.router.navigate(['category', error.error.correctCategory as string]);
              } else {
                this.router.navigate(['ua', 'category', error.error.correctCategory as string]);
              }
            }
            if (error.status === 404) {
              if (lang === 'ru') {
                this.router.navigate(['404'])
              } else {
                this.router.navigate(['ua', '404'])
              }
            }
          }
        }
        return of(error);
      })
    );
  }
}
