import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BzProductComponent } from './bz-product.component';
import { TranslocoModule } from '@ngneat/transloco';
import { SharedModule } from '@shared';
import { BzCountPackagingModule } from '../../action-elements/bz-count-packaging/bz-count-packaging.module';
import {NgxSkeletonLoaderModule} from 'ngx-skeleton-loader';
import {RouterModule} from '@angular/router';
import {ButtonModule} from '../../action-elements/bz-button/button.module';



@NgModule({
  declarations: [BzProductComponent],
  imports: [
    CommonModule,
    ButtonModule,
    BzCountPackagingModule,
    SharedModule,
    TranslocoModule,
    NgxSkeletonLoaderModule,
    RouterModule
  ],
  exports:[BzProductComponent]
})
export class BzProductModule { }
