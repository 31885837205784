import { Languages, ListLazyComponents } from '@enums';
import { createAction, props } from '@ngrx/store';
import { ICity } from '@interfaces';

export enum GlobalAppActions {
  LoadWorkingPanelMenu = '[Global] LoadWorkingPanelMenu',
  SetLangApp = '[Global] SetLangApp',
  SetStatePrevPageMobile = '[Global] SetRoutPrevForMobileBlock',
  SetStateIconMobileMenuBtn = '[Global] SetStateIconMobileMenuBtn',
  SetCurrentBlockMobilePage = '[Global] SetCurrentBlockMobilePage',
  SetVisibleBoolean = '[Global] SetVisibleBoolean',
  SetCurrenCity = '[Global] SetCurrenCity',
  SetCurrenCityLabel = '[Global] SetCurrenCityLabel',
  SetCurrentCoord = '[Global] SetCurrentCoord',
  SetPopupVisibleChangeLocation = '[Global] SetPopupVisibleChangeLocation',
  SetVariantsLocationInPopup = '[Global] SetVariantsLocationInPopup',
  SetCurrentCityId = '[Global] SetCurrentCityId',
  SetCurrentCity = '[Global] SetCurrentCity',
  SetCurrenCityFromUrl = '[Global] SetCurrenCityFromUrl',
  SetCurrentUserTownWithCoords = '[Global] SetCurrentUserTownWithCoords',
  SetCurrentTownEn = '[Global] SetCurrentTownEn',
  SetIsGeoLocationDisabled = '[Global] SetGeoLocationStatus'
}

export const SetVariantsLocationInPopupAction = createAction(
  GlobalAppActions.SetVariantsLocationInPopup,
  props<{variants: ICity[]}>()
);

export const SetPopupVisibleChangeLocationAction = createAction(
  GlobalAppActions.SetPopupVisibleChangeLocation,
  props<{visible: boolean}>()
);

export const SetCurrenCityLabelSuccess = createAction(
  GlobalAppActions.SetCurrenCityLabel,
  props<{label: string}>()
);

export const LoadWorkingPanelMenuSuccess = createAction(
  GlobalAppActions.LoadWorkingPanelMenu
);

export const SetStatePrevPageMobileSuccess = createAction(
  GlobalAppActions.SetStatePrevPageMobile,
  props<{ rout: string }>()
);

export const SetLangAppSuccess = createAction(
  GlobalAppActions.SetLangApp,
  props<{ lang: Languages | string }>()
);

export const SetCurrentBlockMobilePageSuccess = createAction(
  GlobalAppActions.SetCurrentBlockMobilePage,
  props<{ block: ListLazyComponents }>()
);

export const SetVisiableBooleanAction = createAction(
  GlobalAppActions.SetVisibleBoolean,
  props<{ visible: boolean }>()
);

export const SetCurrentCitySuccess = createAction(
  GlobalAppActions.SetCurrenCity,
  props<{ city: string }>()
);

export const SetCurrentCityId = createAction(
  GlobalAppActions.SetCurrentCityId,
  props<{ id: number }>()
);

export const SetCurrentUserTownWithCoords = createAction(
  GlobalAppActions.SetCurrentUserTownWithCoords,
  props<{ town: { townId: number, latitude: number, longitude: number } }>()
);

export const SetCurrentCityAction = createAction(
  GlobalAppActions.SetCurrentCity,
  props<{ city: string }>()
);

export const SetCurrentTownEnAction = createAction(
  GlobalAppActions.SetCurrentTownEn,
  props<{ townEn: string }>()
);

export const SetCurrentCoordSuccess = createAction(
  GlobalAppActions.SetCurrentCoord,
  props<{ coord: any[] }>()
);

export const SetIsGeoLocationDisabled = createAction(
  GlobalAppActions.SetIsGeoLocationDisabled,
  props<{ value: boolean }>()
);
