import { BzProduct } from '@interfaces';
import { createAction, props } from '@ngrx/store';
import { IProduct } from '@interfaces'

export enum InfoDrugActions {
  OpenInfoDragPage = '[InfoDrug] Open Info Drag Page',
  SetDrugInfo = '[InfoDrug] Set Drug Info',
  SetAnalogs = '[InfoDrug] Set Analogs',
  SetSimilar = '[InfoDrug] Set Similar',
  SetFullInstraction = '[InfoDrug] Set Full Instraction',
  ProductToList = '[InfoDrug] Add Product To List',
  ProductToFavorite = '[InfoDrug] Add Product To Favorite',
  DeleteFromList = '[InfoDrug] Delete From List',
  SetLoading = '[InfoDrug] Set Loading Status'
}

export const OpenInfoDragPageAction = createAction(
  InfoDrugActions.OpenInfoDragPage,
  props<{ idDrag: string }>()
)

export const SetLoadingAction = createAction(
  InfoDrugActions.SetLoading,
  props<{ loading: boolean }>()
);

export const LoadDrugInfosAction = createAction(
  InfoDrugActions.SetDrugInfo,
  props<{ product: IProduct }>()
);

export const SetAnalogsAction = createAction(
  InfoDrugActions.SetAnalogs,
  props<{ products: BzProduct[] }>()
);

export const SetSimilarProductsAction = createAction(
  InfoDrugActions.SetSimilar,
  props<{ products: BzProduct[] }>()
);

export const LoadFullInstraction = createAction(
  InfoDrugActions.SetFullInstraction,
  props<{ fullInstraction: string }>()
);

export const ProductToListAction = createAction(
  InfoDrugActions.ProductToList
);

export const ProductToFavoriteAction = createAction(
  InfoDrugActions.ProductToFavorite
);

export const DeleteFromListInfoDrugAction = createAction(
  InfoDrugActions.DeleteFromList
);
