import {
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef,
  Inject,
  Injectable,
  Injector,
  PLATFORM_ID,
  ViewContainerRef
} from '@angular/core';
import { ListLazyComponents } from '@enums';
import { ComponentType } from '@angular/cdk/overlay';
import { isPlatformBrowser } from '@angular/common';
import { UtilsLazyComponentService } from './utils-lazy-component.service';

@Injectable({
  providedIn: 'root'
})
export class LazyLoadComponentsService {

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private readonly componentFactoryResolver: ComponentFactoryResolver,
    private readonly injector: Injector
  ) { }


  public async addToGlobalPopup(component: ListLazyComponents, data?: unknown): Promise<ChildNode> {
    const popupContent: HTMLElement = document.getElementById('popupGlobalApp');

    this.clearContainerPopupGlobalContainer(popupContent.firstChild);
    let lazyComponent: ComponentType<any>;

    if (isPlatformBrowser(this.platformId)) {

      if (component === ListLazyComponents.description) {
        lazyComponent = await UtilsLazyComponentService.import(ListLazyComponents.fullInstruction);
      } else {
        lazyComponent = await UtilsLazyComponentService.import(component);
      }

      if (!lazyComponent || !popupContent) {
        return;
      }
    }

    const factory: ComponentFactory<any> = this.componentFactoryResolver.resolveComponentFactory(lazyComponent);
    const componentRef: ComponentRef<any> = factory.create(this.injector);

    if (data) {
      componentRef.instance.data = data;
    }

    popupContent.appendChild((componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0]);
    return popupContent.firstChild;
  }

  public clearContainerPopupGlobalContainer(node: ChildNode): void {
    if (node) {
      node.remove();
    }
  }

  public async addCoreLazyComponent(
    container: ViewContainerRef,
    component?: ListLazyComponents
  ): Promise<ComponentRef<any>> {
    let comp: ComponentType<any>;
    if (isPlatformBrowser(this.platformId)) {
      comp = await UtilsLazyComponentService.import(component);
    }
    if (!comp || !container) { return; }
    const componentRef: ComponentRef<any> = this.componentFactory(comp, container);
    componentRef.instance.container = container;
    return componentRef;
  }

  public clearContainer(container: ViewContainerRef): void {
    if (container) { container.clear(); }
  }

  private componentFactory(component: ComponentType<any>, container: ViewContainerRef): ComponentRef<any> {
    if (container) {
      container.clear();
    }

    if (component) {
      return container.createComponent(component);
    }
  }

}
