import { Injectable } from '@angular/core';
import { BzApteka, FilterListPharmcies, RequestParamsListPharmcy } from '@interfaces';
import { QuantityForOneProduct } from '@states';

@Injectable({
  providedIn: 'root'
})
export class PharmcyPageService {

  static getParamsForRequestList(param: any, solo: boolean = false, q: number = 1): RequestParamsListPharmcy[] {
    let result = new Array<RequestParamsListPharmcy>();
    if (!solo) {
      result = param.id.split(',').map((item: string) => {
        return {
          id: item.split('_')[0],
          packageNum: item.split('_')[1],
          blisterNum: item.split('_')[2]
        };
      });
    } else {
      result.push({ id: +param.id, quantity: +q });
    }
    return result;
  }

  static getDefaultFilters(param: any, filters: Map<string, FilterListPharmcies>): Map<string, FilterListPharmcies> {
    if (param.fif && param.fif === 'true') { filters.set('fif', { name: 'fif', active: true }); }
    else { filters.set('fif', { name: 'fif', active: false }); }
    if (param.enf && param.enf === 'true') { filters.set('enf', { name: 'enf', active: true }); }
    else { filters.set('enf', { name: 'enf', active: false }); }
    if (param.ds && param.ds === 'true') { filters.set('ds', { name: 'ds', active: true }); }
    else { filters.set('ds', { name: 'ds', active: false }); }
    if (param.ps && param.ps === 'true') { filters.set('ps', { name: 'ps', active: true }); }
    else { filters.set('ps', { name: 'ps', active: false }); }
    if (param.qs && param.qs === 'true') { filters.set('qs', { name: 'qs', active: true }); }
    else { filters.set('qs', { name: 'qs', active: false }); }
    return filters;
  }

  static getQuantityForOneProduct(a: BzApteka): QuantityForOneProduct {
    return {
      packageContentType: a?.products[0]?.packageContentType,
      packageContentNum: a?.products[0]?.packageContentNum,
      balanceNum: a?.products[0]?.balanceNum,
      qty: a.products[0]?.qty
    };
  }

  static allProductInCart(pharma: number[], cart: Set<number>): boolean {
    const result: boolean[] = [];
    pharma.forEach(item => result.push(cart.has(item)));
    return result.every(el => el);
  }

}
