import { Injectable } from '@angular/core';
import { ConcatParams, SearchPageParamsName } from './concat-params';

@Injectable({
  providedIn: 'root'
})
export class ConcatParamsService implements ConcatParams {

  // Help-сервис для конкатинации параметров для страницы результаты поиска и третьей категории каталога,
  // это происхоит при включении/выключении фильтров

  public concat(newParams: SearchPageParamsName, oldParams: SearchPageParamsName): SearchPageParamsName {
    const exeption: Set<string> = new Set(['sectionId', 'info', 'pStart', 'pEnd', 'pattern', 'tradeNameEn']);
    const concatfilterParamsMap: Map<string, Set<number>> = new Map();

    if (!newParams) {
      for (const key in oldParams) {
        if (!exeption.has(key)) {
          concatfilterParamsMap.set(key, new Set((oldParams[key].split(',')).map(item => +item)));
        }
      }
    }

    for (const key in newParams) {
      if (concatfilterParamsMap.has(key)) {
        const buffer = (newParams[key].split(',')).map((item: any) => +item);
        const value = new Set(concatfilterParamsMap.get(key));
        buffer.forEach((item: any) => value.add(item));
        concatfilterParamsMap.set(key, value);
      }
      else { concatfilterParamsMap.set(key, new Set((newParams[key].split(',')).map((item: any) => +item))); }
    }

    const allfilterParams = {};
    concatfilterParamsMap.forEach((item, i) =>
      allfilterParams[i] = (Array.from(item.values()).join(',')));

    return allfilterParams;
  }
}
