import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { isPlatformServer } from '@angular/common';
import { GeoLocationService } from '../services/positioning.services/geo-location.service';

@Injectable({
  providedIn: 'root'
})
export class PharmaciesListGuard  {

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: Object,
    private geo: GeoLocationService,
  ) { }

  resolve(): Observable<{ permissionStatus: PermissionStatus | null, isGeolocationDisabled: boolean, isList: true }> {
    return forkJoin([
      isPlatformServer(this.platformId) ? of(null) : this.geo.getPermissionStatus(),
    ]).pipe(
      map(([permissionStatus]: [PermissionStatus | null]) => {
        const result: { permissionStatus: PermissionStatus, isGeolocationDisabled: boolean, isList: true } = {
          permissionStatus,
          isGeolocationDisabled: !permissionStatus || permissionStatus.state !== 'granted',
          isList: true,
        }
        return result;
      })
    );
  }
}
