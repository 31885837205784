import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BzCountPackagingComponent } from './bz-count-packaging.component';
import { TranslocoModule } from '@ngneat/transloco';
import { SharedModule } from '@shared';



@NgModule({
  declarations: [BzCountPackagingComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    SharedModule
  ],
  exports: [BzCountPackagingComponent]
})
export class BzCountPackagingModule { }
