import { createAction, props } from '@ngrx/store';

export enum CatalogActions {
  setSelectedCategory = '[Catalog] SetSelectedCategory',
}

export const setSelectedCategoryAction = createAction(
  CatalogActions.setSelectedCategory,
  props<{ category: any }>()
);
