import { Injectable } from '@angular/core';
import { ListLazyComponents } from '@enums';
import { ComponentType } from '@angular/cdk/overlay';

@Injectable({
  providedIn: 'root'
})
export class UtilsLazyComponentService {

  static async import(component: ListLazyComponents): Promise<ComponentType<any>> {

    switch (component) {
      case ListLazyComponents.analogs: {
        const { AnalogsComponent } = await import('../ui/analogs/analogs.component');
        return AnalogsComponent;
      }
      case ListLazyComponents.cart: {
        const { CartComponent } = await import('../ui/cart/cart.component');
        return CartComponent;
      }
      case ListLazyComponents.dropMenuProduct: {
        const { DropdownMenuComponent } = await import('../ui/dropdown-menu/dropdown-menu.component');
        return DropdownMenuComponent;
      }
      case ListLazyComponents.filter: {
        const { FiltersBlockPagesComponent } = await import('../ui/filters-block-pages/filters-block-pages.component');
        return FiltersBlockPagesComponent;
      }
      case ListLazyComponents.list: {
        const { ListComponent } = await import('../ui/list/list.component');
        return ListComponent;
      }
      case ListLazyComponents.location: {
        const { LocationComponent } = await  import('../ui/location/location.component');
        return LocationComponent;
      }
      case ListLazyComponents.fullInstruction: {
        const { LongInstructionComponent } = await  import('../ui/long-instruction/long-instruction.component');
        return LongInstructionComponent;
      }
      case ListLazyComponents.map: {
        const { MapComponent } = await import('../ui/map/map.component');
        return MapComponent;
      }
      case ListLazyComponents.private: {
        const { PrivateComponent } = await import('../ui/private/private.component');
        return PrivateComponent;
      }
      case ListLazyComponents.search: {
        const { SearchComponent } = await import('../ui/search/search.component');
        return SearchComponent;
      }
      case ListLazyComponents.shortInstrution: {
        const { ShortInstructionComponent } = await import('../ui/short-instruction/short-instruction.component');
        return ShortInstructionComponent;
      }
      case ListLazyComponents.menu: {
        const { SideMenuComponent } = await  import('../ui/side-menu/side-menu.component');
        return SideMenuComponent;
      }
      case ListLazyComponents.permisions: {
        const { PermissionProductComponent } = await import('../ui/permission-product/permission-product.component');
        return PermissionProductComponent;
      }
      case ListLazyComponents.mobilePharmaciesFilters: {
        const { MobilePharmaciesFiltersComponent } = await import('../ui/mobile-pharmacies-filters/mobile-pharmacies-filters.component');
        return MobilePharmaciesFiltersComponent;
      }
    }
  }

}
