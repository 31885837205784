import { LoadDrugInfosAction, SetAnalogsAction, SetSimilarProductsAction } from '../../../store/actions';
import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { AppState } from '@appStates';
import { BzProduct, IProduct } from '@interfaces';
import { Store } from '@ngrx/store';
import { forkJoin, Observable, of } from 'rxjs';
import { LocalStorageService } from '../storage.services/local-storage.service';
import { InfoDrugApiService } from '../api.services/info-drug-api.service';

@Injectable({
  providedIn: 'root'
})
export class InfoDrugHelperService {

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: Object,
    private readonly infoDrugApiService: InfoDrugApiService,
    private readonly localStorageService: LocalStorageService,
    private readonly store: Store<AppState>
  ) { }

  public intoMergeMapHelper(product: IProduct): Observable<[
    IProduct, BzProduct[], BzProduct[], Set<number>, Set<number>
  ]> | Observable<[IProduct, BzProduct[], BzProduct[]]> {
    if (isPlatformBrowser(this.platformId)) {
      return forkJoin([
        of(product),
        this.infoDrugApiService.getAnalogs(product),
        this.infoDrugApiService.getSimilarProducts(product),
        this.localStorageService.getProductsIdSet(),
        this.localStorageService.getFavoriteIdSet()
      ]);
    } else {
      return forkJoin([of(product), this.infoDrugApiService.getAnalogs(product), this.infoDrugApiService.getSimilarProducts(product)]);
    }
  }

  public intoMapHelper(
    product: IProduct,
    similar: BzProduct[],
    analogs: BzProduct[],
    list: Set<number>,
    favorite: Set<number>
  ): { product: IProduct, analogs: BzProduct[], similar: BzProduct[] } {
    if (isPlatformBrowser(this.platformId)) {
      return {
        product: { ...product, isList: list.has(product.id), isFavorite: favorite.has(product.id) },
        analogs: analogs,
        similar: similar,
      };
    } else {
      return {
        product: product,
        analogs: analogs,
        similar: similar,
      };
    }
  }

  public setDataToNgRxStore(data: { product: IProduct, analogs: BzProduct[], similar: BzProduct[] }): void {
    this.store.dispatch(LoadDrugInfosAction({ product: data.product }));
    this.store.dispatch(SetAnalogsAction({ products: data.analogs }));
    this.store.dispatch(SetSimilarProductsAction({ products: data.similar }));
  }

}
