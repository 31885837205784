import { BzProduct } from '@interfaces';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';


export const adapterViewedProducts: EntityAdapter<BzProduct> = createEntityAdapter<BzProduct>();

export interface ViewedPageState {
    products: EntityState<BzProduct>
}

export const initialViewedPageState: ViewedPageState = {
    products: adapterViewedProducts.getInitialState([])
};