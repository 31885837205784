import { ICity } from '@interfaces';
import { createAction, props } from '@ngrx/store';
import { IFullAddressResponse, IFullAddress } from 'src/app/shared/interfaces/location/full-adress-response.model';

export enum LocationActions {
  SetCities = '[Location] Set Cities List',
  SetSearchCitiesHints = '[Location] Set Search Cities Hints',
  SetFullAddresses = '[Location] Set Full Addresses',
  SetAddresses = '[Location] Set Addresses',
}

export const SetCitiesAction = createAction(
  LocationActions.SetCities,
  props<{ cities: ICity[] }>()
);

export const SetSearchCitiesHintsAction = createAction(
  LocationActions.SetSearchCitiesHints,
  props<{ cities: ICity[] }>()
);

export const SetFullAddressesAction = createAction(
  LocationActions.SetFullAddresses,
  props<{ fullAddresses: IFullAddressResponse }>()
);

export const SetAddressAction = createAction(
  LocationActions.SetAddresses,
  props<{ address: IFullAddress }>()
);