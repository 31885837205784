export enum ListLazyComponents {
    menu = '../ui/side-menu/side-menu.component',
    shortInstrution = '../ui/short-instruction/short-instruction.component',
    fullInstruction = '../ui/long-instruction/long-instruction.component',
    analogs = '../ui/analogs/analogs.component',
    cart = '../ui/cart/cart.component',
    list = '../ui/list/list.component',
    private = '../ui/private/private.component',
    location = '../ui/location/location.component',
    search = '../ui/search/search.component',
    searchInput = '../../modules-ui/action-elements/bz-input-search/input-search.component',
    advice = '../ui/advice-day/advice-day.component',
    dropMenuProduct = '../ui/dropdown-menu/dropdown-menu.component',
    map = '../ui/map/map.component',
    filter = '../ui/filters-block-pages/filters-block-pages.component',
    description = '../ui/description/description',
    permisions = '../ui/permission-product/permission-product.component',
    toHomePageMobile = 'toHomePageMobile',
    mostOftenBookedCategory = '../ui/most-often/most-often.component',
    allAdvice = '../ui/all-advice/all-advice.component',
    mobilePharmaciesFilters = '../ui/mobile-pharmacies-filters/mobile-pharmacies-filters.component',
}
