import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { LocalizationService } from '@core/services';
import { RestUrlService, TypeUrl } from './settings/rest-url.service';

@Injectable({
  providedIn: 'root'
})
export class CatalogApiService {
  private readonly _url: string;

  constructor(
    private readonly http: HttpClient,
    private readonly locale: LocalizationService,
    private readonly urlRest: RestUrlService
  ) {
    this._url = 'https:' + this.urlRest.getUrl(TypeUrl.rest);
  }

  public getCatalog<T>(url: string): Observable<T> {
    const lang: string = this.locale.getLang();
    const urls: string = !!url ? '/api/' + lang + '/catalog/' + url : '/api/' + lang + '/catalog';
    return this.http.get<T>(this._url + urls);
  }

}
