import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { BreadcrumbList } from '../../../shared/interfaces/breadcrumb-list';
import { Crumb, IFaqResponse, IProduct, ISingleAdviceDay, IToSearchWithCategory } from '@interfaces';
import { LocalizationService } from '../localization.service';
import { environment } from '@env';

interface Product {
  description: string;
  name: string;
  image: string;
  sku: string;
  url: string;
  price: string;
}

export interface FAQPages {
  '@type': string;
  name: string;
  acceptedAnswer: {
    '@type': string;
    text: string;
  };
}

@Injectable({
  providedIn: 'root'
})
export class JsonLDServiceService {

  static scriptType = 'application/ld+json';
  static classArray = [
    'structured-data-faq',
    'structured-data-website',
    'structured-data-org',
    'structured-data-crumbs',
    'structured-data-product',
    'structured-data-article',
    'structured-data-company'
  ];

  // Статик методы для ld-json сео скриптов
  static Product = (product: IProduct, url: string) => ({
    '@context': 'https://schema.org',
    '@type': 'Product',
    description: product.productInfo?.manufacturer + ' (' + product?.productInfo?.country + ')' || '',
    name: product.name,
    image: product.image ? 'https:' + environment.staticIMG + '500-375/' + product.image + '.webp' : '',
    url: url,
    offers: [
      {
        '@type': 'AggregateOffer',
        priceCurrency: 'UAH',
        lowPrice: product.productInfo.priceRange ? product.productInfo.priceRange[0] : 0,
        highPrice: product.productInfo.priceRange ? product.productInfo.priceRange[1] : 0,
      }
    ]
  });

  static FAQPage = (fq: FAQPages[]) => ({
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: fq
  });

  static Breadcrambs = (itemListElement: BreadcrumbList[]) => ({
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: itemListElement
  });

  static Artice = (adviceTip: ISingleAdviceDay, imageSize: string) => ({
    '@context': 'https://schema.org',
    "@type": "NewsArticle",
    headline: adviceTip.title,
    image: [
      `https://img.zdorovi.ua/${imageSize}/sun_burn.webp`
    ],
    datePublished: adviceTip.datePublished,
    dateModified: adviceTip.dateModified,
    author: [
      {
        "@type": "Person",
        name: adviceTip.authorName,
        url: adviceTip.authorUrl,
      }
    ]
  });

  static WebSite = (applicationUrl: string) => ({
    "@context": "https://schema.org",
    "@type": "WebSite",
    "url": applicationUrl,
    "potentialAction": {
      "@type": "SearchAction",
      "target": {
        "@type": "EntryPoint",
        "urlTemplate": `${applicationUrl}/search/_?sectionId=6&info=null&pattern={search_term_string}&pStart=1&pEnd=1`
      },
      "query-input": "required name=search_term_string"
    }
  });

  static Company = (apiUrl: string, logoUrl: string) => ({
    "@context": "https://schema.org",
    "@type": "Organization",
    "logo": logoUrl,
    "name": "Здорові",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "проспект Героїв Харкова 199",
      "addressLocality": "Харків",
      "postalCode": "61000",
      "addressCountry": "UA"
    },
    "url": apiUrl,
    "telephone": "+38 050 234 51 70"
  });

  // Статик методы для мутации
  static mutationCrambs(crumbs: Crumb[]): BreadcrumbList[] {
    return crumbs.map((crumb: Crumb, index) => {
      if (crumb.url) {
        return {
          '@type': 'ListItem',
          position: index + 1,
          name: crumb.name,
          item: crumb.url,
        };
      } else {
        return {
          '@type': 'ListItem',
          position: index + 1,
          name: crumb.name,
        };
      }
    });
  }

  static mutationReferences(references: IToSearchWithCategory[]): BreadcrumbList[] {
    return references.map((ref: IToSearchWithCategory, index) => {
      return {
        '@type': 'ListItem',
        position: index + 1,
        name: ref.name ?? '',
        item: ref.url,
      }
    });
  }

  static mutationListFaqs(faqs: IFaqResponse[]): FAQPages[] {
    return faqs.map((faq: IFaqResponse) => {
      return {
        '@type': 'Question',
        'name': faq.question,
        'acceptedAnswer': {
          '@type': 'Answer',
          'text': faq.answer,
        }
      }
    });
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private readonly locale: LocalizationService,
  ) { }

  public remove(): void {
    const els = [];
    JsonLDServiceService.classArray.forEach(c => {
      els.push(...Array.from(this.document.head.getElementsByClassName(c)));
    });
    els.forEach(el => this.document.head.removeChild(el));
  }

  public add(schema: Record<string, any>, className = 'structured-data-website'): void {
    let script: any;
    let shouldAppend = false;
    if (this.document.head.getElementsByClassName(className).length) {
      script = this.document.head.getElementsByClassName(className)[0];
    } else {
      script = this.document.createElement('script');
      shouldAppend = true;
    }
    script.setAttribute('class', className);
    script.type = JsonLDServiceService.scriptType;
    script.text = JSON.stringify(schema);
    if (shouldAppend) {
      this.document.head.appendChild(script);
    }
  }

  public getSeoBreadCrumbsWithUrlField(crumbs: Crumb[], isInfoDrug: boolean = false): Crumb[] {
    return [
      {
        name: this.locale.getLang() === 'ru' ? 'Поиск лекарств' : 'Пошук ліків',
        url: this.locale.getLang() === 'ru' ? 'https://zdorovi.ua' : 'https://zdorovi.ua/ua',
        isHomeCrumb: true,
      },
      ...crumbs
    ].filter((item) => isInfoDrug ? item.info !== 'full' : item.info !== 'full' && item.info !== 'trade').map((crumb: Crumb) => {
      let redirectTo: string = '';
      if (crumb.info === '2' || crumb.info === '1') {
        redirectTo = 'catalog';
      } else if (crumb.info === '3') {
        redirectTo = 'category';
      } else if (crumb.info === 'trade') {
        redirectTo = 'list';
      }
      
      if (this.locale.getLang() === 'ru') {
        return {
          info: crumb.info,
          name: crumb.name,
          sectionId: crumb.sectionId,
          url: crumb.isHomeCrumb ? crumb.url : `${environment.baseUrl}/${redirectTo}/${crumb.url}`,
        }
      } else {
        return {
          info: crumb.info,
          name: crumb.name,
          sectionId: crumb.sectionId,
          url: crumb.isHomeCrumb ? crumb.url : `${environment.baseUrl}/ua/${redirectTo}/${crumb.url}`,
        }
      }
    });
  };

}
