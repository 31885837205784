import { isPlatformBrowser } from '@angular/common';
import { inject, Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { onLCP, onINP, onCLS, onFCP, onTTFB, CLSMetric, INPMetric, LCPMetric, FCPMetric, TTFBMetric } from 'web-vitals';
import { GoogleTagManagerService } from 'angular-google-tag-manager';


@Injectable({
    providedIn: 'root'
})
export class WebVitalsService {
  private gtmService = inject(GoogleTagManagerService);

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
    ) {}

    initWebVitals(): void {
        if (isPlatformBrowser(this.platformId)) {
          onCLS((metric) => this.sendToAnalytics(metric));
          onINP((metric) => this.sendToAnalytics(metric));
          onLCP((metric) => this.sendToAnalytics(metric));
          onFCP((metric) => this.sendToAnalytics(metric));
          onTTFB((metric) => this.sendToAnalytics(metric));
        }
    }

    private sendToAnalytics(metric: CLSMetric | INPMetric | LCPMetric | FCPMetric | TTFBMetric): void {
      // console.log(`Sending ${metric.name} to GTM`);
      this.gtmService.pushTag({
        event: 'web-vitals',
        event_category: 'Web Vitals',
        event_action: metric.name,
        value: Math.round(metric.value),
        event_label: metric.id,
        non_interaction: true,
        page_path: location.pathname,
      });
    }
}
