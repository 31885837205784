import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './routs/app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { TranslocoBrowserRootModule } from './transloco/transloco-browser-root.module';

import { CommonModule } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { BzSelectRegionModule } from './modules-ui/action-elements';
import { environment } from '@env';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { HotjarService } from './core/services/seo.services/hotjar.service';

// import * as Sentry from '@sentry/angular';

@NgModule({
  declarations: [
    AppComponent,
  ],
    imports: [
        // BrowserModule.withServerTransition({ appId: 'clients-ui' }),
        BrowserModule,
        CommonModule,
        CoreModule,
        TransferHttpCacheModule,
        TranslocoBrowserRootModule,
        AppRoutingModule,
        ToastrModule.forRoot(),
        BzSelectRegionModule,
        GoogleTagManagerModule.forRoot({
        id: environment.gtmIndex
      })
    ],
  providers: [
    provideClientHydration(),
    HotjarService
  //   {
  //   provide: ErrorHandler,
  //   useValue: Sentry.createErrorHandler({
  //     showDialog: true,
  //   }),
  // }, {
  //   provide: Sentry.TraceService,
  //   deps: [Router],
  // },
  //   {
  //     provide: APP_INITIALIZER,
  //     useFactory: () => () => {},
  //     deps: [Sentry.TraceService],
  //     multi: true,
  //   }
    ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
