import { TypeDevice } from '@enums';
import { BzProduct, IAdviceDay, IAllAdviceList, ISingleAdviceDay, ITopReserveSingleGroup, SliderItem } from '@interfaces';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { LangStorageService } from '../../core/services/storage.services/lang-storage.service';

export const adapterTopReserveProducts: EntityAdapter<BzProduct> = createEntityAdapter<BzProduct>({
    selectId: (product: BzProduct) => product.key
});

export interface StartPageState {
    slides: SliderItem[];
    adviceDayList: IAdviceDay[];
    adviceDayId: string;
    allAdviceDayTips: IAllAdviceList;
    currentAdvicesPage: number;
    mostOftenList: EntityState<BzProduct>;
    mostOftenGroup: string;
    singleAdviceDay: ISingleAdviceDay;
    singleOftenKeys: string[];
    currentOftenCategory: ITopReserveSingleGroup;
    singleAdviceState: boolean;
}

export const initialStartPageState: StartPageState = {
    slides: [
         {
            id: 0,
            category: 'howToOrder',
            name: 'Как заказать?',
            slides: [
                {
                    id: 0,
                    discription: 'typePreparatName',
                    img: LangStorageService.prototype.lang === 'ru' ? 'illustration_1_ru.svg' : 'illustration_1.svg',
                    active: true,
                    info: 'stepOne'
                }, {
                    id: 1,
                    discription: 'checkAvailable',
                    img: 'illustration_2.svg',
                    active: false,
                    info: 'stepTwo'
                }, {
                    id: 2,
                    discription: 'iconInList',
                    img: 'illustration_3.svg',
                    active: false,
                    info: 'stepThree'
                }, {
                    id: 3,
                    discription: 'selectPharmacy',
                    img: 'illustration_7.svg',
                    active: false,
                    info: 'stepFour'
                }, {
                    id: 4,
                    discription: 'checkCart',
                    img: 'illustration_4.svg',
                    active: false,
                    info: 'stepFive'
                }, {
                    id: 5,
                    discription: 'waitForSms',
                    img: 'illustration_5.svg',
                    active: false,
                    info: 'stepSix'
                }
            ],
            type: 'all',
            load: true,
            active: 0,
            desktopId: 1,
            visible: TypeDevice.all
        },
        {
            id: 1,
            category: 'Избранное',
            name: 'Избранное',
            slides: [
                {
                    id: 0,
                    img: 'maskot_s21.png',
                    discription: 'Здесь будут Ваши избранные препараты, как только Вы их добавите сюда',
                    active: true
                }
            ],
            type: 'product',
            load: true,
            active: 0,
            desktopId: 2,
            visible: TypeDevice.mobile
        },
        {
            id: 2,
            category: 'Ранее смотрели',
            name: 'Ранее смотрели',
            slides: [
                {
                    id: 0,
                    img: 'maskot_s22.png',
                    discription: 'Здесь будут препараты которыми Вы интересовались',
                    active: true
                }
            ],
            type: 'product',
            load: true,
            active: 0,
            desktopId: 3,
            visible: TypeDevice.mobile
        },
    ],
    adviceDayList: [],
    adviceDayId: null,
    allAdviceDayTips: null,
    currentAdvicesPage: null,
    mostOftenGroup: null,
    mostOftenList: adapterTopReserveProducts.getInitialState([]),
    singleAdviceDay: null,
    singleOftenKeys: [],
    currentOftenCategory: null,
    singleAdviceState: null
};
