import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpParams
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalizationService } from '@core/services';
import { CookiesServiceService } from '../services/storage.services/cookies-service.service';
import { isPlatformServer } from '@angular/common';
import { LocationStorageService } from '../services/storage.services/location-storage-service.service';

@Injectable()
export class PrimaryInterceptor implements HttpInterceptor {

  constructor(
    private readonly localize: LocalizationService,
    private readonly cook: CookiesServiceService,
    private readonly location: LocationStorageService,
    @Inject(PLATFORM_ID) private readonly platformId: Object
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.url.includes('/assets/i18n/')) {
      return next.handle(request);
    }

    let region: string;
    let townId: string;

    if (isPlatformServer(this.platformId)) {
      region = this.cook.getItem('label') ? this.cook.getItem('label') : 'kv';
      townId = this.cook.getItem('cityId') ? this.cook.getItem('cityId') : '1240';
    } else {
      region = this.location.label ? this.location.label : this.cook.getItem('label') ? this.cook.getItem('label') : 'kv';
      townId = this.location.cityId ? this.location.cityId : this.cook.getItem('label') ? this.cook.getItem('cityId') : '1240';
    }

    const filterParams: HttpParams = request.params.delete('region').delete('townId');

    const newParams: HttpParams = filterParams.append('region', region).append('townId', townId);

    const httpReq = request.clone({
      params: newParams.append('lang', this.localize.getLang())
    });

    return next.handle(httpReq);
  }

}
