import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  LoadDrugInfosAction,
  OpenInfoDragPageAction,
  SetAnalogsAction,
  SetSimilarProductsAction
} from '@actions';
import { catchError, mergeMap, of, switchMap, map } from 'rxjs';
import { InfoDrugApiService } from '../../core/services/api.services/info-drug-api.service';
import { IProduct} from '@interfaces';
import { InfoDrugHelperService } from '../../core/services/helpers/info-drug-helper.service';

@Injectable()
export class InfoDragEffects {
  constructor(
    private actions$: Actions,
    private apiInfoDrag: InfoDrugApiService,
    private helperDrag: InfoDrugHelperService) {
  }

  infoDragLoadEffects$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(OpenInfoDragPageAction),
        switchMap(({ idDrag }) => this.apiInfoDrag.getProductById(+idDrag).pipe(
            mergeMap((product: IProduct) => {
              return this.helperDrag.intoMergeMapHelper(product);
            }),
            map(([product, analogs, similar, list, favorite]) => {
              return this.helperDrag.intoMapHelper(product, similar, analogs, list, favorite);
            }),
            switchMap(({ product, analogs, similar}) => of(
              LoadDrugInfosAction({ product }),
              SetAnalogsAction({ products: analogs }),
              SetSimilarProductsAction({ products: similar })
            )),
            catchError(() => of((
              LoadDrugInfosAction({ product: null }),
              SetAnalogsAction({ products: [] }),
              SetSimilarProductsAction({ products: [] })
            )))
        ))
      );
    }
  );

}
