import { createSelector } from '@ngrx/store';
import * as fromRouter from '@ngrx/router-store';
import { AppState } from '@appStates';

export const getRouterState = (state: AppState) => {
  return state.router
};

export const getCurrentRouteState = createSelector(
    getRouterState,
    (state: fromRouter.RouterReducerState) => {
      return state
    }
  );