import { Action, createReducer, on } from '@ngrx/store';
import {adapterContacts, ContactsState, initialContactState} from '../state/contacts.page';
import * as ContactsActions from '../actions/contacts.actions';
import {OrderPageState} from '@states';

const reducer = createReducer(
  initialContactState,
  on(
    ContactsActions.UpdateContactsAction, (state, action) => {
      return adapterContacts.setAll(action.contacts, state);
    }
  )
);

export function contactsReducer(state: ContactsState | undefined, action: Action): ContactsState {
  return reducer(state, action);
}
