import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ICity } from '@interfaces';

@Component({
  selector: 'bz-cities-search',
  templateUrl: './bz-cities-search.component.html',
  styleUrls: ['./bz-cities-search.component.sass']
})
export class BzCitiesSearchComponent {
  @Input() searchCitiesHints: ICity[] = [];
  @Input() cityControl!: UntypedFormControl;
  @Input() currentLang!: string;
  @Input() addExtraPadding: boolean = true;

  @Output() onChangeCity: EventEmitter<{ city: ICity, fromHint: boolean }> = new EventEmitter();

  selectCityHint(city: ICity): void {
    this.onChangeCity.emit({ city, fromHint: true });
  }
}
