import { Component, Input, OnInit } from '@angular/core';
import { PlatformLocation } from '@angular/common';
import { ActivatedRoute, Params } from '@angular/router';
import { LocalizationService } from '@core/services';

@Component({
  selector: 'bz-btn-lang',
  templateUrl: './btn-lang.component.html',
  styleUrls: ['./btn-lang.component.sass']
})
export class BtnLangComponent implements OnInit {
  @Input() public lang: string;
  @Input() public animationBtnLang: string;

  public show: boolean = false;
  public params: Params;

  constructor(
    private readonly location: PlatformLocation,
    private readonly activatedRouter: ActivatedRoute,
    private readonly locale: LocalizationService
  ) { }

  ngOnInit(): void {
    this.activatedRouter.queryParams.subscribe((params: Params) => {
      this.params = params;
    });
  }

  public getLangLink(): string {
    if (this.locale.getLang() == 'ru') {
      return '/ua' + this.location.pathname;
    } else {
      return this.location.pathname.replace('/ua', '');
    }
  }


}
