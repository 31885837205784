import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MemoryStorageApiService {
  private MEMORY_STORAGE: Map<string, string> = new Map();

  public create(): void {
    this.MEMORY_STORAGE = new Map();
  }

  public get(key: string): string {
    if (!this.MEMORY_STORAGE.has(key)) { this.set(key, ''); }
    return (key && key.length > 0) ? this.MEMORY_STORAGE.get(key) : null;
  }

  public set(key: string, value: string): void {
    this.MEMORY_STORAGE.set(key, value);
  }

  public remove(key: string): void {
    if (key) {
      this.MEMORY_STORAGE.delete(key);
    }
  }
}
