import { AppState } from '@appStates';
import { BzProduct } from '@interfaces';
import { EntityState } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { adapterFavoriteProducts } from '@states';

const start: number = 0;
const limitInHomePage: number = 5;
const limitInCabinet: number = 6;

const selectPage = (state: AppState): number => state.favorite.page;
const selectFavoriteState = (state: AppState): EntityState<BzProduct> => state.favorite.products;

const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
} = adapterFavoriteProducts.getSelectors();

export const selectAllFavorites = () => createSelector(selectFavoriteState, selectAll);
export const selectFavoriteLength = () => createSelector(selectFavoriteState, selectTotal);
export const selectFavoriteDictionary = () => createSelector(selectFavoriteState, selectEntities);
export const selectFavoriteIds = () => createSelector(selectFavoriteState, selectIds);

export const selectSixProducts = createSelector(
    selectAllFavorites(),
    selectPage,
    (products: BzProduct[], page: number): BzProduct[] => products.slice(start, limitInCabinet * page)
);

export const selectFourProducts = createSelector(
    selectAllFavorites(),
    (products: BzProduct[]): BzProduct[] => products.slice(start, limitInHomePage)
);

export const selectAllFavoriteProducts = createSelector(
    selectAllFavorites(),
    (products: BzProduct[]): BzProduct[] => products
);
