import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '@appStates';
import { StartForNewDataPageService } from '../services/helpers/help-service-for-search-page/start-for-new-data-page.service';
import { AddPageCountLoadDataAction } from '../../store/actions';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CategoryGuard  {
  constructor(
    private readonly helperDataService: StartForNewDataPageService,
    private readonly store: Store<AppState>
  ) { }

  resolve(
    route: ActivatedRouteSnapshot
  ): Observable<boolean> {

    const isListPage: boolean = route.routeConfig.path.includes('list/');

    const url: string = isListPage ? '_' : route.params.url;
    const pattern: string = route.params.pattern;

    let page: string = route.params.page ? route.params.page.replace('page', '') : '1';

    this.store.dispatch(AddPageCountLoadDataAction({ pages: [+page, +page] }));

    let params = route.queryParams;

    if (!pattern) {
      params = {
        ...params,
        sectionId: route.routeConfig.path.includes('category') ? '2' : params.sectionId,
        info: route.routeConfig.path.includes('category') ? '3' : params.info,
        pStart: page,
        pEnd: page
      };
    }

    if (isListPage) {
      params = {
        ...params,
        sectionId: '1',
        info: 'trade',
        pattern: route.params.url,
        tradeNameEn: 'true',
        pStart: page,
        pEnd: page
      }
    }

    this.helperDataService.startDispatchAction(true, url ? url : '_', params, null);
    return of(true);
  }
}
