import { BzApteka } from '@interfaces';
import { createAction, props } from '@ngrx/store';

export enum OrderActions {
  SetOrderPharmacies = '[Order] SetOrderedPharmacies',
  CancelOrder = '[Order] CancelOrder',
  AddOneOrder = '[Order] AddOneOrder',
  AddOrdersToHistory = '[Order] AddOrdersToHistory',
  RemoveOrderFromHistory = '[Order] RemoveOrderFromHistory',
  CancelOneOrderAndArchive = '[Order] CancelOrderAndArchive',
  AddOrderWithError = '[Order] AddOrderWithError',
  DeleteOrderWithErrors = '[Order] DeleteOrderWithErrors',
  UpdateOrder = '[Order] UpdateOrder',
  ClearCurrentOrdersState = '[Order] Clear Current Orders State',
  ClearHistoryOrdersState = '[Order] Clear History Orders State',
  SetUpdatedOrder = '[Order] SetUpdatedOrderBySocket',
  IncrementHistoryPageNum = '[Order] Increment History Page Number',
  ResetPageNumArchiveOrders = '[Order] Reset Page Num Archive Orders'
}

export const SetOrderPharmaciesAction = createAction(
  OrderActions.SetOrderPharmacies,
  props<{ data: BzApteka }>()
);

export const ClearCurrentOrdersStateAction = createAction(
  OrderActions.ClearCurrentOrdersState
);

export const ClearHistoryOrdersStateAction = createAction(
  OrderActions.ClearHistoryOrdersState
);

export const CancelOrderAction = createAction(
  OrderActions.CancelOrder,
  props<{ id: string }>()
);

export const AddOneOrderAction = createAction(
  OrderActions.AddOneOrder,
  props<{ apteka: BzApteka }>()
);

export const AddOrdersToHistory = createAction(
  OrderActions.AddOrdersToHistory,
  props<{ data: BzApteka[] }>()
);

export const RemoveOrderFromHistory = createAction(
  OrderActions.RemoveOrderFromHistory,
  props<{ orderId: string }>()
);

export const CancelOneOrderAndArchiveAction = createAction(
  OrderActions.CancelOneOrderAndArchive,
  props<{ apteka: BzApteka }>()
);

export const AddOrderWithErrorAction = createAction(
  OrderActions.AddOrderWithError,
  props<{ pharm: BzApteka }>()
);

export const DeleteOrderWithErrorsAction = createAction(
  OrderActions.DeleteOrderWithErrors,
  props<{ id: number }>()
);

export const UpdateOrderAction = createAction(
  OrderActions.UpdateOrder,
  props<{ pharm: BzApteka }>()
);

export const SetUpdatedOrderAction = createAction(
  OrderActions.SetUpdatedOrder,
  props<{ order: BzApteka }>()
);

export const IncrementHistoryPageNumAction = createAction(
  OrderActions.IncrementHistoryPageNum
);

export const ResetPageNumArchiveOrdersAction = createAction(
  OrderActions.ResetPageNumArchiveOrders
);